import { AddDomainResponse, Product, Workspace, WorkspacePayload } from 'global.types';

import ApiClient from '~/helpers/ApiClient';

export const awsAPIClient = new ApiClient(import.meta.env?.VITE_AWS_API_BASE_URL);

export const createWorkspace = ({
  name,
  product,
  assignDefaultWorkspaces = false,
}: WorkspacePayload): Promise<{ workspaceId: string }> => {
  return awsAPIClient.post<{ workspaceId: string }>('/create-workspace', {
    name,
    product,
    assignDefaultWorkspaces,
  });
};

export const getWorkspaces = (product?: Product) => {
  const url = product ? `/get-all-workspaces?product=${product}` : '/get-all-workspaces';
  return awsAPIClient.get<{ workspaces: Workspace[] }>(url);
};

export const addDomain = async (payload: {
  workspaceId: string;
  domain: string;
}): Promise<AddDomainResponse> => {
  const response = await awsAPIClient.post<AddDomainResponse>('/add-domain', payload);
  return response;
};

export const getDomain = async (payload: {
  workspaceId: string;
}): Promise<{ domain: string; cname: string }> => {
  const response = await awsAPIClient.post<{ domain: string; cname: string }>(
    '/get-domain',
    payload,
  );
  return response;
};
