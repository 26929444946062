import { Button, Center, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import FakeLeadSummary from './FakeLeadSummary';

import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import Pricing from '~/screens/Pricing';


type AnalysisNeedsQuotaProps = {
  randomLength: number;
}

const Wrapper = styled.div`
  position: relative;
`;

const StyledCenter = styled(Center)`
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
  margin-top: 10px;
`;

const AnalysisNeedsQuota = ({ randomLength }: AnalysisNeedsQuotaProps) => {
  const { userAndWorkspaceInfo } = useFeatureFlags();
  const { user } = userAndWorkspaceInfo;

  const navigate = useNavigate();
  const [openPricing, setOpenPricing] = useState<boolean>(false);

  const handleSubscription = async () => {
    try {
      if(user?.data?.stripeCustomerId) {
        navigate('/accounts');
      } else {
        setOpenPricing(true);
      }
    } catch (error) {
      notifications.show({
        color: 'red',
        title: 'Unable to connect payment gateway!',
        message: 'Our team has been notified. Contact us if the problem persists.',
        autoClose: 3000,
      });
      window.location.href = '/intent/dashboard';
    }
  };
  return (
    <>
      {openPricing && <Pricing withCloseButton onClose={() => setOpenPricing(false)} />}
      <Wrapper>
        <FakeLeadSummary randomLength={randomLength} />
        <StyledCenter>
          <Stack maw="60%" gap="xs">
            <Text size="sm" fw={700}>You have reached your monthly lead limit</Text>
            <Button
              style={{ fontWeight: 300 }}
              size="compact-sm"
              variant="filled"
              onClick={(e: SyntheticEvent) => {
                e.preventDefault();
                e.stopPropagation();
                handleSubscription();
              }}
            >
              Upgrade now to see more
            </Button>
          </Stack>
        </StyledCenter>
      </Wrapper>
    </>
  );
};

export default AnalysisNeedsQuota;
