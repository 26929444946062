import { Group, Text } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconX } from '@tabler/icons-react';

const { FullScreen, Accept, Reject } = Dropzone;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ImageDropzone = ({ onDrop }: any) => (
  <FullScreen accept={IMAGE_MIME_TYPE} onDrop={onDrop}>
    <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
      <Accept>
        <IconUpload />
      </Accept>
      <Reject>
        <IconX />
      </Reject>
      <Text size="xl" inline>
        Drop an image here to upload into the bucket
      </Text>
    </Group>
  </FullScreen>
);

export default ImageDropzone;
