import { Text, TextInput } from '@mantine/core';
import styled from 'styled-components';

export const IconSend = styled.img.attrs(() => ({
  src: '/send.svg',
}))`
  width: 16px;
  height: 16px;
`;

export const StyledTextInput = styled(TextInput)`
  &::placeholder {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }
`;

export const ScrollMessage = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
`;

interface MessageBubbleProps {
  isUser: boolean;
}

export const MessageBubble = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isUser'].includes(prop),
})<MessageBubbleProps>`
  background-color: ${(props) => (props.isUser ? '#5A1CEC' : '#FFFFFF')};
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  border-radius: ${(props) => (props.isUser ? '8px 8px 0px 8px' : '8px 8px 8px 0px')};
  border: 1px solid #e9ecef;
  box-shadow:
    0px 1px 3px 0px #0000000d,
    0px 1px 2px 0px #0000001a;
  padding: 10px;
  width: 271px;
  color: ${(props) => (props.isUser ? '#FFFFFF' : '#000000')};
`;

export const StyledText = styled(Text)`
  font-family: 'Rubik';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
`;
