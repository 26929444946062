import { Space, Text, Title } from '@mantine/core';
import styled from 'styled-components';

import CodeWithCopyButton from '../CodeWithCopyButton/CodeWithCopyButton';

type AskTeammateProps = {
  code: string;
};

const composeEmailBody = (code: string) => `
Hi there,

I am asking for your technical help installing a visitor tracking tool named "Intent" on our website.

Could you please add the following tracking code into <head> tag of every page?

${code}

This tool has been designed specifically to prevent slowing down our website performance.

Here is the link to the documentation:
- Manual install https://intent-upflowy-2.productfruits.help/en/article/direct-installation-guide
- Google Tag Manager install https://intent-upflowy-2.productfruits.help/en/article/google-tag-manager
- Website admin panel install https://intent-upflowy.productfruits.help/en/article/website-admin-panel

Feel free to reach out to contact@upflowy.com for technical support if needed.

Thank you,`;

const Wrapper = styled.div`
  background-color: var(--mantine-color-white);
  border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-2);
  border-top-color: var(--mantine-color-white);
  width: 100%;
  padding: 20px;
  margin-top: -20px;
`;

const AskTeammate = ({ code }: AskTeammateProps) => (
  <Wrapper>
    <Title order={4}>Send instructions to a teammate</Title>
    <Text>
      Copy the message below to send installation instructions to your teammate.
    </Text>
    <Space h="md" />
    <CodeWithCopyButton code={composeEmailBody(code)} />
  </Wrapper>
);

export default AskTeammate;
