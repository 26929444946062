import { Box, Button, Flex, Skeleton, Text, TextInput, ThemeIcon, Title } from '@mantine/core';
import { IconArrowBarUp, IconPlayerPlay, IconWorldWww } from '@tabler/icons-react';

import BasePanel from '../BasePanel/BasePanel';
import { PublishSubPanelsDataProps } from '../PublishSettings/PublishSettingsMenu';
export interface PublishGoLivePanelProps {
  opened: boolean;
  onClickToClose: () => void;
  data?: PublishSubPanelsDataProps;
}

const PublishGoLivePanel = ({ opened, onClickToClose, data }: PublishGoLivePanelProps) => {
  const { handleClickToRunPublishSequence, publishing, loadDomain } = data || {};
  const form = data?.form || {
    errors: {},
    values: { favicon: '', title: '', description: '' },
    getInputProps: () => {},
  };
  const formDomain = data?.formDomain || {
    errors: {},
    values: { domain: '', cname: '', error: false },
    getInputProps: () => {},
    isDirty: () => false,
  };

  const formHasErrors =
    Object.keys(form.errors).length + Object.keys(formDomain.errors).length !== 0;

  return (
    <>
      <BasePanel
        opened={opened}
        onClickToClose={onClickToClose}
        label="Publish"
        title="Go Live"
        icon={<IconArrowBarUp />}
        publishButton={
          <Box p={16} style={{ boxShadow: '0px -2px 2px 0px rgba(191, 191, 191, 0.25)' }}>
            <Button variant="default" w="100%" onClick={() => window.open('./preview', '_newtab')}>
              <IconPlayerPlay />
              <Text ml={8}>Preview page</Text>
            </Button>
            <Button
              w="100%"
              loading={publishing}
              disabled={publishing || formHasErrors}
              loaderProps={{ type: 'dots' }}
              onClick={handleClickToRunPublishSequence}
              mt={10}
              aria-label="Publish"
            >
              Publish
            </Button>
          </Box>
        }
      >
        <Flex direction="column" gap={32} w="100%">
          <Flex direction="column">
            <Flex align="center" mt="md">
              <ThemeIcon color="#f3eaff">
                <IconWorldWww color="#6d3fee" size="18" stroke={1} />
              </ThemeIcon>
              <Title order={4} ml={8} fw={400}>
                Domain Settings
              </Title>
            </Flex>

            <Skeleton visible={loadDomain} height={80}>
              <TextInput
                label="Add a Domain or Subdomain"
                description={
                  formDomain.values.domain && !formDomain.isDirty()
                    ? 'Please contact us for updating the domain'
                    : "Type the domain or subdomain exactly as you'd want it to appear"
                }
                disabled={publishing || (!!formDomain.values.domain && !formDomain.isDirty())}
                placeholder="www.yourcompany.com"
                size="sm"
                mt="md"
                {...formDomain.getInputProps('domain')}
              />
            </Skeleton>
          </Flex>
          <Flex direction="column">
            <Flex align="center" mt={36}>
              <img
                width={24}
                alt="Google Tag Manager"
                src={`/assets/icon-googletagmanager-blue.png`}
              />
              <Title order={4} ml={8} fw={400}>
                Google Tag Manager
              </Title>
            </Flex>

            <TextInput
              label="Container ID"
              size="sm"
              mt="md"
              placeholder="Tag manager's container ID"
              disabled={publishing}
              {...form.getInputProps('googleTagManagerId')}
            />
          </Flex>
        </Flex>
      </BasePanel>
    </>
  );
};

export default PublishGoLivePanel;
