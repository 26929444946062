import { Button, Divider, Flex, Text, Textarea, TextInput, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconInfoCircle, IconPlus } from '@tabler/icons-react';

import { validateKeywords, validateName } from './validate';

import { VARIANTS_KEYWORDS_LIMIT } from '~/constants';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

interface DefineVariantProps {
  onClose: () => void;
}

const amountOfKeywords = (keywords: string): number =>
  keywords.length === 0 ? 0 : keywords.split(',').length;

const DefineVariant = ({ onClose }: DefineVariantProps) => {
  const { generateVariants } = usePageSet();
  const form = useForm({
    initialValues: {
      name: '',
      keywords: '',
    },
    validate: {
      name: validateName,
      keywords: validateKeywords,
    },
    validateInputOnChange: true,
  });

  const handleClickToDefineVariant = () => {
    const { name, keywords } = form.values;
    generateVariants(
      name,
      keywords.split(',').map((item) => item.trim()),
    );
    onClose();
  };

  return (
    <>
      <Text mt={0} fz={14}>
        Page variants allow you to manage multiple themed pages, such as those for different
        locations or personas, using a single template.
      </Text>
      <Text mt={16} fz={14}>
        Upflowy can use AI to automatically generate these pages from one blueprint, simplifying the
        management of variant campaigns.
      </Text>

      <TextInput
        mt={16}
        label={
          <Flex align="center" gap={4}>
            Variant type
            <Tooltip
              fz={12}
              withinPortal={false}
              label={
                <>
                  These keywords will be used to generate
                  <br />
                  unique pages based on your variant type
                </>
              }
            >
              <IconInfoCircle size={16} />
            </Tooltip>
          </Flex>
        }
        description="Give this variant a clear, descriptive name to easily identify it later"
        {...form.getInputProps('name')}
      />

      <Textarea
        mt={16}
        mb={4}
        rows={8}
        label="Add variant keywords"
        placeholder="e.g. New York, San Francisco, Los Angeles"
        // eslint-disable-next-line max-len
        description={`Enter up to ${VARIANTS_KEYWORDS_LIMIT} keywords, separated by commas that define your page variants.`}
        {...form.getInputProps('keywords')}
      />

      {form.isValid('keywords') && (
        <Text fz={12} ta="right" c="var(--mantine-color-dimmed)">
          {amountOfKeywords(form.values.keywords)}/{VARIANTS_KEYWORDS_LIMIT} keywords used
        </Text>
      )}

      <Divider mx={-16} mt={32} />

      <Flex mt={16} gap={10} wrap="wrap">
        <Button onClick={onClose} variant="default" type="button" maw="100%">
          Cancel
        </Button>
        <Button
          leftSection={<IconPlus />}
          disabled={!form.isValid()}
          onClick={handleClickToDefineVariant}
          maw="100%"
        >
          Generate variants
        </Button>
      </Flex>
    </>
  );
};

export default DefineVariant;
