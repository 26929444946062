import { Affix, Flex, Group, Loader, Paper } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ListVariant from './components/ListVariant';
import Portview from './components/Portview';
import { PreviewViewer } from './components/PreviewViewer';
import TopNavbar from './components/TopNavbar';
import { forceRender } from './utils';

import useErrorHandling from '../../hooks/useErrorHandling/useErrorHandling';
import { HideChat } from '../Studio/Studio.styles';

import ScreenContainer from '~/components/ScreenContainer/ScreenContainer.styles';
import VariantDropdownButton from '~/components/VariantDropdownButton/VariantDropdownButton';
import { ParsedLandingpageObject } from '~/global.types';
import renderPage from '~/helpers/renderPage/renderPage';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider.tsx';
import { firstFetch } from '~/services/PageServices';
import { GetPageVariantData, getPageVariants } from '~/services/PageSetServices/PageSetServices';

const Preview = () => {
  const handlingError = useErrorHandling();
  const [tab, setTab] = useState('desktop');
  const [loading, setLoading] = useState(false);
  const [srcDoc, setSrcDoc] = useState('');
  const [variantList, setVariantList] = useState<GetPageVariantData[]>([]);
  const [isPageSet, setIsPageSet] = useState(false);
  const { nanoId = '' } = useParams();

  const { isLoading, selectedVariant, getVariantView } = usePageSet();

  useEffect(() => {
    const asyncRenderPage = async (res: ParsedLandingpageObject) => {
      try {
        const pageSetId = res.pageSetId;

        if (pageSetId) {
          setIsPageSet(true);
          getPageVariants({ pageSetId })
            .then((response) => {
              setVariantList(response.data);
              if (response.data[0]) getVariantView(response.data[0].nanoId);
            })
            .catch(() => {
              notifications.show({
                color: 'red',
                message: 'Failed to retrieve page variants.',
                autoClose: 3000,
              });
            });
        }
        const renderedContent = await renderPage(
          nanoId,
          res.workspaceId,
          JSON.parse(res.content || ''),
        );
        setSrcDoc(renderedContent);
      } catch (error) {
        notifications.show({
          color: 'red',
          message: 'Error in loading page!',
          autoClose: 3000,
        });
        console.error('Error in rendering page:', error);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    firstFetch(nanoId)
      .then(asyncRenderPage)
      .catch(handlingError)
      .finally(() => setLoading(false));
  }, []);

  // Temporary quick fix to resolve this strange issue that is recently surfaced
  // This unstuck the rendering by calling an extra reload. Not sure why this becomes
  // a problem only recently, but it does the job.
  useEffect(forceRender(srcDoc), [srcDoc]);

  const loadingViewer = (isPageSet && isLoading) || loading;

  return (
    <ScreenContainer>
      <HideChat />
      {isPageSet && (
        <Affix position={{ left: 8, top: 8 }} zIndex={9999}>
          <Paper shadow="xs" p="0" withBorder radius={10} w="fit-content">
            <Group justify="space-between" p={8}>
              <VariantDropdownButton
                isLoading={isLoading}
                selectedVariantName={selectedVariant?.name}
                popoverWidth={150}
              >
                <ListVariant variantList={variantList} />
              </VariantDropdownButton>
            </Group>
          </Paper>
        </Affix>
      )}
      <TopNavbar setTab={setTab} tab={tab} />
      <Portview type={tab}>
        <PreviewViewer
          sandbox="allow-downloads allow-popups allow-same-origin allow-scripts allow-forms"
          srcDoc={srcDoc}
          style={{ display: loadingViewer ? 'none' : 'block' }}
        />
        {loadingViewer && (
          <Flex h="100%" justify="center" align="center">
            <Loader />
          </Flex>
        )}
      </Portview>
    </ScreenContainer>
  );
};

export default Preview;
