import { PageSetVariantStateObj } from './PageSetProvider';

import seperateStyleFromInnerHtml from '~/helpers/viewerInteractions/seperateStyleFromInnerHtml';
import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';

const variantTextValueReplaceCallback =
  (viewer: HTMLIFrameElement, attributeName: string) =>
  ([key, obj]: [string, { textValue?: string } | undefined]) => {
    const variantEnabledElem = viewer.contentDocument?.querySelector(`[${attributeName}="${key}"]`);

    if (variantEnabledElem && obj && typeof obj === 'object') {
      // If EditorJS is already appended, we don't need to seperate out the styles for innerHTML swap
      const foundEditorAppended = variantEnabledElem.querySelector('.upf-unit-edit');

      if (foundEditorAppended) {
        foundEditorAppended.innerHTML = obj.textValue || '';
      } else {
        const original = seperateStyleFromInnerHtml(variantEnabledElem.outerHTML);
        variantEnabledElem.innerHTML = original.styles + obj.textValue;
      }
    }
  };

const updatePageContentWithVariant = (
  viewer: HTMLIFrameElement,
  variantNodes: GetPageVariantData['variantNodes'],
  variantStates?: PageSetVariantStateObj,
) => {
  if (!variantNodes) return;

  Object.entries(variantNodes).forEach(
    variantTextValueReplaceCallback(viewer, 'data-variant-node-id'),
  );
  Object.entries(variantStates?.editedVariantNodes || {}).forEach(
    variantTextValueReplaceCallback(viewer, 'data-selector'),
  );
};

export default updatePageContentWithVariant;
