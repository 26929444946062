import { Center, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Portview from './components/Portview';
import { PreviewViewer } from './components/PreviewViewer';
import TopNavbar from './components/TopNavbar';
import { firstFetchPreview, forceRender } from './utils';

import useErrorHandling from '../../hooks/useErrorHandling/useErrorHandling';
import { HideChat } from '../Studio/Studio.styles';

import ScreenContainer from '~/components/ScreenContainer/ScreenContainer.styles';
import { ParsedLandingpageObject } from '~/global.types';
import renderPage from '~/helpers/renderPage/renderPage';

const PreviewByUrlToken = () => {
  // Retrieve the location object using useLocation
  const location = useLocation();
  const handlingError = useErrorHandling();
  // Extract the token from the search query string
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const [tab, setTab] = useState('desktop');

  const [loading, setLoading] = useState(false);
  const [srcDoc, setSrcDoc] = useState('');
  const { nanoId = '' } = useParams();

  useEffect(() => {
    const asyncRenderPage = async (res: ParsedLandingpageObject) => {
      try {
        const renderedContent = await renderPage(
          nanoId,
          res.workspaceId,
          JSON.parse(res.content || ''),
        );

        setSrcDoc(renderedContent);
      } catch (error) {
        notifications.show({
          color: 'red',
          message: 'Error in loading page!',
          autoClose: 3000,
        });
        console.error('Error in rendering page:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!token) {
      handlingError({ status: 403 });
      return;
    }

    setLoading(true);
    firstFetchPreview(nanoId, token)
      .then(asyncRenderPage)
      .catch(handlingError)
      .finally(() => setLoading(false));
  }, [token]);

  // Temporary quick fix to resolve this strange issue that is recently surfaced
  // This unstuck the rendering by calling an extra reload. Not sure why this becomes
  // a problem only recently, but it does the job.
  useEffect(forceRender(srcDoc), [srcDoc]);

  if (loading) {
    return (
      <ScreenContainer>
        <HideChat />

        <Center style={{ marginTop: 20 }}>
          <Loader />
        </Center>
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer>
      <HideChat />
      <TopNavbar setTab={setTab} tab={tab} />
      <Portview type={tab}>
        <PreviewViewer
          sandbox="allow-downloads allow-popups allow-same-origin allow-scripts allow-forms"
          srcDoc={srcDoc}
        />
      </Portview>
    </ScreenContainer>
  );
};

export default PreviewByUrlToken;
