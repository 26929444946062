import styled from 'styled-components';

export const AuthCard = styled.div`
  border: 1px solid var(--m3-sys-light-outline-variant);
  border-radius: 1rem;
  max-width: 550px;
  margin: auto auto;
  background-color: var(--m3-sys-light-on-primary);
  font-family: var(--m3-label-large);
  font-size: var(--m3-body-small-size);
  color: var(--m3-sys-light-on-surface-variant);
  text-align: center;
  padding: 1rem 0;

  p {
    margin: 0 auto 1.5rem auto;
    padding: 0;
  }

  a {
    color: var(--m3-sys-light-primary);
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 550px) {
    & {
      border: 0;
      padding: 2rem;
    }
  }
`;

export const AuthHeadline = styled.h2`
  font-size: var(--m3-headline-large-size);
  color: var(--m3-black);
  font-weight: 700;
  max-width: 20rem;
  margin: 1.5rem auto 0.75rem auto;

  @media (max-width: 550px) {
    & {
      margin-top: 0;
    }
  }
`;

export const AuthSubHeadline = styled.div`
  font-size: var(--m3-body-small-size);
  color: var(--m3-sys-light-on-surface);
  margin-bottom: 1.5rem;
`;

export const TermsConditions = styled.p`
  max-width: 20rem;
  margin: 1.5rem auto 0.75rem auto;
  line-height: 1.25rem;
  font-size: 8px;
  font-weight: 400;
`;

export const BrandLogo = styled.img.attrs(() => ({
  src: '/intent_logo.svg',
  alt: 'Intent logo',
}))`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  margin-bottom: 2rem;
  width: 120px;

  @media (max-width: 550px) {
    & {
      margin-top: 4vh;
      margin-bottom: 0;
    }
  }
`;