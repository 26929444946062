import msg from './msg';

import { ViewerWindowObject } from '~/global.types';

export function addListenerForViewerDropzones() {
  msg({ type: 'reset-bound' });
  setTimeout(() => {
    document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
      const { addListenerForDropzones } = (viewer as HTMLIFrameElement)
        .contentWindow as ViewerWindowObject;
      if (addListenerForDropzones) addListenerForDropzones();
    });
  }, 200);
}

export function removeListenerForViewerDropzones() {
  msg({ type: 'hover-dropzone-end' });
  document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
    const { removeListenerForDropzones } = (viewer as HTMLIFrameElement)
      .contentWindow as ViewerWindowObject;
    if (removeListenerForDropzones) removeListenerForDropzones();
  });
}
