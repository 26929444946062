import { useEffect, useState } from 'react';

import notifyConnectivitiyClasses from './NotifyConnectivitiy.module.css';

const NotifyConnectivity = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const showNotifyConnectivity = () => {
      setIsOffline(true);
    };

    const hideNotifyConnectivity = () => {
      setIsOffline(false);
    };

    window.addEventListener('offline', showNotifyConnectivity);
    window.addEventListener('online', hideNotifyConnectivity);

    return () => {
      window.removeEventListener('offline', showNotifyConnectivity);
      window.removeEventListener('online', hideNotifyConnectivity);
    };
  }, []);

  return (
    isOffline && (
      <div className={notifyConnectivitiyClasses.container}>
        Offline. Attempting to reconnect...
      </div>
    )
  );
};

export default NotifyConnectivity;
