import { Button, FocusTrap, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

import { refreshUserToken } from '~/services/UserServices';
import { createWorkspace } from '~/services/WorkspaceServices';

type NewWorkspaceModalProps = {
  title: string;
  name?: string;
  close: () => void;
};

const NewWorkspaceModal = ({ name, title, close }: NewWorkspaceModalProps) => {
  const [saving, setSaving] = useState(false);
  const form = useForm({
    validateInputOnChange: true,
    initialValues: { name },
  });

  const handleWorkspaceCreation = async (workspaceName: string) => {
    setSaving(true);
    try {
      const workspace = await createWorkspace({ name: workspaceName, product: 'V2' });
      await refreshUserToken();
      localStorage.setItem('activeWorkspace', workspace.workspaceId);
      notifications.show({
        color: 'blue',
        title: 'Workspace created successfully',
        message: '',
        autoClose: 3000,
      });
      window.location.href = '/dashboard';
    } catch (error) {
      console.log('Error creating workspace:', error);
      notifications.show({
        color: 'red',
        title: 'Error creating workspace',
        message: 'Please try again',
        autoClose: 3000,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      opened
      onClose={close}
      title={title}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      zIndex={9999}
    >
      <form
        onSubmit={form.onSubmit((values) =>
          handleWorkspaceCreation(values.name as unknown as string),
        )}
      >
        <FocusTrap active>
          <TextInput
            data-autofocus
            label="Enter a workspace name:"
            {...form.getInputProps('name')}
            required
          />
        </FocusTrap>
        <Group mt="md" justify="flex-end">
          <Button
            aria-label="Close Modal"
            onClick={close}
            variant="default"
            type="button"
            disabled={saving}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" loading={saving}>
            Ok
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
export default NewWorkspaceModal;
