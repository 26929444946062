import { FC } from 'react';

import { FeatureFlagProvider } from './FeatureFlagProvider';
import { MediaCollectionProvider } from './MediaCollectionProvider';
import { PageSetProvider } from './PageSetProvider/PageSetProvider';
import { WorkspaceProvider } from './WorkspaceProvider/WorkspaceProvider';

const PROVIDER_TRAIN = [
  FeatureFlagProvider,
  MediaCollectionProvider,
  WorkspaceProvider,
  PageSetProvider,
];

// This should take any Functional Component Providers
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composedProviders = (...components: FC<any>[]): FC<any> => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>,
  );
};

export const StudioAppContextProvider = composedProviders(...PROVIDER_TRAIN);
