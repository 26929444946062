import useViewerMessage from '../useViewerMessage/useViewerMessage';

import { WheelForStudioPanZoomEvent } from '~/messages.types';

/**
 * This is a convenient helper hook that adds Wheel start and end capability,
 * which is not available from Wheel Event Listener.
 *
 * This is currently used to enable wheel/dual-finger-touch to pan features
 */
const useWheelToPanMessage = (
  setStateFunc: (value: React.SetStateAction<boolean>) => void,
  additionalCallback?: (e: WheelForStudioPanZoomEvent) => void,
) => {
  let panTimer: NodeJS.Timeout | null = null;

  return useViewerMessage((e: MessageEvent) => {
    const { data } = e;

    if (data.type === 'wheel-to-pan') {
      if (additionalCallback) additionalCallback(data.partialWheelEvent);

      if (!panTimer) {
        setStateFunc(true);
      }

      if (panTimer) {
        clearTimeout(panTimer);
      }

      panTimer = setTimeout(() => {
        setStateFunc(false);
        panTimer = null;
      }, 200);
    }
  }, []);
};

export default useWheelToPanMessage;
