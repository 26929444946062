import { useNavigate } from 'react-router-dom';

const useErrorHandling = () => {
  const navigate = useNavigate();

  return (error: { status: number }) => {
    if ([401, 403].includes(error.status)) {
      navigate('/403');
    } else {
      navigate('/404');
    }
  };
};

export default useErrorHandling;
