export const imageSwapUploadStateProcessing = (targetedElement: Element) => {
  const wrapper = document.createElement('div');
  wrapper.setAttribute('data-image-src', 'true');
  wrapper.setAttribute('data-selector', targetedElement?.getAttribute('data-selector') || '');
  wrapper.setAttribute('slot', targetedElement?.slot || '');
  targetedElement?.removeAttribute('data-selector');
  targetedElement?.removeAttribute('slot');
  targetedElement?.parentNode?.insertBefore(wrapper, targetedElement);
  wrapper.appendChild(targetedElement);
};

const imageSwapUploadState = (selector: string) => {
  document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
    const targetedElement = (viewer as HTMLIFrameElement).contentDocument?.querySelector(
      `[data-selector=${selector}]`,
    );

    if (targetedElement) imageSwapUploadStateProcessing(targetedElement);
  });
};

export default imageSwapUploadState;
