import { ViewerData, ViewerWindowObject } from '~/global.types';

export const postMessagesToParent = (
  data: Partial<ViewerData>,
  innerComponentType?: string,
  reissueData?: boolean,
) => {
  const { postMessageOnParentTrigger } = (
    document.getElementById(data.viewer || '') as HTMLIFrameElement
  )?.contentWindow as ViewerWindowObject;
  if (postMessageOnParentTrigger) {
    const { elementSelector, columnLayoutInnerComponentType, rootNodeHost } = data;

    if (elementSelector)
      postMessageOnParentTrigger(elementSelector, innerComponentType, reissueData);

    if (columnLayoutInnerComponentType && rootNodeHost)
      postMessageOnParentTrigger(rootNodeHost, columnLayoutInnerComponentType, reissueData);
  }
};
