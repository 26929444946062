import { GetEntityQuery } from '../../../api.types';

import { ParsedLandingpageObject } from '~/global.types';
import { callGraphQlPreview } from '~/helpers/graphQl/graphQl';
import { prettyJson } from '~/services/PageServices';

export const firstFetchPreview = async (
  nanoId?: string,
  token?: string | null,
): Promise<ParsedLandingpageObject> => {
  const { data } = await callGraphQlPreview<GetEntityQuery>({
    query: `
      query GetEntity($nanoId: String!) {
        getEntity(nanoId: $nanoId, type: "PAGE") {
          nanoId
          definition
          name
          title
          content
          workspaceId: relatedEntityId
        }
      }
      `,
    variables: { nanoId },
    token: token ?? '',
  });

  return {
    ...data?.getEntity,
    content: prettyJson(JSON.parse(data?.getEntity?.content ?? '{}') ?? {}),
  } as ParsedLandingpageObject;
};

export const forceRender = (srcDoc?: string) => () => {
  if (srcDoc)
    setTimeout(() => {
      document.querySelector('iframe')?.contentWindow?.location.reload();
      console.log('Viewer loaded');
    }, 300);
};
