import { PageJsonSnippetObj } from '~/global.types';

export default {
  contains: [
    {
      '@loading': 'lazy',
      '@slot': 'column-1',
      '@alt': '',
      '@src': 'https://placehold.co/200',
      selector: 'elem-8TkYvXg4',
      style: {
        color: 'var(--color-text-lightbg)',
        margin: 'auto',
      },
      tagName: 'img',
      type: 'image',
      object: 'primitive',
    },
    {
      textValue: 'heading 2',
      '@slot': 'column-1',
      selector: 'elem-W1jKmLt7',
      style: {
        'font-size': '24px',
        'font-family': 'var(--font-family-headings)',
        'line-height': '1.5',
        color: 'var(--color-text-lightbg)',
        'font-weight': '700',
        'text-align': 'center',
      },
      tagName: 'h1',
      type: 'text',
      object: 'primitive',
    },
    {
      textValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      '@slot': 'column-1',
      selector: 'elem-R0p3J7Qm',
      style: {
        'font-family': 'var(--font-family-paragraphs)',
        'line-height': '1.5',
        color: 'var(--color-text-lightbg)',
        'text-align': 'center',
      },
      tagName: 'div',
      type: 'text',
      object: 'primitive',
    },
    {
      '@loading': 'lazy',
      '@slot': 'column-2',
      '@alt': '',
      '@src': 'https://placehold.co/200',
      selector: 'elem-F5LtJ9Yu',
      style: {
        color: 'var(--color-text-lightbg)',
        margin: 'auto',
      },
      tagName: 'img',
      type: 'image',
      object: 'primitive',
    },
    {
      textValue: 'heading 2',
      '@slot': 'column-2',
      selector: 'elem-M9v2J5D6',
      style: {
        'font-size': '24px',
        'font-family': 'var(--font-family-headings)',
        'line-height': '1.5',
        color: 'var(--color-text-lightbg)',
        'font-weight': '700',
        'text-align': 'center',
      },
      tagName: 'h1',
      type: 'text',
      object: 'primitive',
    },
    {
      textValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      '@slot': 'column-2',
      selector: 'elem-Q2kXrL9J',
      style: {
        'font-family': 'var(--font-family-paragraphs)',
        'line-height': '1.5',
        color: 'var(--color-text-lightbg)',
        'text-align': 'center',
      },
      tagName: 'div',
      type: 'text',
      object: 'primitive',
    },
    {
      '@loading': 'lazy',
      '@slot': 'column-3',
      '@alt': '',
      '@src': 'https://placehold.co/200',
      selector: 'elem-T7jLpH8K',
      style: {
        color: 'var(--color-text-lightbg)',
        margin: 'auto',
      },
      tagName: 'img',
      type: 'image',
      object: 'primitive',
    },
    {
      textValue: 'heading 2',
      '@slot': 'column-3',
      selector: 'elem-P9wR8L2F',
      style: {
        'font-size': '24px',
        'font-family': 'var(--font-family-headings)',
        'line-height': '1.5',
        color: 'var(--color-text-lightbg)',
        'font-weight': '700',
        'text-align': 'center',
      },
      tagName: 'h1',
      type: 'text',
      object: 'primitive',
    },
    {
      textValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      '@slot': 'column-3',
      selector: 'elem-X4mJ9V6B',
      style: {
        'font-family': 'var(--font-family-paragraphs)',
        'line-height': '1.5',
        color: 'var(--color-text-lightbg)',
        'text-align': 'center',
      },
      tagName: 'div',
      type: 'text',
      object: 'primitive',
    },
  ],
  selector: 'layout-jWXILANr',
  style: {
    padding: '0px 0px 48px 0px',
  },
  layoutName: 'column-layout',
} as PageJsonSnippetObj;
