import { BackgroundImage, Button, Card, Flex, ImageProps, Overlay } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import ImageUploader from '../ImageUploader/ImageUploader';

interface ImagePreviewProps extends ImageProps {
  isReplacingImage: boolean;
  onFileSelected?: (image: File | null) => void;
  onLibrarySelected?: () => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
  src,
  isReplacingImage,
  onFileSelected,
  onLibrarySelected,
}) => {
  const { hovered, ref } = useHover();

  return (
    <Card
      className={`${isReplacingImage ? 'replacing-image' : ''} checker`}
      ref={ref}
      aria-label="Image preview"
      withBorder
      shadow="none"
      padding={0}
    >
      <BackgroundImage h={200} src={src} bgp="center center" bgr="no-repeat" bgsz="cover" />

      <Overlay center backgroundOpacity={0}>
        <Flex
          direction="column"
          style={{
            opacity: hovered ? 1 : 0,
            visibility: hovered ? 'visible' : 'hidden',
            transition: 'opacity 0.3s ease, visibility 0.3s ease',
          }}
        >
          <ImageUploader
            colors="#495057"
            opacity={0.8}
            customText="Upload image"
            onFileSelected={onFileSelected}
          />
          <Button
            size="sm"
            onClick={onLibrarySelected}
            color="#495057"
            opacity={0.8}
            style={{ border: '1px solid #ffffff' }}
          >
            Select from library
          </Button>
        </Flex>
      </Overlay>
    </Card>
  );
};

export default ImagePreview;
