import { ColorInput, ColorPicker, Flex, Overlay } from '@mantine/core';
import { useEffect, useState } from 'react';

import { enforceHex, noTransparentColor } from './processingFunctions';

interface ColorEditorProps {
  value: string;
  onChange: (color: string) => void;
  label: string;
  reverse?: boolean;
  noAlpha?: boolean;
}

export const ColorEditor = ({ value, onChange, label, reverse, noAlpha }: ColorEditorProps) => {
  const [color, setColor] = useState<string>(value);
  const [isPickerActive, setIsPickerActive] = useState(false);

  const handleInputChange = (newColor: string) => {
    setColor(newColor);
    onChange(newColor);
  };

  const handlePickerChange = (newColor: string) => {
    handleInputChange(noTransparentColor(newColor, color));
  };

  const handlePickerMouseDown = () => {
    setIsPickerActive(true);
  };

  const handleGlobalMouseUp = () => {
    setIsPickerActive(false);
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleGlobalMouseUp, true);

    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp, true);
    };
  }, [handleGlobalMouseUp, isPickerActive]);

  return (
    <>
      <Flex direction={reverse ? 'column-reverse' : 'column'}>
        <ColorPicker
          value={color}
          size="xs"
          format={noAlpha ? 'hex' : 'hexa'}
          fullWidth
          role="widget"
          onChange={handlePickerChange}
          onMouseDown={handlePickerMouseDown}
        />
        <ColorInput
          size="xs"
          mt={reverse ? 0 : 4}
          mb={reverse ? 8 : 0}
          value={enforceHex(color)}
          format="hex"
          withPicker={false}
          withPreview={false}
          aria-label={label}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onChange(color);
            }
          }}
        />
      </Flex>
      <Overlay
        backgroundOpacity={0}
        fixed
        data-testid="overlay"
        style={{
          display: isPickerActive ? 'block' : 'none',
        }}
      />
    </>
  );
};
