import { Property, WorkspaceConfig } from 'global.types';

import ApiClient from '~/helpers/ApiClient';

export const awsAPIClient = new ApiClient(import.meta.env?.VITE_AWS_API_BASE_URL);

export const createProperty = ({
  workspaceId,
  name,
  config,
}: {
  workspaceId: string;
  name: string;
  config?: WorkspaceConfig;
}) => {
  return awsAPIClient.post<{ workspaceId: string; propertyId: string }>('/create-property', {
    workspaceId,
    name,
    config,
  });
};

export const updateProperty = ({
  workspaceId,
  propertyId,
  name,
  config,
}: {
  workspaceId: string;
  propertyId: string;
  name?: string;
  config?: WorkspaceConfig;
}) => {
  return awsAPIClient.put<{ workspaceId: string; properties: Property }>('/update-property', {
    workspaceId,
    propertyId,
    name,
    config,
  });
};

export const getProperties = (workspaceId: string) => {
  return awsAPIClient.get<Property[]>(`/get-properties?workspaceId=${workspaceId}`);
};
