import { Buffer } from 'buffer';

import { FeatureFlags } from '~/global.types';
import ApiClient from '~/helpers/ApiClient';

export const awsAPIClient = new ApiClient(import.meta.env?.VITE_AWS_API_BASE_URL);

export const fetchFeatureFlags = (email?: string) => {
  const decodedEmail = email ? Buffer.from(email).toString('base64') : '';
  return awsAPIClient.post<FeatureFlags>('/feature-flags', { uid: decodedEmail });
};
