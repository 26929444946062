import { Badge } from '@mantine/core';

const styles = (backgroundColor: string, color: string) => ({
  root: {
    backgroundColor,
    cursor: 'pointer',
    width: '110px',
  },
  label: {
    color,
  },
});

export const BadgeComingSoon = () => (
  <Badge size="sm" styles={styles('#F1F3F5', '#495057')}>
    COMING SOON
  </Badge>
);

export const BadgeConnected = () => (
  <Badge size="sm" styles={styles('#EBFBEE', '#006E26')}>
    Connected
  </Badge>
);

export const BadgeDisconnected = () => (
  <Badge size="sm" styles={styles('#FFF5F5', '#B91D20')}>
    Disconnected
  </Badge>
);
