import { ActionIcon, ColorSwatch } from '@mantine/core';
import styled from 'styled-components';

export const MenuContainer = styled.div`
  background: #fff;
  z-index: 1001;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid var(--Gray-Gray3, #dee2e6);
  background: var(--bw-white, #fff);
  display: flex;
  gap: 8px;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.05),
    0px 10px 15px -5px rgba(0, 0, 0, 0.1),
    0px 7px 7px -5px rgba(0, 0, 0, 0.04);
  position: fixed;
  pointer-events: all;
`;

export const StyledActionIcon = styled(ActionIcon)`
  color: #000;
  border-radius: 4px;
  flex-direction: column;

  &[aria-expanded='true'],
  &:hover {
    background: #dee2e6;
  }
`;

export const CircleIcon = styled(ColorSwatch)`
  border-radius: 50%;
  border: 1px solid #ced4da;
  background: #f2f2f2;
  box-shadow: 2px 2px 7px 0px rgba(101, 101, 101, 0.1) inset;
  width: 20px;
  height: 20px;
  overflow: hidden;
`;

export const RingIcon = styled(ColorSwatch)`
  position: relative;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: var(--bw-white, #fff);
  border: 1px solid rgba(101, 101, 101, 0.2);
  box-shadow: 1px 1px 1px 0px rgba(101, 101, 101, 0.6);

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    margin: auto;
    border-radius: 12px;
    border: 1px solid rgba(101, 101, 101, 0.2);
    background: var(--bw-white, #fff);
    box-shadow: 1px 1px 1px 0px rgba(101, 101, 101, 0.6) inset;
    width: 10px;
    height: 10px;
  }
`;

export const IconHighlight = styled.img.attrs(() => ({
  src: '/highlight.svg',
}))`
  width: 20px;
  height: 20px;
`;

export const BgColorBar = styled(ColorSwatch)`
  height: 2.5px;
  width: 13px;
  border-radius: 2px;
`;

export const ButtonHighlightContent = styled.div`
  padding-top: 4px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2px;
`;
