import { PageJsonSnippetObj } from '~/global.types';

export default {
  contains: [
    {
      textValue: 'This is heading 1',
      '@slot': 'column-1',
      selector: 'elem-3bQ6WvP2',
      style: {
        'font-size': '32px',
        'font-family': 'var(--font-family-headings)',
        'line-height': '42px',
        color: 'var(--color-text-darkbg)',
        'font-weight': '700',
        'text-align': 'center',
      },
      tagName: 'h1',
      type: 'text',
      object: 'primitive',
    },
  ],
  selector: 'layout-8fG7ZxT1',
  style: {
    padding: '32px 0px 32px 0px',
    background: 'var(--color-tertiary-100)',
  },
  tagName: 'div',
  layoutName: 'column-layout',
  object: 'section',
} as PageJsonSnippetObj;
