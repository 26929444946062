import { Collapse } from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconArrowBarUp, IconBrowserCheck, IconChevronRight } from '@tabler/icons-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import validator from 'validator';

import BaseSubMenuButton from '../BaseSubMenuButton/BaseSubMenuButton';
import { SubMenuContainer } from '../Toolbar.styles';
import { subPanelCollection } from '../useToolbarOperations';

import { MakeOpenSubPanelObj, PageJsonSnippetObj, SubPanelTypes } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { uploadFile } from '~/services/AssetServices';
import { publishPage, updatePublishSettings } from '~/services/PageServices';
import { addDomain, getDomain } from '~/services/WorkspaceServices';

export interface PublishSubPanelsDataProps {
  loadDomain?: boolean;
  handleClickToRunPublishSequence?: () => void;
  publishing?: boolean;
  faviconFile?: File | null;
  setFaviconFile?: (payload: File | null) => void;
  form?: UseFormReturnType<
    {
      title: string;
      favicon: string;
      description: string;
      googleTagManagerId: string;
    },
    (values: {
      title: string;
      favicon: string;
      description: string;
      googleTagManagerId: string;
    }) => {
      title: string;
      favicon: string;
      description: string;
      googleTagManagerId: string;
    }
  >;
  formDomain?: UseFormReturnType<
    {
      domain: string;
      error: boolean;
      cname: string;
    },
    (values: { domain: string; cname: string; error: boolean }) => {
      domain: string;
      error: boolean;
      cname: string;
    }
  >;
  formForNotContent?: UseFormReturnType<{
    nanoId: string;
    workspaceId: string;
    urlSlug: string;
  }>;
}
interface PublishSettingsMenuProps {
  opened: boolean;
  makeOpenSubPanel?: MakeOpenSubPanelObj;
}

const publish =
  (
    nanoId = '',
    setPublishing: Dispatch<SetStateAction<boolean>>,
    openedSub: string,
    setOpenedSub: Dispatch<SetStateAction<string>>,
  ) =>
  async () => {
    try {
      await publishPage({ nanoId });
      const panelName = 'publish-confirmation';
      setOpenedSub(openedSub === panelName ? '' : panelName || '');
      notifications.show({
        color: 'blue',
        message: 'Page published successfully!',
        autoClose: 3000,
      });
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'Unable to publish page!',
        autoClose: 3000,
      });
    } finally {
      setPublishing(false);
    }
  };

const addNewDomain = async (
  workspaceId: string,
  domain: string,
  setPublishing: Dispatch<SetStateAction<boolean>>,
  formDomain: UseFormReturnType<
    {
      domain: string;
      cname: string;
      error: boolean;
    },
    (values: { domain: string; cname: string; error: boolean }) => {
      domain: string;
      cname: string;
      error: boolean;
    }
  >,
) => {
  try {
    setPublishing(true);
    const response = await addDomain({ workspaceId: workspaceId ?? '', domain });

    if (response.vercelResponse && !response.vercelResponse?.verified) {
      formDomain.setValues({
        error: true,
      });
      notifications.show({
        color: 'red',
        message: 'This domain name is already registered in Vercel! Please try another one.',
        autoClose: 3000,
      });
      return;
    }
    notifications.show({
      color: 'blue',
      message: 'Add domain successfully! Continue publishing...',
      autoClose: 3000,
    });
    formDomain.setValues({
      cname: response.cnameValue,
      error: false,
    });
  } catch (error) {
    formDomain.setValues({
      error: true,
    });
    notifications.show({
      color: 'red',
      message: 'Unable to add domain, it may already exist!',
      autoClose: 3000,
    });
  }
};

const PublishSettingsMenu = ({ opened, makeOpenSubPanel }: PublishSettingsMenuProps) => {
  const [publishing, setPublishing] = useState(false);
  const [faviconFile, setFaviconFile] = useState<File | null>(null);
  const [loadDomain, setLoadDomain] = useState(true);
  const [openedSub, setOpenedSub] = useState('');

  const form = useForm({
    initialValues: {
      title: '',
      favicon: '',
      description: '',
      googleTagManagerId: '',
    },
    validateInputOnChange: true,
    validate: {
      googleTagManagerId: (value) => {
        if (!value) return null;

        return validator.matches(value, /^GTM-[A-Z0-9]{1,8}$/i)
          ? null
          : 'Invalid Google Tag Manager ID format, must start with "GTM-"';
      },
    },
  });

  const formDomain = useForm({
    initialValues: {
      domain: '',
      cname: '',
      error: false,
    },
    validateInputOnChange: true,
    validate: {
      domain: (value) => (value.length > 0 && !validator.isFQDN(value) ? 'Invalid domain' : null),
    },
  });

  const formForNotContent = useForm({
    initialValues: {
      nanoId: '',
      workspaceId: '',
      urlSlug: '',
    },
    validateInputOnChange: true,
    validate: {
      urlSlug: (value) => (value.length === 0 || !validator.isSlug(value) ? 'Invalid slug' : null),
    },
  });

  useEffect(() => {
    if (opened) {
      msg({ type: 'editor-sync' });
      msg({ type: 'trigger-broadcast-page-data' });
    }
  }, [opened]);

  useViewerMessage(
    async ({ data }) => {
      if (data.type === 'broadcast-page-data' && opened) {
        const { nanoId, definition: urlSlug, workspaceId = '' } = data.pageDetails;

        const {
          title = '',
          favicon = '',
          head = [],
          googleTagManagerId = '',
        } = JSON.parse(data.pageData || '{}');

        const description =
          (head.find((tag: PageJsonSnippetObj) => tag['@name'] === 'description') || {})[
            '@content'
          ] || '';

        const obj = {
          title,
          favicon,
          googleTagManagerId,
          description,
        };

        const objForNotContent = {
          nanoId,
          workspaceId,
          urlSlug,
        };

        form.setValues(obj);
        form.resetDirty(obj);
        formForNotContent.setValues(objForNotContent);
        formForNotContent.resetDirty(objForNotContent);

        setLoadDomain(true);
        try {
          const getDomainResult = await getDomain({ workspaceId });
          if (getDomainResult.domain) {
            formDomain.setValues(getDomainResult);
            formDomain.resetDirty({
              domain: getDomainResult.domain,
              cname: getDomainResult.cname,
              error: false,
            });
            setLoadDomain(false);
          }
        } catch {
          setLoadDomain(false);
        }
      }

      if (data.type === 'update-page-content-success' && opened && publishing) {
        form.resetDirty(form.values);
        publish(formForNotContent.values.nanoId, setPublishing, openedSub, setOpenedSub)();
      }

      if (data.type === 'update-page-content-failed' && opened && publishing) {
        notifications.show({
          color: 'red',
          message: JSON.parse(data.outputData.errorMessage).message,
          autoClose: 3000,
        });
        setPublishing(false);
      }
    },
    [opened, publishing, form, formForNotContent],
  );

  const handleClickToRunPublishSequence = async () => {
    setPublishing(true);

    if (opened && formDomain.isDirty() && formDomain.values.domain) {
      addNewDomain(
        formForNotContent.values.workspaceId,
        formDomain.values.domain,
        setPublishing,
        formDomain,
      );
      formDomain.resetDirty(formDomain.values);
    }

    if (opened && faviconFile) {
      // If there is a new favicon, form must be dirty
      const { workspaceId, urlSlug } = formForNotContent.values;
      let uploadResponse;
      try {
        msg({ type: 'upload-to-collection', file: faviconFile });
        // The below uploads to assets folder via Asset Handling Service
        uploadResponse = await uploadFile({ workspaceId, file: [faviconFile] });
      } catch {
        notifications.show({
          color: 'red',
          message: 'Something wrong during new favicon uploads. Halting the publish process.',
          autoClose: 3000,
        });
        setPublishing(false);
        return;
      }

      const favicon = `${workspaceId}/${uploadResponse.filePaths[0]}`;

      notifications.show({
        color: 'blue',
        message: 'New favicon uploads successfully! Continue publishing...',
        autoClose: 3000,
      });

      msg({
        type: 'editing-publish-settings',
        outputData: { ...form.values, urlSlug, favicon },
      });
      form.setFieldValue('favicon', favicon);
      setFaviconFile(null);
    } else if (opened && form.isDirty()) {
      const { urlSlug } = formForNotContent.values;

      // If there is no new favicon, but other form is edited
      msg({ type: 'editing-publish-settings', outputData: { ...form.values, urlSlug } });
    } else {
      // If nothing is edited, should still cater publish
      try {
        await updatePublishSettings(formForNotContent.values);
      } catch (error) {
        let message = 'Unable to update page settings!';
        if (error instanceof Error && error.message) {
          message =
            typeof error.message == 'string' ? JSON.parse(error.message).message : error.message;
        }
        notifications.show({
          color: 'red',
          message,
          autoClose: 3000,
        });
        setPublishing(false);
        return;
      }
      publish(formForNotContent.values.nanoId, setPublishing, openedSub, setOpenedSub)();
    }
  };

  useEffect(() => {
    setOpenedSub(makeOpenSubPanel?.type ?? '');
  }, [makeOpenSubPanel]);

  const handleClickToToggleSubPanel = (panelName: SubPanelTypes) => {
    setOpenedSub(openedSub === panelName ? '' : panelName || '');
  };

  return (
    <Collapse in={opened} transitionDuration={100} transitionTimingFunction="linear">
      <SubMenuContainer style={{ borderRadius: '0px 4px 4px 4px' }} data-editor-sync-when-mousedown>
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Settings"
          label="Publish Settings"
          panel="publish-settings"
          icon={<IconBrowserCheck size={16} />}
        />
        <IconChevronRight size={14} />
        <BaseSubMenuButton
          openedSub={openedSub}
          handleClickToToggleSubPanel={handleClickToToggleSubPanel}
          displayText="Go Live"
          label="Go Live"
          panel="publish-go-live"
          icon={<IconArrowBarUp size={16} />}
        />
      </SubMenuContainer>

      {Object.entries(subPanelCollection).map(([key, obj]) => {
        const Panel = obj.component;
        return (
          <Panel
            key={key}
            opened={openedSub === key}
            handleClickToToggleSubPanel={handleClickToToggleSubPanel}
            dataFromPostMessage={makeOpenSubPanel?.data}
            viewerDataFromPostMessage={makeOpenSubPanel?.viewerData}
            onClickToClose={() => setOpenedSub('')}
            data={{
              loadDomain,
              handleClickToRunPublishSequence,
              publishing,
              faviconFile,
              setFaviconFile,
              form,
              formForNotContent,
              formDomain,
            }}
          />
        );
      })}
    </Collapse>
  );
};

export default PublishSettingsMenu;
