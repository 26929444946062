import { Center, Stack, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { callBrandAnalysisService, createPage } from '../apis';
import TopLeftLogo from '../components/TopLeftLogo';

import { WebsiteForm } from '~/components/WebsiteForm/WebsiteForm';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';

const IngestBrand = () => {
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState<boolean>(false);
  const { userAndWorkspaceInfo } = useFeatureFlags();
  const { user, v2ActiveWorkspace } = userAndWorkspaceInfo;

  if (!user) {
    throw new Error('No user found');
  }

  const { workspaceId } = v2ActiveWorkspace || {};

  if (!workspaceId) {
    throw new Error('No workspace found');
  }

  const handlePageIngestion = (url: string) => {
    setVerifying(true);
    createPage(workspaceId, url)
      .then(({ nanoId, finalUrl }) => {
        navigate(`/onboarding-ingesting?url=${encodeURIComponent(url)}`);
        callBrandAnalysisService(workspaceId, nanoId, finalUrl ?? url)
          .then(() => {
            navigate(`/${nanoId}/edit`);
          })
          .catch(() => {
            notifications.show({
              color: 'red',
              title: 'Unable to process your request',
              message: 'Please try again later.',
              autoClose: 3000,
            });
            navigate(`/pages/new`);
          });
      })
      .catch(() => {
        notifications.show({
          color: 'red',
          title: `Invalid URL`,
          message:
            'The URL entered may contain a typo or is currently unreachable. Please check the URL and try again.',
          autoClose: 4000,
        });
      })
      .finally(() => {
        setVerifying(false);
      });
  };

  return (
    <Center h="100%">
      <Link to="/dashboard">
        <TopLeftLogo src="/upflowy_with_gradient.svg" alt="Upflowy" />
      </Link>

      <Stack w="calc(100vw - 2rem)" maw={320}>
        <Title order={4}>Share the link for your website</Title>
        <Text size="sm" c="var(--mantine-color-gray-7)">
          We’ll use your link to suggest new initiatives you can do instantly for your business
        </Text>
        <WebsiteForm w="100%" label="" verifying={verifying} onSuccess={handlePageIngestion} />
      </Stack>
    </Center>
  );
};

export default IngestBrand;
