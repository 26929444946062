import { useEffect } from 'react';

import PageLoader from '~/components/PageLoader';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { subscribeUserToFreeStripePlan } from '~/services/PaymentServices';

const ContactUs = () => {
  const { userAndWorkspaceInfo } = useFeatureFlags();
  const { hasActiveIntentSubscription } = userAndWorkspaceInfo;

  useEffect(() => {
    if (!hasActiveIntentSubscription) {
      subscribeUserToFreeStripePlan();
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.async = true;
    script.src = `https://sdk.upflowy.com/upflowy.client.js?configId=${
      import.meta.env.VITE_CONTACT_US_SDK
    }`;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <PageLoader />;
};

export default ContactUs;
