import { Stack, Text } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

const EmptyLeadsState = () => (
  <Stack align="center" gap="xs" maw={276}>
    <IconSearch size={24} color="#410CBD" />
    <Text size="lg" ta="center">
      No new contacts yet
    </Text>
    <Text c="dimmed" size="sm" ta="center">
      {'Intent is tracking on your website. ' +
        'Your contact table will automatically updated when we identify a lead with contact information.'}
    </Text>
  </Stack>
);

export default EmptyLeadsState;
