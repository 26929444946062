import { Box, Button, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconWorld } from '@tabler/icons-react';
import isURL from 'validator/lib/isURL';

import { REGEX_WEBSITE_HOSTNAME } from '../../constants';

const INVALID_URL_MESSAGE = 'Enter your website URL in the correct format, like www.company.com';

interface WebsiteFormProps {
  onSuccess: (url: string) => void;
  label?: string;
  hostnameOnly?: boolean;
  w?: number | string;
  verifying?: boolean;
}

export const WebsiteForm = ({
  onSuccess,
  label = 'Enter your website URL',
  hostnameOnly,
  verifying,
  ...rest
}: WebsiteFormProps) => {
  const form = useForm({
    initialValues: {
      url: '',
    },
    validate: {
      url: (value) => {
        if (value.length === 0) return 'Your website URL is required';
        return (hostnameOnly ? REGEX_WEBSITE_HOSTNAME.test(value) : isURL(value))
          ? null
          : INVALID_URL_MESSAGE;
      },
    },
  });

  return (
    <Box {...rest}>
      <form
        onSubmit={form.onSubmit((values) => {
          onSuccess(values.url);
        })}
      >
        <TextInput
          label={label}
          size="md"
          leftSection={<IconWorld size={20} />}
          placeholder="e.g. https://mywebsite.com"
          {...form.getInputProps('url')}
        />
        <Group justify="flex-end" mt="md">
          <Button fullWidth type="submit" bg="var(--m3-sys-light-primary)" loading={verifying}>
            Continue
          </Button>
        </Group>
      </form>
    </Box>
  );
};
