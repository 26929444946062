import { ActionIcon, Menu, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDots, IconTrash } from '@tabler/icons-react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';

import DeleteImageModal from './DeleteImageModal';

import { ImageMasonryCard } from '~/components/ImageMasonry/ImageMasonry';
import {
  addListenerForViewerDropzones,
  removeListenerForViewerDropzones,
} from '~/helpers/viewerInteractions/dropzoneEventHandling';
import { MediaCollectionItem } from '~/providers/MediaCollectionProvider';

interface AddImageProps {
  item: MediaCollectionItem;
  workspaceId: string;
}

const MenuTargetContainer = styled.div`
  right: 8px;
  top: 8px;
  position: absolute;
`;

// This is to ensure listeners won't get added multiple times during monitoring
let hasListener = false;

const AddImage = ({ item = {}, workspaceId }: AddImageProps) => {
  const [openedModal, { open, close }] = useDisclosure(false);

  const [{ cursor }, dragRef] = useDrag(
    () => ({
      type: 'add-from-collection',
      item,
      collect: (monitor) => {
        if (monitor.isDragging() && !hasListener) {
          hasListener = true;
          addListenerForViewerDropzones();
        }
        return {
          cursor: monitor.isDragging() ? 'grabbing' : 'grab',
        };
      },
      end: () => {
        removeListenerForViewerDropzones();
        hasListener = false;
      },
    }),
    [],
  );

  return (
    <div ref={dragRef} style={{ cursor, position: 'relative' }}>
      <div style={{ pointerEvents: 'none' }}>
        <ImageMasonryCard item={item} workspaceId={workspaceId} />
      </div>

      <Menu withinPortal={false} position="bottom-start" offset={2} withArrow>
        <MenuTargetContainer data-testid="menu-target" className="custom-menu">
          <Menu.Target>
            <ActionIcon
              size="xs"
              variant="filled"
              color="white"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <IconDots style={{ width: rem(18), height: rem(18), color: 'gray' }} />
            </ActionIcon>
          </Menu.Target>
        </MenuTargetContainer>
        <Menu.Dropdown z-index={100}>
          <Menu.Label>Image</Menu.Label>
          <Menu.Divider />

          <Menu.Item
            onClick={(event) => {
              event.stopPropagation();
              open();
            }}
            leftSection={<IconTrash style={{ width: rem(16), height: rem(16) }} />}
          >
            Move to trash
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <DeleteImageModal openModal={openedModal} close={close} item={item} />
    </div>
  );
};

export default AddImage;
