import { Badge, BadgeStylesNames, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';

import { TrackingStatus as TrackingStatusTypes } from '../../global.types';

type TrackingStatusProps = {
  status: TrackingStatusTypes;
  lastEventCreatedAt: number | null;
  onClick: () => void;
};

const trackingStatusLabel = {
  ['tracking']: 'Tracking',
  ['not tracking']: 'Not tracking',
  ['never tracked']: 'Not tracking',
  ['loading']: 'Loading...',
};

const styles: Record<
  TrackingStatusTypes | 'loading',
  Partial<Record<BadgeStylesNames, React.CSSProperties>>
> = {
  ['tracking']: {
    root: { backgroundColor: '#EBFBEE', cursor: 'pointer', width: '110px' },
    label: { color: 'green' },
  },
  ['not tracking']: {
    root: { backgroundColor: '#FFF5F5', cursor: 'pointer', width: '110px' },
    label: { color: '#B91D20' },
  },
  ['never tracked']: {
    root: { backgroundColor: '#FFF5F5', cursor: 'pointer', width: '110px' },
    label: { color: '#B91D20' },
  },
  ['loading']: {
    root: { backgroundColor: '#e1e3e3', cursor: 'pointer', width: '110px' },
    label: { color: '#495057' },
  },
};

const TrackingStatus = ({ status, lastEventCreatedAt, onClick }: TrackingStatusProps) => (
  <Tooltip
    disabled={!lastEventCreatedAt}
    label={`Last event received on ${dayjs(lastEventCreatedAt).format('D MMM YYYY, h:mma')}`}
    position="bottom"
    withArrow
  >
    <Badge size="md" styles={styles[status]} onClick={onClick}>
      {trackingStatusLabel[status]}
    </Badge>
  </Tooltip>
);

export default TrackingStatus;
