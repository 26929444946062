import styled from 'styled-components';

const TopLeftLogo = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50%;
  max-width: 132px;
`;

export default TopLeftLogo;
