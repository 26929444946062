import { ChatMessage } from './Message';

import ApiClient from '~/helpers/ApiClient';

type ApiResponse = {
  response: string;
  message: string;
};

export const callChatBot = async (
  chatUID: string,
  message: string,
  chatHistory: ChatMessage[],
  nanoID: string,
) => {
  const client = new ApiClient(import.meta.env?.VITE_AWS_API_BASE_URL);
  const response: ApiResponse = await client.post('/send-message-to-chatbot', {
    conversationID: `${nanoID}-${chatUID}`,
    message,
    chatHistory,
  });
  return JSON.parse(response.response);
};
