import { notifications } from '@mantine/notifications';

import msg from '~/helpers/viewerInteractions/msg';
import { imageAttach } from '~/screens/Studio/pageProcessing/imageUploadAndAttach/imageUploadAndAttach';
import { transferAsset } from '~/services/AssetServices';

const attachImageFromCollection = (workspaceId: string, src: string) => {
  transferAsset(workspaceId, src)
    .then((uploaded) => {
      msg({ type: 'image-upload-complete', uploaded });
    })
    .catch((e) => {
      notifications.show({
        id: 'image-upload-error',
        color: 'red',
        title: 'Upload error',
        message: e.message,
        autoClose: 3000,
      });

      msg({ type: 'restore-page-when-error' });
    });
  return imageAttach(src.split('/thumbnail/')[1]);
};

export default attachImageFromCollection;
