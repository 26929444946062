import { Card, Stack, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import isEmail from 'validator/lib/isEmail';

import { ProfilePayload } from '~/global.types';

const INVALID_EMAIL_MESSAGE = 'Enter your email in the correct format, like charlie@upflowy.com';

type AccountProfileProps = {
  email: string;
  name: string;
  onChange: (payload: Partial<ProfilePayload>) => void;
};

const AccountProfile = ({
  email,
  name,
  onChange,
}: AccountProfileProps) => {
  const form = useForm({
    initialValues: {
      email,
      name,
    },

    validate: {
      email: (value) => {
        if (value.length === 0) return 'Your Email is required';
        return isEmail(value) ? null : INVALID_EMAIL_MESSAGE;
      },
      name: (value) => {
        if (value.length === 0) return 'Your Name is required';
      },
    },
  });

  return (
    <Card shadow="sm" padding="lg" radius="md" style={{ width: '100%' }} withBorder>
      <Stack maw={450}>
        <Title order={3}>Profile</Title>
        <Title order={4}>Account details</Title>
        <form>
          <TextInput disabled label="Email" size="md" {...form.getInputProps('email')} />
          <TextInput
            label="Your name"
            size="md"
            mt="md"
            {...form.getInputProps('name')}
            onChange={(event) => {
              form.setFieldValue('name', event.target.value);
            }}
            onBlur={(event) => {
              onChange({ name: event.target.value });
            }}
          />
        </form>
      </Stack>
    </Card>
  );
};

export default AccountProfile;
