import { ElementClickedBoundRect } from '../../SetBoundForOverlayElements';
import attachImageFromCollection from '../attachImageFromCollection/attachImageFromCollection';
import validateFile from '../validateFile/validateFile';

import { PageJsonSnippetObj, ParsedLandingpageObject } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import imageUploadAndAttach from '~/screens/Studio/pageProcessing/imageUploadAndAttach/imageUploadAndAttach';

const dropToAddElementProcessing = async (
  itemType: string,
  node: PageJsonSnippetObj | { files: File[] },
  hoverBound: ElementClickedBoundRect,
  pageDetails?: Omit<ParsedLandingpageObject, 'content'>,
  additionalState = {},
) => {
  let addNode: PageJsonSnippetObj | undefined;

  const processing: Record<string, () => void | Promise<void>> = {
    'move-page-element'() {
      addNode = node as PageJsonSnippetObj;
      msg({
        type: 'move-page-element',
        messageData: hoverBound,
        selector: addNode.selector as string,
        ...additionalState,
      });
    },

    'add-from-collection'() {
      const { workspaceId } = pageDetails || {};
      const src = '@src' in node && typeof node['@src'] === 'string' ? node['@src'] : '/';

      if (workspaceId) {
        addNode = attachImageFromCollection(workspaceId, src);

        if ('replaceSrc' in additionalState) {
          msg({
            type: 'replace-and-upload-image',
            selector: additionalState.replaceSrc as string,
            imageSrc: addNode['@data-image-src'] as string,
          });
        } else {
          msg({
            type: 'add-image-placeholder-while-uploading',
            messageData: hoverBound,
            addNode,
            ...additionalState,
          });
        }
      }
    },

    async default() {
      // If is files like images, upload the files first and then add the node
      if (Array.isArray(node.files)) {
        if (validateFile(node.files[0])) {
          const { workspaceId } = pageDetails || {};
          msg({ type: 'upload-to-collection', file: (node.files as File[])[0] });
          // The below uploads to assets folder via Asset Handling Service
          addNode = await imageUploadAndAttach(workspaceId, (node.files as File[])[0]);

          if (addNode) {
            if ('replaceSrc' in additionalState) {
              msg({
                type: 'replace-and-upload-image',
                selector: additionalState.replaceSrc as string,
                imageSrc: addNode['@data-image-src'] as string,
              });
            } else {
              msg({
                type: 'add-image-placeholder-while-uploading',
                messageData: hoverBound,
                addNode,
                ...additionalState,
              });
            }
          }
        } else {
          return;
        }
      } else {
        addNode = node as PageJsonSnippetObj;

        msg({
          type: 'add-page-element',
          messageData: hoverBound,
          addNode,
          ...additionalState,
        });
      }
    },
  };

  (processing[itemType] || processing.default)();
};

export default dropToAddElementProcessing;
