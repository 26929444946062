import { notifications } from '@mantine/notifications';

import { PageJsonSnippetObj } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import { StudioMessage } from '~/messages.types';
import { uploadFile } from '~/services/AssetServices';

export const imageAttach = (fileName: string) => ({
  tagName: 'div',
  '@data-image-src': fileName,
  textValue: '<br><br><br><br><br><br>',
  style: {
    width: '100%',
    'align-self': 'center',
  },
});

export const imageAttachStateProcess = (targetedElement: Element, isAddBefore: boolean) => {
  const stateElem = document.createElement('div');
  stateElem.setAttribute('data-image-src', 'true');
  stateElem.setAttribute('slot', targetedElement?.slot || '');
  stateElem.setAttribute('style', 'height: 72px;');
  stateElem.innerHTML = '&nbsp;';

  if (isAddBefore) {
    targetedElement?.parentNode?.insertBefore(stateElem, targetedElement);
  } else {
    targetedElement?.parentNode?.insertBefore(stateElem, targetedElement.nextElementSibling);
  }
};

export const imageAttachState = ({
  addAfter,
  addBefore,
}: StudioMessage['AddImagePlaceholderWhileUploading']) => {
  document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
    const targetedElement = (viewer as HTMLIFrameElement).contentDocument?.querySelector(
      `[data-selector=${addAfter || addBefore}]`,
    );

    if (targetedElement) imageAttachStateProcess(targetedElement, !!addBefore);
  });
};

const imageUploadAndAttach = async (
  workspaceId?: string,
  file?: File,
): Promise<PageJsonSnippetObj | undefined> => {
  if (!workspaceId || !file) {
    console.error('Missing workspaceId or/and file');
    return;
  }

  uploadFile({ workspaceId, file: [file] })
    .then((uploaded) => {
      msg({ type: 'image-upload-complete', uploaded });
    })
    .catch(() => {
      notifications.show({
        id: 'image-upload-error',
        color: 'red',
        message: 'Error adding image to page. Please try again.',
        autoClose: 3000,
      });

      msg({ type: 'restore-page-when-error' });
    });

  return imageAttach(file.name);
};

export default imageUploadAndAttach;
