// Url can be long
/* eslint-disable max-len */
import { Center, Tabs, Text } from '@mantine/core';
import { IconCrown, IconMail } from '@tabler/icons-react';
import { useState } from 'react';
import styled from 'styled-components';

import AskTeammate from './AskTeammate';
import activetabClasses from './WebsiteInstallActive.module.css';
import noActivetabClasses from './WebsiteInstallNoActive.module.css';

const Icons = styled.div`
  margin-bottom: 10px;
`;

const TabText = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

const StyledIframe = styled.iframe`
  border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-2);
  border-top-color: var(--mantine-color-white);
  width: 100%;
  height: max(calc(100vh - 600px), 300px);
`;

type WebInstallProps = {
  code: string;
};

const WebsiteInstall = ({ code }: WebInstallProps) => {
  const [activeTab, setActiveTab] = useState<string | null>(null);

  return (
    <Tabs
      onChange={setActiveTab}
      variant="unstyled"
      classNames={!activeTab ? noActivetabClasses : activetabClasses}
    >
      <Tabs.List>
        <Tabs.Tab value="gtm">
          <Icons>
            <img
              src={`/assets/icon-googletagmanager-${activeTab === 'gtm' ? 'blue' : 'grey'}.png`}
              alt="Google Tag Manager"
              style={{ width: 24 }}
            />
          </Icons>
          <TabText>I have access to</TabText>
          <TabText>Google Tag Manager</TabText>
        </Tabs.Tab>
        <Tabs.Tab value="cms">
          <Icons>
            <Icons>
              <img
                src={`/assets/icon-crm-${activeTab === 'cms' ? 'blue' : 'grey'}.png`}
                alt="Wordpress, Squarespace, Wix"
                style={{ width: 104 }}
              />
            </Icons>
          </Icons>
          <TabText>I have access to my</TabText>
          <TabText>website admin panel</TabText>
        </Tabs.Tab>
        <Tabs.Tab value="manual">
          <Icons>
            <IconCrown size={24} color={activeTab === 'manual' ? '#410bbd' : '#000'} />
          </Icons>
          <TabText>I am a god of code,</TabText>
          <TabText>I’ll install manually</TabText>
        </Tabs.Tab>
        <Tabs.Tab value="invite-help">
          <Icons>
            <IconMail size={24} color={activeTab === 'invite-help' ? '#410bbd' : '#000'} />
          </Icons>
          <TabText>Not my cup of tea,</TabText>
          <TabText>I’ll ask a teammate </TabText>
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="gtm">
        <StyledIframe src="https://intent-upflowy-2.productfruits.help/en/article/google-tag-manager?embed=1&nonav=1" />
      </Tabs.Panel>

      <Tabs.Panel value="cms">
        <StyledIframe src="https://intent-upflowy.productfruits.help/en/article/website-admin-panel?embed=1" />
      </Tabs.Panel>

      <Tabs.Panel value="manual">
        <StyledIframe src="https://intent-upflowy-2.productfruits.help/en/article/direct-installation-guide?embed=1&nonav=1" />
      </Tabs.Panel>

      <Tabs.Panel value="invite-help">
        <Center style={{ marginTop: 20 }}>
          <AskTeammate code={code} />
        </Center>
      </Tabs.Panel>
    </Tabs>
  );
};

export default WebsiteInstall;
