export const revertUploadStateToOrginal = (targetedElement: Element) => {
  const wrapper = targetedElement.parentNode;

  if (wrapper instanceof HTMLElement && wrapper.getAttribute('data-image-src') === 'true') {
    const dataSelector = wrapper.getAttribute('data-selector') || '';
    const slot = wrapper.getAttribute('slot') || '';

    if (dataSelector) targetedElement.setAttribute('data-selector', dataSelector);
    if (slot) targetedElement.setAttribute('slot', slot);

    wrapper.parentNode?.insertBefore(targetedElement, wrapper);
    wrapper.remove();
  }
};

const removeAllUploadState = () => {
  document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
    const elements = (viewer as HTMLIFrameElement).contentDocument?.querySelectorAll(
      '[data-image-src="true"] > *',
    );

    elements?.forEach((targetedElement) => {
      revertUploadStateToOrginal(targetedElement);
    });

    (viewer as HTMLIFrameElement).contentDocument
      ?.querySelectorAll('[data-image-src="true"]')
      .forEach((targetedElement) => {
        targetedElement.remove();
      });
  });
};

export default removeAllUploadState;
