import { Tooltip } from '@mantine/core';
import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { ElementHoverDropline, InvisibleLayer } from '../../PannableOverlays.styles';
import {
  ACCEPT_MESSAGES,
  DropzoneProps,
  LINE_THICKNESS,
  MAX_PADDED_CLICK_AREA,
  PADDED_CLICK_AREA_THICKNESS_PERCENTAGE,
} from '../DropzoneOverlay';

const Dropline = ({
  hoverBound,
  drop,
  label,
  at = 'left',
}: DropzoneProps & { at?: 'left' | 'right' | 'top' | 'bottom' }) => {
  const [{ isOver }, dropzone] = useDrop(
    () => ({
      accept: [...ACCEPT_MESSAGES, NativeTypes.FILE],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      drop,
    }),
    [hoverBound],
  );

  const [inDropArea, setInDropArea] = useState(false);

  const style = (thicknessPercent = 0.2, lineThickness?: number) => {
    const { x = -99, y = -99, width = 0, height = 0 } = hoverBound;
    const thicknessX = lineThickness || Math.min(width * thicknessPercent, MAX_PADDED_CLICK_AREA);
    const thicknessY = lineThickness || Math.min(height * thicknessPercent, MAX_PADDED_CLICK_AREA);

    return {
      left: {
        left: x,
        top: y,
        width: thicknessX,
        height,
      },
      right: {
        // The position at the end of the section, is the most left position plus the width of
        // the section viewport and minus the width of the dropzone.
        left: x + width - thicknessX,
        top: y,
        width: thicknessX,
        height,
      },
      top: {
        left: x,
        top: y - thicknessY / 2,
        width,
        height: thicknessY,
      },
      bottom: {
        left: x,
        // Similar to right's left property but apply it vertically
        top: y + height - thicknessY / 2,
        width,
        height: thicknessY,
      },
    };
  };

  return (
    <InvisibleLayer
      ref={dropzone}
      style={style(PADDED_CLICK_AREA_THICKNESS_PERCENTAGE)[at]}
      onDragOver={() => {
        setInDropArea(true);
      }}
      onDragLeave={() => {
        setInDropArea(false);
      }}
    >
      <Tooltip label={label} opened={isOver && inDropArea} transitionProps={{ duration: 0 }}>
        <ElementHoverDropline
          {...(dropzone && { ref: dropzone })}
          className={`${isOver && inDropArea ? 'is-hovered' : ''}`}
          style={{
            ...style(undefined, LINE_THICKNESS)[at],
          }}
        />
      </Tooltip>
    </InvisibleLayer>
  );
};

export default Dropline;
