import { json } from '@codemirror/lang-json';
import { Container } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import CodeMirror, { EditorView, ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { useEffect, useRef } from 'react';

import {
  AddDivText,
  AddH1Text,
  AddH2Text,
  AddH3Text,
  AddH4Text,
  AddH5Text,
  AddH6Text,
} from './AddText';
import { Panel } from './DevEditor.styles';
import ImageDropzone from './ImageDropzone';

import { ParsedLandingpageObject } from '~/global.types';
import { uploadFile } from '~/services/AssetServices';

interface DevEditorProps {
  pageData?: string;
  pageDetails?: Partial<ParsedLandingpageObject>;
  onChange?: (htmlStr: string) => void;
}

let codeMirrorView: EditorView | undefined;

/**
 * This DevEditor is only used by internal devs to craft and edit landing page due to special
 * requests. This is not a public facing UI.
 *
 * To enable this editor, one can append ?dev=true to the edit URL.
 */
const DevEditor = ({ pageData = '{}', pageDetails = {}, onChange }: DevEditorProps) => {
  const codeMirror = useRef(null);

  useEffect(() => {
    const autoFocusLine = ({ data }: MessageEvent) => {
      const { findString, type } = data;

      if (codeMirrorView && type === 'element-selected-in-viewer') {
        const anchor = codeMirrorView.state.doc.toString().indexOf(findString);

        if (anchor !== -1) {
          // Dispatch a selection and scrollIntoView first inside the editor
          // ensure the virtualised DOM element is rendered
          codeMirrorView.dispatch({
            selection: { anchor },
            scrollIntoView: true,
          });

          // Then do a second scroll but this time align the way you want
          document.querySelector('.cm-activeLine')?.scrollIntoView({ block: 'center' });
        }
      }
    };

    window.addEventListener('message', autoFocusLine);
    return () => {
      window.removeEventListener('message', autoFocusLine);
    };
  }, []);

  useEffect(() => {
    if (codeMirror.current) {
      const { view }: ReactCodeMirrorRef = codeMirror.current;
      codeMirrorView = view;
    }
  }, [pageData]);

  const handleDropToUploadAndAppendPictureTag = async (file: FileWithPath[]) => {
    const { workspaceId = '' } = pageDetails;
    const { filePaths } = await uploadFile({ workspaceId, file });

    if (codeMirrorView) {
      const state = codeMirrorView.state;
      const range = state.selection.ranges[0];
      codeMirrorView.dispatch({
        changes: {
          from: range.from,
          to: range.to,
          insert:
            JSON.stringify({
              contains: [
                {
                  '@src': filePaths[0],
                  tagName: 'img',
                },
              ],
              tagName: 'picture',
            }) + ',',
        },
      });
    }
  };

  return (
    <Panel>
      <ImageDropzone onDrop={handleDropToUploadAndAppendPictureTag} />
      <CodeMirror
        ref={codeMirror}
        value={pageData}
        onChange={onChange}
        extensions={[json()]}
        width="640px"
        height="calc(100vh - 5rem)"
        basicSetup={{
          foldGutter: true,
          highlightActiveLineGutter: true,
          bracketMatching: true,
        }}
      />
      <Container p={16} display="flex">
        <AddH1Text />
        <AddH2Text />
        <AddH3Text />
        <AddH4Text />
        <AddH5Text />
        <AddH6Text />
        <AddDivText />
      </Container>
    </Panel>
  );
};

export default DevEditor;
