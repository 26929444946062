import { Skeleton } from '@mantine/core';
import styled from 'styled-components';

type FakeLeadSummaryProps = {
  randomLength: number;
}

const Wrapper = styled.div`
  filter: blur(4px);
`;

const FakeLeadSummary = ({ randomLength }: FakeLeadSummaryProps) => {
  return (
    <Wrapper>
      <Skeleton animate={false} height={12} width="90%" radius="xl" />
      <Skeleton animate={false} height={12} mt={4} width={`${randomLength+15}%`} radius="xl" />
      <Skeleton animate={false} height={12} mt={4} width={`${randomLength+5}%`} radius="xl" />
      <Skeleton animate={false} height={12} mt={4} width="90%" radius="xl" />
      <Skeleton animate={false} height={12} mt={4} width={`${randomLength}%`} radius="xl" />
    </Wrapper>
  );
};

export default FakeLeadSummary;
