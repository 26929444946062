import styled from 'styled-components';

const BottomLeftImage = styled.img`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50%;
  max-width: 400px;
`;

export default BottomLeftImage;
