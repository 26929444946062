import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-datatable/styles.css';

import router from './Routes';
import NotifyConnectivity from './components/NotifyConnectivity/NotifyConnectivity';
import { StudioAppContextProvider } from './providers/StudioAppContextProvider';
import StyleVariables from './styleVariables';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_ADMIN_DSN,
  environment: import.meta.env.VITE_APP_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    encodeURIComponent(import.meta.env.VITE_APP_CONFIG_WATCHER_API),
    encodeURIComponent(import.meta.env.VITE_AWS_API_BASE_URL),
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <MantineProvider
    theme={{
      fontFamily: 'Rubik',
      headings: { fontFamily: 'Rubik' },
      primaryColor: 'upflowy-purple',
      colors: {
        'upflowy-purple': [
          'var(--m3-sys-light-primary)',
          'var(--m3-sys-light-primary)',
          'var(--m3-sys-light-primary)',
          'var(--m3-sys-light-primary)',
          'var(--m3-sys-light-primary)',
          'var(--m3-sys-light-primary)',
          'var(--m3-sys-light-primary)',
          'var(--m3-sys-light-primary)',
          'var(--m3-sys-light-primary)',
          'var(--m3-sys-light-primary)',
        ],
      },
    }}
  >
    <Notifications position="bottom-center" zIndex={999999} />
    <StyleVariables />
    <StudioAppContextProvider>
      <RouterProvider router={router} />
    </StudioAppContextProvider>
    <NotifyConnectivity />
  </MantineProvider>,
);
