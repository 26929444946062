import { Menu, Tooltip } from '@mantine/core';
import {
  IconLayoutAlignBottom,
  IconLayoutAlignMiddle,
  IconLayoutAlignTop,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { StyledActionIcon } from '../../EditToolbar.styles';

import { ViewerData } from '~/global.types';

const iconProps = { size: 25, stroke: 1.5 };
const alignments = [
  {
    icon: <IconLayoutAlignTop {...iconProps} />,
    value: 'flex-start',
  },
  {
    icon: <IconLayoutAlignMiddle {...iconProps} />,
    value: 'center',
  },
  {
    icon: <IconLayoutAlignBottom {...iconProps} />,
    value: 'flex-end',
  },
];

type VerticalAlignToolProps = {
  handleClickToEmitEvent: (key: string, value: string) => void;
  viewerData: Partial<ViewerData>;
};

const VerticalAlignTool = ({ handleClickToEmitEvent, viewerData }: VerticalAlignToolProps) => {
  const [alignmentValue, setAlignmentValue] = useState<string>('flex-start');

  useEffect(() => {
    setAlignmentValue(viewerData?.editorState?.justifyContent || 'flex-start');
  }, [viewerData]);

  const iconComponent =
    alignments.find((alignment) => alignment.value === alignmentValue)?.icon || alignments[0].icon;

  return (
    <Menu aria-label="vertical-align-tool" position="bottom" zIndex={1001}>
      <Tooltip label="Vertical Alignment" color="Gray" withArrow zIndex={1001}>
        <Menu.Target>
          <StyledActionIcon variant="transparent">{iconComponent}</StyledActionIcon>
        </Menu.Target>
      </Tooltip>
      <Menu.Dropdown>
        <Menu.Label>Vertical Alignment</Menu.Label>
        {alignments.map((alignment) => (
          <Menu.Item
            aria-label={`vertical-align-tool-${alignment.value}`}
            key={alignment.value}
            onClick={() => {
              setAlignmentValue(alignment.value);
              handleClickToEmitEvent('justifyContent', alignment.value);
            }}
          >
            {alignment.icon}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default VerticalAlignTool;
