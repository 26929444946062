import { Card, Grid, rem, Space, Title } from '@mantine/core';
import { useState } from 'react';

import { BadgeConnected, BadgeDisconnected } from '~/components/Badges/Badges';
import EmailExportSwitch from '~/components/EmailExport/EmailExportSwitch';
import SlackModal from '~/components/IntegrationModals/SlackModal';
import WebhookModal from '~/components/IntegrationModals/WebhookModal';
import ServiceIntegration from '~/components/ServiceIntegration';
import { Connection, UserAndWorkspaceInfo } from '~/global.types';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { testWebhook } from '~/services/IntentAPI';
import { updateProperty } from '~/services/PropertyServices';
import { updateProfile } from '~/services/UserServices';

type ColServiceIntegrationProps = {
  title: string;
  description: React.ReactNode;
  imageSrc: string;
  type: string;
  onConnect?: () => void;
};

const IntentIntegrations = () => {
  const [showWebhookModal, setShowWebhookModal] = useState<boolean>(false);
  const [showSlackModal, setShowSlackModal] = useState<boolean>(false);

  const { userAndWorkspaceInfo, updateUserAndWorkspace } = useFeatureFlags();
  const { intentActiveWorkspace, intentProperties, user } =
    userAndWorkspaceInfo as UserAndWorkspaceInfo;
  const activeProperty = intentProperties.length ? intentProperties[0] : null;

  const updatePropertyConfig = async (url: string, type: string) => {
    const config = {
      ...activeProperty?.config,
    };

    if (!config.connections) config.connections = [];

    // Auto deprecate the old WEBHOOK_URL until everyone has migrated
    const { WEBHOOK_URL } = activeProperty?.config || {};
    if (WEBHOOK_URL) {
      config.connections.push({ url: WEBHOOK_URL, type: 'webhook', status: 'connected' });
      delete config?.WEBHOOK_URL;
    }

    const findExisting = config.connections.find((item) => item.type === type);

    if (findExisting) {
      findExisting.url = url;
    } else {
      config.connections.push({ url, type, status: 'connected' });
    }

    await updateProperty({
      workspaceId: intentActiveWorkspace?.workspaceId ?? '',
      propertyId: activeProperty?.id ?? '',
      config,
    });

    // update feature flags
    if (activeProperty) {
      const properties = intentActiveWorkspace?.properties ?? {};
      updateUserAndWorkspace({
        intentProperties: Object.values({
          ...properties,
          [activeProperty.id]: {
            ...activeProperty,
            config,
          },
        }),
      });
    }
    setShowWebhookModal(false);
    setShowSlackModal(false);
  };

  const getConnectionStatus = (type: string): Connection | undefined => {
    // The old WEBHOOK_URL to be deprecated
    const oldWebHookUrl = activeProperty?.config?.WEBHOOK_URL;
    if (oldWebHookUrl && type === 'webhook') {
      return {
        type: 'webhook',
        status: 'connected',
        url: oldWebHookUrl,
      };
    }

    // Official connections facility starts here
    const allConnections = activeProperty?.config?.connections || [];
    return allConnections.find((connection) => connection.type === type);
  };

  const ColServiceIntegration = ({
    title,
    description,
    imageSrc,
    type,
    onConnect,
  }: ColServiceIntegrationProps) => {
    const connectionObj = getConnectionStatus(type);
    const descriptionNode = !connectionObj ? (
      description
    ) : connectionObj?.status === 'connected' ? (
      <BadgeConnected />
    ) : (
      <BadgeDisconnected />
    );

    return (
      <Grid.Col span={6}>
        <ServiceIntegration
          title={title}
          description={descriptionNode}
          image={<img src={imageSrc} style={{ width: rem(48), height: rem(48) }} alt={type} />}
          buttonText={connectionObj ? 'Edit' : 'Connect'}
          onConnect={onConnect}
        />
      </Grid.Col>
    );
  };

  return (
    <>
      <WebhookModal
        initialWebhookURL={getConnectionStatus('webhook')?.url}
        opened={showWebhookModal}
        onSaveConnection={async (webhookUrl) => await updatePropertyConfig(webhookUrl, 'webhook')}
        onClose={() => setShowWebhookModal(false)}
        onTestWebhook={async (webhookUrl: string) =>
          testWebhook({
            workspaceId: intentActiveWorkspace?.workspaceId ?? '',
            propertyId: activeProperty?.id ?? '',
            webhookUrl,
          })
        }
      />

      <SlackModal
        initialWebhookURL={getConnectionStatus('slack')?.url}
        opened={showSlackModal}
        onSaveConnection={async (webhookUrl) => await updatePropertyConfig(webhookUrl, 'slack')}
        onClose={() => setShowSlackModal(false)}
        onTestWebhook={async (webhookUrl: string) =>
          testWebhook({
            workspaceId: intentActiveWorkspace?.workspaceId ?? '',
            propertyId: activeProperty?.id ?? '',
            webhookUrl,
          })
        }
      />

      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Title order={4}>Integrations</Title>
        <Space h="md" />
        <Grid grow>
          <Grid.Col span={6}>
            <EmailExportSwitch
              initialChecked={!!user?.data?.enableEmailNotification}
              onChange={async (value) => {
                const updatedUser = await updateProfile({ enableEmailNotification: value });
                //to update feature flags with latest value
                updateUserAndWorkspace({
                  user: updatedUser,
                });
              }}
            />
          </Grid.Col>

          <ColServiceIntegration
            title="Connect to any CRM"
            description="using Zapier & Webhooks"
            imageSrc="/assets/thumbnail-zapier.png"
            type="webhook"
            onConnect={() => setShowWebhookModal(true)}
          />
          <ColServiceIntegration
            title="Slack"
            description="Send summary to a channel"
            imageSrc="/assets/thumbnail-slack.png"
            type="slack"
            onConnect={() => setShowSlackModal(true)}
          />

          {/** Because this is odd number of columns, remove the below will result span 12 */}
          <Grid.Col span={6} />
        </Grid>
      </Card>
    </>
  );
};
export default IntentIntegrations;
