import { PageJsonSnippetObj } from '~/global.types';

export default {
  contains: [
    {
      textValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      '@slot': 'column-1',
      selector: 'elem-k4uQtswn',
      style: {
        'font-size': '24px',
        'font-family': 'var(--font-family-headings)',
        'line-height': '42px',
        color: 'var(--color-text-darkbg)',
        'font-weight': '700',
        'text-align': 'center',
      },
      style_max1200: {
        padding: '0 60px',
      },
      tagName: 'h1',
      type: 'text',
      object: 'primitive',
    },
    {
      textValue: 'CTA Button',
      '@slot': 'column-2',
      selector: 'elem-2V8u4H7t',
      style: {
        cursor: 'pointer',
        'border-radius': 'var(--button-radius)',
        'background-color': 'var(--color-primary-100)',
        border: '1px solid var(--color-primary-100)',
        padding: '10px 24px',
        color: 'var(--color-text-darkbg)',
        'font-weight': '400',
        width: 'fit-content',
        'font-size': '22px',
        'line-height': '1.5',
        'font-family': 'var(--font-family-headings)',
        'text-align': 'center',
      },
      style_max1200: {
        width: '100%',
        'box-sizing': 'border-box',
      },
      tagName: 'a',
      type: 'button',
      '@href': '',
      '@role': 'button',
      object: 'primitive',
    },
  ],
  'style_max1200_for_.column-2': {
    'max-width': '100% !important',
  },
  'style_max1200_for_.column-1': {
    'max-width': '100% !important',
  },
  'style_for_.column-2': {
    'max-width': 'fit-content',
  },
  'style_for_.column-1': {
    'max-width': 'fit-content',
    'justify-content': 'center',
  },
  'style_max1200_for_.container': {
    'border-radius': '0 !important',
    width: '100%',
  },
  selector: 'layout-c7SaNfJt',
  style: {
    'background-color': '#f6f7f7',
    padding: '48px 0',
  },
  'style_for_.container': {
    'background-color': 'var(--color-tertiary-100)',
    'border-radius': 'var(--card-radius)',
    padding: '32px 0px 32px 0px',
    'justify-content': 'center',
  },
  layoutName: 'column-layout',
} as PageJsonSnippetObj;
