import { Button, Group, MantineTheme, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconChevronUp, IconRefresh, IconSelector } from '@tabler/icons-react';
import { AnalysisRecordPartial } from 'global.types';
import { DataTable, type DataTableSortStatus } from 'mantine-datatable';
import { useCallback, useEffect, useState } from 'react';

import AnalysisCell from './AnalysisCell';
import ContactCell from './ContactCell';
import CreatedAtCell from './CreatedAtCell';
import EmptyLeadsState from './EmptyLeadsState';
import LeadRowDetails from './LeadRowDetails';
import StyledStack from './StyledStack';
import WorkspaceQuotaInfo from './WorkspaceQuotaInfo';

import { getPaginatedLeads } from '../../services/IntentAPI';

import { isSuperAdmin } from '~/services/UserServices';

const PAGE_SIZE = 10;
const INITIAL_SORTED_BY_COLUMN = 'createdAt';
const INITIAL_SORTED_BY_DIRECTION = 'desc';

const columns = [
  {
    accessor: 'createdAt',
    title: 'Date and time',
    width: '20%',
    sortable: true,
    render: CreatedAtCell,
  },
  {
    accessor: 'contacts',
    title: 'Contact details',
    width: '27.5%',
    render: ContactCell,
  },
  {
    accessor: 'analysis',
    title: 'Lead summary',
    width: '52.5%',
    render: AnalysisCell,
  },
];

const sortIcons = {
  sorted: <IconChevronUp size={14} />,
  unsorted: <IconSelector size={14} />,
};

const styles = {
  root: (theme: MantineTheme) => ({
    borderRadius: theme.radius.md,
  }),
  table: {
    color: '#666',
  },
  header: {
    color: '#141517',
    borderBottom: '1px solid #DEE2E6',
    backgroundColor: '#E9ECEF',
  },
};

const handleError = (error: Error) => {
  console.error(error);
  notifications.show({
    color: 'red',
    title: 'Error fetching leads',
    message: 'Our team has been notified. Contact us if the problem persists.',
    autoClose: 3000,
  });
};

const IntentLeads = ({ workspaceId, propertyId }: { workspaceId: string; propertyId: string }) => {
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<AnalysisRecordPartial>>({
    columnAccessor: INITIAL_SORTED_BY_COLUMN,
    direction: INITIAL_SORTED_BY_DIRECTION,
  });
  const [records, setRecords] = useState<AnalysisRecordPartial[]>([]);
  const [refreshing, setRefreshing] = useState(false);

  let rowExpansion:
    | undefined
    | {
        allowMultiple: boolean;
        content: ({ record }: { record: AnalysisRecordPartial }) => JSX.Element;
      } = undefined;
  if (isSuperAdmin()) {
    rowExpansion = {
      allowMultiple: true,
      content: ({ record }: { record: AnalysisRecordPartial }) => <LeadRowDetails {...record} />,
    };
  }

  const handleLeads = async (analysis: AnalysisRecordPartial[], total: number) => {
    setRecords(
      analysis.map(
        (record) =>
          ({
            ...record,
            randomLength: Math.random() * (75 - 10) + 10,
          }) as AnalysisRecordPartial,
      ),
    );
    setTotalRecords(total);
  };

  useEffect(() => {
    setFetching(true);
    getPaginatedLeads({
      workspaceId,
      propertyId,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      orderBy: `${sortStatus.columnAccessor}:${sortStatus.direction}`,
    })
      .then((data) => handleLeads(data.records, data.totalRecords))
      .catch(handleError)
      .finally(() => setFetching(false));
  }, [page, sortStatus]);

  const onRefreshClick = useCallback(() => {
    setRefreshing(true);
    getPaginatedLeads({
      workspaceId,
      propertyId,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      orderBy: `${sortStatus.columnAccessor}:${sortStatus.direction}`,
    })
      .then((data) => handleLeads(data.records, data.totalRecords))
      .catch(handleError)
      .finally(() => setRefreshing(false));
  }, [page, sortStatus]);

  const onPageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onSortStatusChange = useCallback(
    (dataTableSortStatus: DataTableSortStatus<AnalysisRecordPartial>) => {
      setSortStatus(dataTableSortStatus);
    },
    [],
  );

  return (
    <StyledStack>
      <WorkspaceQuotaInfo workspaceId={workspaceId} propertyId={propertyId} />
      <Group justify="space-between">
        <Title order={2} style={{ marginBottom: '15px' }}>
          Leads
        </Title>
        <Button
          disabled={fetching || refreshing}
          leftSection={<IconRefresh className={refreshing ? 'spin' : ''} size={14} />}
          variant="default"
          onClick={onRefreshClick}
        >
          Refresh
        </Button>
      </Group>
      <DataTable
        fetching={fetching}
        height="calc(80vh - 100px)"
        emptyState={<EmptyLeadsState />}
        records={records}
        idAccessor={(record: AnalysisRecordPartial) => {
          if (record.leadId) return record.leadId;
          if (record.anonymousUserId && record.sessionId)
            return `${record.anonymousUserId}.${record.sessionId}`;
          return record.createdAt;
        }}
        columns={columns}
        totalRecords={totalRecords}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={onPageChange}
        sortStatus={sortStatus}
        onSortStatusChange={onSortStatusChange}
        sortIcons={sortIcons}
        styles={styles}
        rowExpansion={rowExpansion}
      />
    </StyledStack>
  );
};

export default IntentLeads;
