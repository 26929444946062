import { PageJsonSnippetObj } from '~/global.types';

export const getStyleWithString = (findString: string) => (obj: PageJsonSnippetObj) =>
  obj.tagName === 'style' && (obj.textValue as string).includes(findString);

export function formatBrandColorName(colorName: string) {
  return colorName
    .replace(/--/g, '')
    .replace('-100', '')
    .replace(/-/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

export const checkIsAssetsPathUrlInBackgroundImage = (backgroundImage: string) =>
  backgroundImage.startsWith('url(assets/');

export const checkIsUrlInBackgroundImage = (backgroundImage: string) =>
  backgroundImage.includes('url(');

export const checkIsGradientAsBackgroundImage = (backgroundImage: string) =>
  backgroundImage.includes('-gradient');

export const checkIsSolidColorBrandPreset = (backgroundColor: string) =>
  backgroundColor.includes('--color');
