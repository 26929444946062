import styled, { createGlobalStyle } from 'styled-components';

export const HideChat = createGlobalStyle`
  html {
    overscroll-behavior-x: none;
  }
  
  #brevo-conversations {
    display: none !important;
    opacity: 0 !important;
    z-index: -1 !important;
    left: -9999px !important;
    top: -9999px !important;
  }

  .mantine-Select-group:first-child > .show-group-line-only {
    display: none;
  }

  // Show group line only is made to fit for the design that Mantine doesn't offer
  // from their Select component
  .show-group-line-only {
    font-size: 0px;
    padding: 8px 8px 8px 0;
  }

  .replacing-image {
    position: relative;
    pointer-events: none;
  }

  .replacing-image::before {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(105, 49, 237, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .replacing-image::after {
    content: ' ';
    display: block;
    width: 36px;
    height: 36px;
    border: 4px solid rgba(105, 49, 237, 1);
    border-radius: 50%;
    border-left-color: transparent;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;
  }

  .checker {
    background: conic-gradient(#ffffff 90deg,#CED4DA 90deg 180deg,#ffffff 180deg 270deg,#CED4DA 270deg);
    background-repeat: repeat ;
    background-size: 24px 24px;
    background-position: top left;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const BgBlur = styled.div`
  width: 200px;
  height: 360px;
  flex-shrink: 0;
  border-radius: 354px;
  background: #8f33d7;
  filter: blur(75px);
  position: absolute;
`;
