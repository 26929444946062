import { Alert, Button } from '@mantine/core';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  background: linear-gradient(90deg, #7c1acb 7.13%, #5e27d3 50.13%);
  border-radius: 0px;
  color: var(--color-white, #fff);
  font-size: 12px;
  .mantine-Alert-title {
    align-items: center;
    justify-content: center;
  }
  button {
    margin-left: 10px;
    border-radius: 4px;
    background: var(--color-white, #fff);
    color: #7c1acb;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: 22px;
  }
`;

type Props = {
  open: boolean;
  handleClickClaimPlan: () => void;
};

const ProductHuntAlert = ({ open, handleClickClaimPlan }: Props) => {
  if (open) {
    return (
      <StyledAlert
        title={
          <>
            <span>🎁 Product Hunt Launch Sale!!! One week ONLY. Save 75%</span>
            <Button type="button" onClick={handleClickClaimPlan}>
              Claim your plan now
            </Button>
          </>
        }
      />
    );
  }
  return null;
};

ProductHuntAlert.defaultProps = {
  open: true,
};

export default ProductHuntAlert;
