import { Container, Space } from '@mantine/core';
import { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';

import PrimaryNavigationMenu from '~/components/PrimaryNavigationMenu';
import { ListItem } from '~/components/PrimaryNavigationMenu/types';
import ScreenContainer from '~/components/ScreenContainer/ScreenContainer.styles';
import { Workspace } from '~/global.types';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';

const StyledContainer = styled(Container)`
  max-width: 1148px;
  margin-left: auto;
  margin-right: auto;
`;

const DefaultLayout = ({ children }: PropsWithChildren) => {
  const { isLoadingWorkspace, userAndWorkspaceInfo, updateUserAndWorkspace } = useFeatureFlags();
  const { v2Workspaces, v2ActiveWorkspace } = userAndWorkspaceInfo;
  const [listOfWorkspaces, setListOfWorkspaces] = useState<ListItem[]>([]);
  const [activeWorkspace, setActiveWorkspace] = useState<ListItem | undefined>(undefined);

  useEffect(() => {
    if (!isLoadingWorkspace) {
      const listItems = v2Workspaces.map((workspace: Workspace) => ({
        id: workspace.workspaceId,
        name: workspace.name,
      }));
      setListOfWorkspaces(listItems);

      if (!v2ActiveWorkspace) {
        setActiveWorkspace(listItems[0]);
      } else {
        setActiveWorkspace({ id: v2ActiveWorkspace?.workspaceId, name: v2ActiveWorkspace?.name });
      }
    }
  }, [isLoadingWorkspace, v2Workspaces.length]);

  const setSelectItemFromList = (id: string) => {
    const selectedWorkspace = v2Workspaces.find(
      (workspace: Workspace) => workspace.workspaceId === id,
    );
    if (selectedWorkspace) {
      setActiveWorkspace({ id: selectedWorkspace?.workspaceId, name: selectedWorkspace?.name });
      localStorage.setItem('activeWorkspace', selectedWorkspace.workspaceId);
      updateUserAndWorkspace({ v2ActiveWorkspace: selectedWorkspace });
    }
  };

  return (
    <ScreenContainer
      style={{
        backgroundColor: '#E9ECEF',
        overflow: 'auto',
      }}
    >
      <Space h="md" />

      <StyledContainer>
        <PrimaryNavigationMenu
          list={listOfWorkspaces}
          selectedItem={{ id: activeWorkspace?.id ?? '', name: activeWorkspace?.name ?? '' }}
          setSelectItemFromList={setSelectItemFromList}
          contentEditable={false}
          showDropdown={true}
        />
        {children}
      </StyledContainer>
    </ScreenContainer>
  );
};

export default DefaultLayout;
