import { Notification } from '@mantine/core';
import styled from 'styled-components';

const StyledNotification = styled(Notification)`
  position: absolute;
  z-index: 100;
  top: 10px;
`;

const Alert = ({
  title,
  message,
  onClose,
}: {
  title: string;
  message: string;
  onClose?: () => void;
}) => {
  return (
    <StyledNotification withBorder color="red" radius="xs" title={title} onClose={onClose}>
      {message}
    </StyledNotification>
  );
};

export default Alert;
