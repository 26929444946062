import { Button, Group, Modal, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useState } from 'react';

import { MediaCollectionItem, useMediaCollection } from '~/providers/MediaCollectionProvider';
import { deleteImages } from '~/services/AssetServices';

const DeleteImageModal = ({
  openModal,
  close,
  item,
}: {
  openModal: boolean;
  close: () => void;
  item: MediaCollectionItem;
}) => {
  const [, [workspaceId], [imageList, setImageList]] = useMediaCollection();
  const [deleting, setDeleting] = useState(false);

  const removeImageToList = (deleteItem: MediaCollectionItem) => {
    const updatedItems = imageList.filter((image) => image['@src'] !== deleteItem['@src']);
    setImageList(updatedItems);
  };

  const handleClickDelete = async () => {
    try {
      setDeleting(true);
      await deleteImages(workspaceId, item['@src'] ?? '');
      removeImageToList(item);

      notifications.show({
        color: 'green',
        message: 'Image deleted successfully!',
        autoClose: 3000,
        icon: <IconCheck />,
      });
    } catch (error) {
      console.log('error', error);
      notifications.show({
        color: 'red',
        message: 'Unable to delete image!',
        autoClose: 3000,
      });
    } finally {
      setDeleting(false);
      close();
    }
  };

  return (
    <Modal opened={openModal} onClose={close} title="Delete this image?" zIndex={10001}>
      <Text size="sm">
        You are about to delete <b style={{ overflowWrap: 'anywhere' }}>{item['@alt']}</b>.
        <br />
        <br />
        This can't be undone.
      </Text>

      <Group mt="md" justify="flex-end">
        <Button onClick={close} variant="default" type="button" disabled={deleting}>
          Cancel
        </Button>
        <Button onClick={handleClickDelete} loading={deleting}>
          Delete
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteImageModal;
