import { Button, FileButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

import { IMAGE_SIZE_LIMIT, SUPPORTED_IMAGE_TYPES } from '~/constants';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { MediaCollectionItem, useMediaCollection } from '~/providers/MediaCollectionProvider';
import { uploadImages } from '~/services/AssetServices';

interface ImageUploaderProps {
  colors?: string;
  opacity?: number;
  customText?: string;
  onFileSelected?: (image: File | null) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  colors,
  opacity,
  customText,
  onFileSelected,
}) => {
  const [, [workspaceId], [imageList, setImageList]] = useMediaCollection();
  const [file, setFile] = useState<File | null>(null);
  const [loader, setLoader] = useState(false);

  const addImageToList = (datatItem: MediaCollectionItem) => {
    setImageList([datatItem, ...imageList]);
  };

  const uploadSelectedFile = async (image: File | null) => {
    setFile(image);
    if (image === null) return;
    if (image.size > IMAGE_SIZE_LIMIT) {
      notifications.show({
        color: 'red',
        message: 'The image file is too large. Please try a smaller image.',
        autoClose: 3000,
      });
      return;
    }
    try {
      setLoader(true);
      if (onFileSelected) onFileSelected(image);
      const { images_urls } = await uploadImages({ workspaceId, files: [image] });
      addImageToList({
        tagName: 'img',
        '@src': images_urls[0].thumbnail,
        '@alt': image.name,
      });
    } catch (error) {
      notifications.show({
        id: 'image-upload-error',
        color: 'red',
        message: 'Error uploading image. Please try again.',
        autoClose: 3000,
      });
    } finally {
      setFile(null);
      setLoader(false);
    }
  };

  useViewerMessage(
    ({ data }) => {
      if (data.type === 'upload-to-collection') {
        uploadSelectedFile(data.file);
      }
    },
    [workspaceId, addImageToList],
  );

  return (
    <>
      <FileButton onChange={uploadSelectedFile} accept={SUPPORTED_IMAGE_TYPES.join(',')}>
        {(props) => (
          <Button
            {...props}
            fullWidth
            loading={loader}
            data-testid="image-upload"
            mb="sm"
            color={colors}
            opacity={opacity}
            style={{ border: '1px solid #ffffff' }}
          >
            {loader ? `Uploading...${file?.name}` : customText || 'Upload Images'}
          </Button>
        )}
      </FileButton>
    </>
  );
};

export default ImageUploader;
