import { Anchor, Text } from '@mantine/core';
import { AnalysisRecordPartial, AnalysisStatus } from 'global.types';
import parse from 'html-react-parser';
import styled from 'styled-components';

import AnalysisNeedsQuota from './AnalysisNeedsQuota';
import AnalysisProcessing from './AnalysisProcessing';

import { ANALYSIS_STATUS_NEEDS_QUOTA, ANALYSIS_STATUS_NEW } from '~/constants';

const isLeadProcessing = (status: AnalysisStatus, createdAt: number) => {
  const tenMinutesAgoTimestamp = Date.now() - 1000 * 60 * 10;
  return status === ANALYSIS_STATUS_NEW && createdAt > tenMinutesAgoTimestamp;
};

const StyledText = styled(Text)`
  color: #84909c;
`;

const BreakLineText = styled(Text)`
  white-space: pre-wrap;
  strong {
    font-weight: bold;
  }
`;

const AnalysisCell = ({ status, createdAt, analysis, randomLength }: AnalysisRecordPartial) => {
  if (analysis) {
    return <BreakLineText size="sm">{parse(analysis)}</BreakLineText>;
  }
  if (status === ANALYSIS_STATUS_NEEDS_QUOTA) {
    return <AnalysisNeedsQuota randomLength={randomLength} />;
  }
  if (isLeadProcessing(status, createdAt)) {
    return <AnalysisProcessing randomLength={randomLength} />;
  }
  return (
    <StyledText size="sm">
      We don’t have enough data to generate a summary.&nbsp;
      <Anchor
        href="https://intent-upflowy-2.productfruits.help/en/article/not-enough-data-error?nonav=1"
        target="_blank"
      >
        Learn why.
      </Anchor>
    </StyledText>
  );
};

export default AnalysisCell;
