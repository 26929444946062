import { useLayoutEffect, useState } from 'react';

import EditToolbar from './EditToolbar';
import { initialBound } from './PannableOverlays';
import { BoundingBoxSupportingText, ElementClickedBoundBox } from './PannableOverlays.styles';
import {
  ElementClickedBoundRect,
  pinPointerFromElementBoundInViewer,
  transformPointerToElementBound,
} from './SetBoundForOverlayElements';

import { ViewerData } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import { postMessagesToParent } from '~/helpers/viewerInteractions/postMessageOnParentTrigger';
import useCopyPaste from '~/hooks/useCopyPaste/useCopyPaste';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';

const SelectElement = ({
  show,
  allowDevMode,
}: {
  show: boolean;
  allowDevMode: {
    enabled: boolean;
  };
}) => {
  const [selectBound, setSelectBound] = useState<ElementClickedBoundRect>(initialBound);

  useViewerMessage(
    ({ data }) => {
      if (data?.type === 'element-bounding-client-update') {
        setTimeout(() => {
          pinPointerFromElementBoundInViewer('select', data.viewer, data.rect);
          transformPointerToElementBound<Partial<ViewerData>>('select', data, setSelectBound);
        }, 120);
      }

      if (
        ['update-page-content-success', 'update-page-variant-state-success'].includes(data?.type)
      ) {
        transformPointerToElementBound('select', selectBound.data, setSelectBound);
      }

      if (
        data?.type === 'viewer-refreshed' &&
        selectBound.data.viewer &&
        selectBound.data.viewer === data.viewer
      ) {
        postMessagesToParent(selectBound.data);
      }

      const resetBoundEvents = [
        'reset-bound',
        'broadcast-history-change-page-data',
        'delete-page-elements',
      ];
      if (resetBoundEvents.includes(data?.type)) {
        // Usually this is called when clicking out the viewer
        setSelectBound(initialBound);
        // If any edit has been perform within EditorJS, make sure it sync across all viewer and trigger autosave
        msg({ type: 'editor-sync' });
      }

      // We may consider converage this with viewer-refreshed
      if (data?.type === 'update-bound') {
        postMessagesToParent(selectBound.data);
      }

      if (
        data?.type === 'image-fully-loaded' &&
        selectBound.data.viewer &&
        selectBound.data.viewer === data.viewer
      ) {
        postMessagesToParent(selectBound.data, undefined, true);
      }
    },
    [selectBound],
  );

  useLayoutEffect(() => {
    // This if condition ensure the outdated selectBound does not recreate the selection boundary
    if (selectBound.data.elementSelector || selectBound.data.rootNodeHost) {
      transformPointerToElementBound('select', selectBound.data, setSelectBound);
    }
  }, [show]);

  useCopyPaste(selectBound.data);

  return (
    <>
      {allowDevMode.enabled && (
        <div
          style={{
            position: 'fixed',
            pointerEvents: 'all',
            zIndex: 999,
            left: (selectBound.x || -99) + (selectBound.width || 0) + 4,
            top: selectBound.y || -99, // leave a bit of gap looks nicer,
          }}
        ></div>
      )}

      <EditToolbar
        hasSlot={selectBound.data.slot}
        tagName={selectBound.data.tagName || ''}
        style={{
          left: selectBound.x || -99,
          top: (selectBound.y || -99) + (selectBound.height || 0) + 4, // leave a bit of gap looks nicer,
        }}
        allowDevMode={allowDevMode}
      />

      <BoundingBoxSupportingText
        style={{
          left: selectBound.x || -99,
          top: selectBound.y || -99,
        }}
      >
        {selectBound.data.columnLayoutInnerComponentType || ''}
      </BoundingBoxSupportingText>
      <ElementClickedBoundBox
        aria-label="Element selected"
        style={{
          left: selectBound.x || -99,
          top: selectBound.y || -99,
          width: selectBound.width || 0,
          height: selectBound.height || 0,
        }}
      />
    </>
  );
};

export default SelectElement;
