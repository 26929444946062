import { Skeleton } from '@mantine/core';

const CardSkeleton = ({ visible }: { visible: boolean }) => (
  <span>
    <Skeleton visible={visible} height={144} mt={6} />
    <Skeleton visible={visible} height={8} mt={6} width="60%" radius="xl" />
    <Skeleton visible={visible} height={8} mt={6} width="60%" radius="xl" />
  </span>
);

export default CardSkeleton;
