import { Flex, Popover, TextInput, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconRadiusTopLeft } from '@tabler/icons-react';
import Color from 'color';
import { ChangeEvent, useEffect, useState } from 'react';

import { RingIcon, StyledActionIcon } from '../../EditToolbar.styles';

import { ColorEditor } from '~/components/ColorEditor/ColorEditor';
import { ViewerData } from '~/global.types';

interface BorderToolProps {
  viewerData: Partial<ViewerData>;
  handleUpdate: (key: string, newValue: string) => void;
}

const BorderTool = ({ viewerData, handleUpdate }: BorderToolProps) => {
  const [opened, setOpened] = useDisclosure(false);
  const [value, setValue] = useState({
    radius: '0',
    style: 'none',
    width: '0',
    color: '#000000ff',
  });

  useEffect(() => {
    const { border = '0', borderRadius = '0' } = viewerData?.editorState || {};
    setOpened.close();
    // We do not have the design nor the product call to work on border style yet
    // But, here is the disabled variable if you need one, which can re-enable it easily
    const [width, style, ...colorParts] = border.split(' ');

    try {
      setValue({
        width,
        style,
        color: Color(colorParts.join(' ')).hex().toLowerCase(),
        radius: borderRadius,
      });
    } catch {
      return;
    }
  }, [viewerData]);

  const { color, style, width, radius } = value;
  const noBorder = style === 'none' || parseInt(width, 10) <= 0;

  const handleChangeToUpdateColor = (hexColor: string) => {
    if (hexColor.toLowerCase() !== color.toLowerCase()) {
      const newValue = { ...value, color: hexColor };
      if (noBorder) {
        newValue.width = '8px';
        newValue.style = 'solid';
      }
      setValue(newValue);
      handleUpdate('border', `${newValue.width} ${newValue.style} ${hexColor}`);
    }
  };

  const handleChangeToUpdateWidth = (event: ChangeEvent<HTMLInputElement>) => {
    const widthStr = event.target?.value;
    const newWidth = isNaN(widthStr as unknown as number) ? widthStr : `${widthStr}px`;
    const newStyle = parseInt(widthStr as string, 10) > 0 ? 'solid' : 'none';
    setValue({ ...value, width: widthStr });
    handleUpdate('border', `${newWidth} ${newStyle} ${color}`);
  };

  const handleChangeToUpdateRadius = (event: ChangeEvent<HTMLInputElement>) => {
    const radiusStr = event.target?.value;
    const rad = isNaN(radiusStr as unknown as number) ? radiusStr : `${radiusStr}px`;
    setValue({ ...value, radius: radiusStr });
    handleUpdate('borderRadius', rad);
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened.close}
      position="bottom"
      withinPortal={false}
      zIndex={1001}
    >
      <Tooltip label="Border" color="Gray" withArrow zIndex={1001}>
        <Popover.Target>
          <StyledActionIcon
            aria-label="border-tool"
            variant="transparent"
            onClick={setOpened.toggle}
          >
            <RingIcon color={noBorder ? '#ffffff00' : color} size="2.5px" withShadow={false} />
          </StyledActionIcon>
        </Popover.Target>
      </Tooltip>
      <Popover.Dropdown>
        <Flex mb={8} gap={8}>
          <TextInput
            leftSectionPointerEvents="none"
            leftSection={<img src="../border-width.svg" />}
            size="xs"
            w="90"
            value={width}
            onChange={handleChangeToUpdateWidth}
            aria-label="Set border width"
          />
          <TextInput
            leftSectionPointerEvents="none"
            leftSection={<IconRadiusTopLeft />}
            size="xs"
            w="90"
            value={radius}
            onChange={handleChangeToUpdateRadius}
            aria-label="Set border radius"
          />
        </Flex>
        <ColorEditor
          key={viewerData.elementSelector}
          value={color || '#000'}
          onChange={handleChangeToUpdateColor}
          label="Set border color"
          reverse
          noAlpha
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default BorderTool;
