import { ActionIcon, Flex, Input, Popover, SimpleGrid, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconBorderSides,
  IconLayoutAlignBottom,
  IconLayoutAlignLeft,
  IconLayoutAlignRight,
  IconLayoutAlignTop,
  IconLayoutDistributeHorizontal,
  IconLayoutDistributeVertical,
} from '@tabler/icons-react';
import { ChangeEventHandler, useEffect, useState } from 'react';

import { StyledActionIcon } from '../../EditToolbar.styles';

import { ViewerData } from '~/global.types';

interface PaddingToolProps {
  viewerData: Partial<ViewerData>;
  handleUpdate: (key: string, value: string) => void;
}

const initialPadding = ['0px', '0px', '0px', '0px'];

const validPaddingUnit = (strValue?: string) =>
  /^\d+(px|em|rem|cm|mm\b)?(,\d+(px|em|rem|cm|mm\b))*$/.test(strValue || '');

const formateValue = (v: (string | undefined)[]) => {
  return v.map((str) => (/^\d+(\.\d+)?$/.test(str || '') ? `${str}px` : str)).join(' ');
};

const PaddingTool = ({ viewerData, handleUpdate }: PaddingToolProps) => {
  const [opened, setOpened] = useDisclosure(false);
  const [isAdvanceInput, setIsAdvanceInput] = useDisclosure(false);
  const [value, setValue] = useState<(string | undefined)[]>(initialPadding);

  useEffect(() => {
    const { editorState } = viewerData;
    setOpened.close();
    setValue(editorState?.padding || initialPadding);
  }, [viewerData]);

  const px = value[1] === value[3] ? value[1] : `${value[3]},${value[1]}`;
  const py = value[0] === value[2] ? value[0] : `${value[0]},${value[2]}`;

  const handleChangeToUpdateValue =
    (index: number): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      const newValue = [...value];
      newValue[index] = (e.target as HTMLInputElement).value;
      setValue(newValue);
      if (validPaddingUnit(newValue[index])) {
        handleUpdate('padding', formateValue(newValue));
      }
    };

  const handleChangeToUpdateCombinedValue =
    (index1: number, index2: number): ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      const newValue = [...value];
      const strValue = (e.target as HTMLInputElement).value;

      // Comma needs to be checked before spliting. This is handled as a controlled state
      // and missing the check can impact on user input experience.
      if (strValue.includes(',')) {
        const splitValue = strValue.split(',');
        newValue[index1] = splitValue[0];
        newValue[index2] = splitValue[1] || '';
      } else {
        newValue[index1] = strValue;
        newValue[index2] = strValue;
      }

      setValue(newValue);

      if (validPaddingUnit(newValue[index1]) && validPaddingUnit(newValue[index2])) {
        handleUpdate('padding', formateValue(newValue));
      }
    };

  return (
    <Popover opened={opened} onChange={setOpened.close} position="bottom" zIndex={1001}>
      <Tooltip label="Padding" color="Gray" withArrow zIndex={1001}>
        <Popover.Target>
          <StyledActionIcon
            aria-label="padding-tool"
            variant="transparent"
            onClick={setOpened.toggle}
          >
            <IconLayoutDistributeVertical size={20} stroke={1.5} />
          </StyledActionIcon>
        </Popover.Target>
      </Tooltip>
      <Popover.Dropdown>
        <Flex gap={8}>
          <ActionIcon
            aria-label="define padding individually"
            variant="default"
            onClick={setIsAdvanceInput.toggle}
            bg={isAdvanceInput ? 'var(--mantine-color-gray-2)' : 'transparent'}
            size={36}
          >
            <IconBorderSides size={20} stroke={1.5} />
          </ActionIcon>
          {isAdvanceInput ? (
            <SimpleGrid spacing={8} cols={2}>
              <Input
                w={100}
                aria-label="padding-left input"
                leftSection={<IconLayoutAlignLeft />}
                value={value[3]}
                onChange={handleChangeToUpdateValue(3)}
              />
              <Input
                w={100}
                aria-label="padding-top input"
                leftSection={<IconLayoutAlignTop />}
                value={value[0]}
                onChange={handleChangeToUpdateValue(0)}
              />
              <Input
                w={100}
                aria-label="padding-right input"
                leftSection={<IconLayoutAlignRight />}
                value={value[1]}
                onChange={handleChangeToUpdateValue(1)}
              />
              <Input
                w={100}
                aria-label="padding-bottom input"
                leftSection={<IconLayoutAlignBottom />}
                value={value[2]}
                onChange={handleChangeToUpdateValue(2)}
              />
            </SimpleGrid>
          ) : (
            <SimpleGrid spacing={8} cols={2}>
              <Input
                w={100}
                aria-label="horizontal-padding input"
                leftSection={<IconLayoutDistributeVertical />}
                value={px}
                onChange={handleChangeToUpdateCombinedValue(3, 1)}
              />
              <Input
                w={100}
                aria-label="vertical-padding input"
                leftSection={<IconLayoutDistributeHorizontal />}
                value={py}
                onChange={handleChangeToUpdateCombinedValue(0, 2)}
              />
            </SimpleGrid>
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export default PaddingTool;
