import { Code, Text, Title } from '@mantine/core';

import NoActivityDetected from './NoActivityDetected';

import CodeWithCopyButton from '../CodeWithCopyButton/CodeWithCopyButton';
import WebsiteInstall from '../WebsiteInstall/WebsiteInstall';

import StyledStack from '~/components/IntentLeads/StyledStack';
import { TrackingStatus } from '~/global.types';
import composeSDKCode from '~/helpers/composeSDKCode/composeSDKCode';

type ConnectWebsiteProps = {
  propertyId: string;
  status?: TrackingStatus;
};

const ConnectWebsite = ({ propertyId, status }: ConnectWebsiteProps) => {
  const headTag = '<head>  </head>';
  const code = composeSDKCode(propertyId);

  return (
    <StyledStack>
      {status === 'never tracked' && <NoActivityDetected />}
      <Title order={3}>Connect to your website</Title>
      <Text size="sm" style={{ color: 'var(--color-grey)' }}>
        This tiny piece of code needs to make its way to your website, between the{' '}
        <Code>{headTag}</Code> tags.
      </Text>
      <CodeWithCopyButton code={code} />
      <Title order={4}>How would you like to install?</Title>
      <WebsiteInstall code={code} />
    </StyledStack>
  );
};

export default ConnectWebsite;
