import { Divider, Paper, Popover, TextInput } from '@mantine/core';
import { IconCircleX, IconFilter } from '@tabler/icons-react';
import { useState } from 'react';

const FieldSearchHeader = ({
  name,
  handleSearch,
}: {
  name: string;
  handleSearch: (key: string) => void;
}) => {
  const [textToSearch, setTextToSearch] = useState<string>('');
  const [opened, setOpened] = useState(false);

  const handleEmailSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextToSearch(event.target.value);
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(textToSearch);
      setOpened(false);
    }
  };

  const handleClearSearch = () => {
    setTextToSearch('');
    handleSearch('');
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom"
      clickOutsideEvents={['mouseup', 'touchend']}
    >
      <Popover.Target>
        <IconFilter
          data-testid={`filter-icon-${name}`}
          onClick={() => setOpened(true)}
          style={{
            verticalAlign: 'middle',
            cursor: 'pointer',
            color: textToSearch.length ? 'red' : 'black',
          }}
        />
      </Popover.Target>
      <Popover.Dropdown>
        <Paper shadow="sm" style={{ minWidth: '200px' }}>
          <TextInput
            placeholder={`Search by ${name}`}
            value={textToSearch}
            onChange={handleEmailSearchChange}
            onKeyDown={handleEnterKeyPress}
            rightSection={
              textToSearch && (
                <IconCircleX
                  data-testid={`clear-icon-${name}`}
                  style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                  onClick={handleClearSearch}
                />
              )
            }
          />
          <Divider />
        </Paper>
      </Popover.Dropdown>
    </Popover>
  );
};

export default FieldSearchHeader;
