import { Tooltip } from '@mantine/core';
import { IconCode } from '@tabler/icons-react';

import { StyledActionIcon } from '../../EditToolbar.styles';

import { ViewerData } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';

interface CssInspectorToolProps {
  viewerData: Partial<ViewerData>;
}

const handleClickToEmitInlineToolEvent = ({ viewerData }: CssInspectorToolProps) => {
  const { elementSelector, rootNodeHost } = viewerData;
  msg({
    type: 'get-selected-page-data',
    elementSelector: elementSelector || rootNodeHost,
    messageData: { data: viewerData },
    openToolbarPanel: 'style-inspector-panel',
  });
};

const CssInspectorTool = ({ viewerData }: CssInspectorToolProps) => {
  return (
    <Tooltip label="CSS Inspector" color="Gray" withArrow zIndex={1001}>
      <StyledActionIcon
        aria-label="css-inspector-tool"
        variant="transparent"
        onClick={() => handleClickToEmitInlineToolEvent({ viewerData })}
      >
        <IconCode size={20} stroke={1.5} />
      </StyledActionIcon>
    </Tooltip>
  );
};

export default CssInspectorTool;
