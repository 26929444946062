import { Card } from '@mantine/core';

import ConnectWebsite from '~/components/ConnectWebsite/ConnectWebsite';

type IntentHelpProps = {
  propertyId?: string;
};

const IntentHelp = ({ propertyId }: IntentHelpProps) => {
  return (
    <Card shadow="sm" padding="0" radius="md" withBorder>
      {propertyId && <ConnectWebsite propertyId={propertyId} />}
    </Card>
  );
};

export default IntentHelp;
