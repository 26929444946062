import { Popover } from '@mantine/core';
import { IconLetterASmall } from '@tabler/icons-react';
import { useCallback, useEffect, useState } from 'react';

import {
  BgColorBar,
  ButtonHighlightContent,
  IconHighlight,
  StyledActionIcon,
} from '../../EditToolbar.styles';

import { ColorEditor } from '~/components/ColorEditor/ColorEditor';
import { ViewerData } from '~/global.types';
import {
  modifyElementStyleInIframe,
  modifyTextRangeStyleInFirame,
} from '~/helpers/viewerInteractions/viewerUpdateUponEditing';

interface FontColorToolProps {
  viewerData: Partial<ViewerData>;
}

export const FontColorTool = ({ viewerData }: FontColorToolProps) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState<string>();

  useEffect(() => {
    setValue(viewerData?.editorState?.color || '#000');
  }, [viewerData]);

  const handleChangeToUpdateValue = (val: string) => {
    setValue(val);
    if (viewerData?.type === 'element-selected-in-viewer') {
      modifyElementStyleInIframe('color', val, viewerData);
    } else {
      modifyTextRangeStyleInFirame('color', val, viewerData);
    }
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom"
      withinPortal={false}
      zIndex={1001}
    >
      <Popover.Target>
        <StyledActionIcon
          aria-label="font-color-tool"
          variant="transparent"
          onClick={() => setOpened((o) => !o)}
        >
          <IconLetterASmall size={32} stroke={1.64} />
          <div
            style={{
              width: '55%',
              height: '3px',
              borderRadius: '2px',
              backgroundColor: value,
              marginBottom: '4px',
              marginTop: '-4px',
            }}
          />
        </StyledActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <ColorEditor
          value={value || '#000'}
          onChange={handleChangeToUpdateValue}
          label="Set font color"
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export const FontBackgroundColorTool = ({ viewerData }: FontColorToolProps) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState<string>(
    viewerData?.editorState?.backgroundColor ?? '#00000000',
  );

  useEffect(() => {
    const bgColor = viewerData?.editorState?.backgroundColor ?? '#00000000';
    setValue(bgColor);
  }, [viewerData]);

  const handleChangeToUpdateValue = (val: string) => {
    setValue(val);
    modifyTextRangeStyleInFirame('background-color', val, viewerData);
  };

  const addSelectionStyle = useCallback(() => {
    const viewer = (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement)
      .contentDocument;

    const targetElement = viewer?.querySelector(`[data-id='${viewerData.findString}']`);
    if (targetElement) {
      const uniqueClassName = 'selection-style-background-color';
      const uniqueStyleId = 'style-selection-background';

      targetElement.classList.add(uniqueClassName);

      let style = viewer?.getElementById(uniqueStyleId);
      if (!style) {
        style = document.createElement('style');
        style.id = uniqueStyleId;
        style.innerHTML = `
          .${uniqueClassName}::selection {
            background: rgba(255, 255, 255, 0.3);
          }
          .${uniqueClassName} span::selection {
            background: inherit !important;
          }
        `;
        viewer?.head.appendChild(style);
      }
    }
  }, [viewerData.viewer, viewerData.findString]);

  const removeSelectionStyle = () => {
    const viewer = (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement)
      .contentDocument;

    const targetElement = viewer?.querySelector(`[data-id='${viewerData.findString}']`);
    if (targetElement) {
      const uniqueClassName = 'selection-style-background-color';
      targetElement.classList.remove(uniqueClassName);

      const style = viewer?.getElementById('style-selection-background');
      if (style) {
        style.remove();
      }
    }
  };

  useEffect(() => {
    const viewer = (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement)
      ?.contentDocument;

    if (!viewer) return;

    viewer.addEventListener('selectionchange', addSelectionStyle);

    return () => {
      viewer.removeEventListener('selectionchange', addSelectionStyle);
      removeSelectionStyle();
    };
  }, [viewerData.viewer, addSelectionStyle]);

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom"
      withinPortal={false}
      zIndex={1001}
    >
      <Popover.Target>
        <StyledActionIcon
          aria-label="font-background-color-tool"
          variant="transparent"
          onClick={() => {
            if (value.match(/0\)$/)) {
              setValue(value.slice(0, -2) + '1)');
            }
            if (value.match(/00$/)) {
              setValue(value.slice(0, -2) + 'FF');
            }

            setOpened((o) => !o);
          }}
        >
          <ButtonHighlightContent>
            <IconHighlight />
            <BgColorBar color={value} size="2.5px" radius="3px"></BgColorBar>
          </ButtonHighlightContent>
        </StyledActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <ColorEditor
          value={value || '#000'}
          onChange={handleChangeToUpdateValue}
          label="Set font background color"
        />
      </Popover.Dropdown>
    </Popover>
  );
};
