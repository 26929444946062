import { ReactNode } from 'react';

import { Container, Dot, MobileDot, Sound, TabletDot } from './Devices.styles';

interface DesktopProps {
  type: string;
  children: ReactNode;
}

const Portview: React.FC<DesktopProps> = ({ type, children }) => {
  return (
    <Container className={`${type}`}>
      <div className={`viewport-${type}`}>
        <div className="viewport-device">
          {type === 'desktop' && (
            <>
              <Dot />
              <Dot />
              <Dot />
            </>
          )}
          {type === 'mobile' && (
            <>
              <MobileDot />
              <Sound />
            </>
          )}
          {type === 'tablet' && (
            <>
              <TabletDot />
              <Sound />
            </>
          )}
        </div>
        <div className="viewport-frame">{children}</div>
      </div>
    </Container>
  );
};

export default Portview;
