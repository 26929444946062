import { useEffect } from 'react';

import PageLoader from '~/components/PageLoader';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { setupDefaultSubscription } from '~/services/PaymentServices';

const SwitchApp = () => {
  const { featureFlags, userAndWorkspaceInfo, isLoadingSubscription } = useFeatureFlags();
  const { hasActiveV2Subscription } = userAndWorkspaceInfo;
  const { hasV2Access } = featureFlags;

  useEffect(() => {
    if (!isLoadingSubscription) {
      if (hasV2Access.enabled && !hasActiveV2Subscription) {
        setupDefaultSubscription('V2')
          .then(() => (window.location.href = '/dashboard'))
          .catch(() => {
            console.log('Error setting up subscription');
          });
      } else if (!hasV2Access.enabled) window.location.href = '/intent/dashboard';
      else {
        window.location.href = '/dashboard';
      }
    }
  }, [hasActiveV2Subscription, hasV2Access, isLoadingSubscription]);

  return <PageLoader />;
};

export default SwitchApp;
