import { PageJsonSnippetObj } from '~/global.types';

export default {
  contains: [
    {
      contains: [
        {
          textValue: 'This is heading 1',
          '@slot': 'column-1',
          selector: 'elem-vo8BQgUJ',
          style: {
            'font-size': '32px',
            'font-family': 'var(--font-family-headings)',
            'line-height': '1',
            color: 'var(--color-text-lightbg)',
            'font-weight': '700',
            'text-align': 'center',
          },
          tagName: 'h6',
          type: 'text',
          object: 'primitive',
        },
        {
          contains: [
            {
              textValue: 'heading 2',
              '@slot': 'column-2',
              selector: 'elem-i8iCn5WG',
              style: {
                'font-size': '24px',
                'font-family': 'var(--font-family-headings)',
                'line-height': '33.6px',
                color: 'var(--color-text-lightbg)',
                'font-weight': '700',
                'text-align': 'left',
              },
              tagName: 'h1',
              type: 'text',
              object: 'primitive',
            },
            {
              textValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              '@slot': 'column-2',
              selector: 'elem-XTEmMFz2',
              style: {
                'font-family': 'var(--font-family-paragraphs)',
                'line-height': '1.5',
                color: 'var(--color-text-lightbg)',
                'text-align': 'left',
              },
              tagName: 'div',
              type: 'text',
              object: 'primitive',
            },
            {
              '@loading': 'lazy',
              '@slot': 'column-1',
              '@alt': '',
              '@src': 'https://placehold.co/200',
              selector: 'elem-pwFfL1fk',
              style: {
                'align-self': 'end',
                'border-radius': 'var(--card-radius)',
                'max-width': '100%',
              },
              style_max1200: {
                'align-self': 'center',
              },
              tagName: 'img',
              type: 'image',
              object: 'primitive',
            },
          ],
          '@slot': 'column-1',
          'style_for_.column-2': {
            'justify-content': 'center',
          },
          'style_for_.column': {
            'justify-content': 'center',
            display: 'flex',
          },
          selector: 'apsiuhdfpiausbf',
          style: {
            background: 'var(--color-neutrallight)',
          },
          tagName: 'div',
          'style_for_.container': {
            'background-color': 'var(--color-neutrallight)',
            padding: '32px 0px 32px 0px',
          },
          layoutName: 'column-layout',
          object: 'meaning-unit',
          'style_max1200_for_slot[name=column-2]::slotted(*)': {
            'text-align': 'center !important',
          },
          'style_min1200_for_.column-2': {
            'flex-basis': '600px',
          },
        },
        {
          contains: [
            {
              textValue: 'heading 2',
              '@slot': 'column-2',
              selector: 'elem-vh5pKMKR',
              style: {
                'font-size': '24px',
                'font-family': 'var(--font-family-headings)',
                'line-height': '33.6px',
                color: 'var(--color-text-lightbg)',
                'font-weight': '700',
                'text-align': 'left',
              },
              tagName: 'h1',
              type: 'text',
              object: 'primitive',
            },
            {
              textValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              '@slot': 'column-2',
              selector: 'elem-gcvUjWNk',
              style: {
                'font-family': 'var(--font-family-paragraphs)',
                'line-height': '1.5',
                color: 'var(--color-text-lightbg)',
                'text-align': 'left',
              },
              tagName: 'div',
              type: 'text',
              object: 'primitive',
            },
            {
              '@loading': 'lazy',
              '@slot': 'column-1',
              '@alt': '',
              '@src': 'https://placehold.co/200',
              selector: 'elem-6t5ytJVr',
              style: {
                'align-self': 'end',
                'border-radius': 'var(--card-radius)',
                'max-width': '100%',
              },
              style_max1200: {
                'align-self': 'center',
              },
              tagName: 'img',
              type: 'image',
              object: 'primitive',
            },
          ],
          '@slot': 'column-1',
          'style_for_.column-2': {
            'justify-content': 'center',
          },
          'style_for_.column': {
            'justify-content': 'center',
            display: 'flex',
          },
          selector: 'aknfbuas',
          style: {
            background: 'var(--color-neutrallight)',
          },
          tagName: 'div',
          'style_for_.container': {
            padding: '32px 0px 32px 0px',
          },
          layoutName: 'column-layout',
          object: 'meaning-unit',
          'style_max1200_for_slot[name=column-2]::slotted(*)': {
            'text-align': 'center !important',
          },
          'style_min1200_for_.column-2': {
            'flex-basis': '600px',
          },
        },
        {
          contains: [
            {
              textValue: 'heading 2',
              '@slot': 'column-2',
              selector: 'elem-qyadaBHN',
              style: {
                'font-size': '24px',
                'font-family': 'var(--font-family-headings)',
                'line-height': '33.6px',
                color: 'var(--color-text-lightbg)',
                'font-weight': '700',
                'text-align': 'left',
              },
              tagName: 'h1',
              type: 'text',
              object: 'primitive',
            },
            {
              textValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              '@slot': 'column-2',
              selector: 'elem-rENjBdIH',
              style: {
                'font-family': 'var(--font-family-paragraphs)',
                'line-height': '1.5',
                color: 'var(--color-text-lightbg)',
                'text-align': 'left',
              },
              tagName: 'div',
              type: 'text',
              object: 'primitive',
            },
            {
              '@loading': 'lazy',
              '@slot': 'column-1',
              '@alt': '',
              '@src': 'https://placehold.co/200',
              selector: 'elem-NDAU01tJ',
              style: {
                'align-self': 'end',
                'border-radius': 'var(--card-radius)',
                'max-width': '100%',
              },
              style_max1200: {
                'align-self': 'center',
              },
              tagName: 'img',
              type: 'image',
              object: 'primitive',
            },
          ],
          '@slot': 'column-1',
          'style_for_.column-2': {
            'justify-content': 'center',
          },
          'style_for_.column': {
            'justify-content': 'center',
            display: 'flex',
          },
          selector: 'apjdnpaiubds',
          style: {
            background: 'var(--color-neutrallight)',
          },
          tagName: 'div',
          'style_for_.container': {
            padding: '32px 0px 32px 0px',
          },
          layoutName: 'column-layout',
          object: 'meaning-unit',
          'style_max1200_for_slot[name=column-2]::slotted(*)': {
            'text-align': 'center !important',
          },
          'style_min1200_for_.column-2': {
            'flex-basis': '600px',
          },
        },
      ],
      '@slot': 'column-1',
      'style_for_.column-1': {
        padding: '0px 0px 0px 0px',
      },
      selector: 'oiubpuiwefv',
      style: {
        background: 'var(--color-neutrallight)',
      },
      tagName: 'div',
      'style_for_.container': {
        padding: '64px 0px 0px 0px',
      },
      layoutName: 'column-layout',
      object: 'meaning-unit',
      'style_max1200_for_slot[name=column-2]::slotted(*)': {
        'text-align': 'center !important',
      },
      'style_min1200_for_.column-2': {
        'flex-basis': '600px',
      },
    },
  ],
  'style_for_.column-1': {
    padding: '0px 0px 0px 0px',
  },
  selector: 'layout-ppbbufTo',
  style: {
    padding: '32px 0px 0px 0px',
    background: 'var(--color-neutrallight)',
  },
  tagName: 'div',
  'style_for_.container': {
    padding: '0px 0px 0px 0px',
  },
  layoutName: 'column-layout',
  object: 'section',
} as PageJsonSnippetObj;
