import { Space } from '@mantine/core';

import ConnectWebsite from '../../components/ConnectWebsite/ConnectWebsite';
import IntentLeads from '../../components/IntentLeads/IntentLeads';
import { TrackingStatusInfo } from '../../global.types';

import PageLoader from '~/components/PageLoader';

type IntentDashboardProps = {
  workspaceId?: string;
  propertyId?: string;
  trackingStatusInfo?: TrackingStatusInfo;
};

const IntentDashboard = ({ workspaceId, propertyId, trackingStatusInfo }: IntentDashboardProps) => {
  if (
    !workspaceId ||
    !propertyId ||
    !trackingStatusInfo ||
    trackingStatusInfo.status === 'loading'
  ) {
    return <PageLoader />;
  }
  return (
    <>
      {trackingStatusInfo.status !== 'tracking' && (
        <>
          <ConnectWebsite propertyId={propertyId} status={trackingStatusInfo.status} />
          <Space h="md" />
        </>
      )}
      {trackingStatusInfo.status !== 'never tracked' && (
        <>
          <IntentLeads workspaceId={workspaceId} propertyId={propertyId} />
          <Space h="md" />
        </>
      )}
    </>
  );
};

export default IntentDashboard;
