import { LoadingOverlay } from '@mantine/core';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  ReactZoomPanPinchContentRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';

import {
  Desktop,
  DevicesContainer,
  Mobile,
  ResizableViewer,
  Tablet,
  ViewerContainer,
} from './Viewer.styles';

import { IS_FIREFOX, IS_SAFARI } from '~/constants';
import { ParsedLandingpageObject } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import useWheelToPanMessage from '~/hooks/useWheelToPanMessage/useWheelToPanMessage';
import { WheelForStudioPanZoomEvent } from '~/messages.types';

const DeviceWrapper = ({
  children,
  enablePerformanceMode,
}: PropsWithChildren & { enablePerformanceMode?: boolean }) => (
  <DevicesContainer data-wheel-scrollable>
    <Desktop data-wheel-scrollable>
      <ViewerContainer>{children}</ViewerContainer>
    </Desktop>
    {!enablePerformanceMode && (
      <>
        <Tablet data-wheel-scrollable>
          <ViewerContainer>{children}</ViewerContainer>
        </Tablet>
        <Mobile data-wheel-scrollable>
          <ViewerContainer>{children}</ViewerContainer>
        </Mobile>
      </>
    )}
  </DevicesContainer>
);

interface ViewerProps {
  srcDoc: string;
  pageDetails: Omit<ParsedLandingpageObject, 'content'>;
  holdMetaKey: boolean;
  enablePerformanceMode?: boolean;
}

const Viewer = ({ srcDoc, enablePerformanceMode, holdMetaKey = true }: ViewerProps) => {
  const [isMovingViewer, setIsMovingViewer] = useState(holdMetaKey);
  const [blockingEditor, setBlockingEditor] = useState(false);
  const transformWrapperRef = useRef<ReactZoomPanPinchContentRef>(null);

  useWheelToPanMessage(setIsMovingViewer, (e: WheelForStudioPanZoomEvent) => {
    const { deltaX = 0, deltaY = 0, ctrlKey, clientX = 0, clientY = 0 } = e;
    const viewer = transformWrapperRef.current;

    if (viewer?.instance) {
      const { positionX, positionY, scale } = viewer.instance.transformState;

      if (ctrlKey) {
        const divide = IS_FIREFOX || IS_SAFARI ? 20 : 40;

        // Trackpad register pinch zoom with ctrlkey.
        // This calculate the new position to keep the zoom centered at the cursor
        const newScale = Math.min(Math.max(scale - deltaY / divide, 0.1), 2);
        const newX = clientX - ((clientX - positionX) / scale) * newScale;
        const newY = clientY - ((clientY - positionY) / scale) * newScale;
        viewer.setTransform(newX, newY, newScale, 50, 'linear');
      } else {
        viewer.setTransform(positionX - deltaX, positionY - deltaY, scale, 0, 'linear');
      }
    }
  });

  useViewerMessage(({ data }) => {
    if (data.type === 'set-viewer-zoom') {
      const viewer = transformWrapperRef.current;

      if (viewer?.instance) {
        const { positionX, positionY } = viewer.instance.transformState;
        viewer.instance.setTransformState(data.scale, positionX, positionY);
        msg({ type: 'update-bound' });
      }
    }

    if (data.type === 'block-editor') {
      setBlockingEditor(true);
    }

    if (data.type === 'unblock-editor') {
      setBlockingEditor(false);
    }
  }, []);

  const { scale = 0 } = transformWrapperRef.current?.instance?.transformState || {};

  useEffect(() => {
    msg({ type: 'viewer-zoom-update', scale });
  }, [scale]);

  useEffect(() => {
    setIsMovingViewer(holdMetaKey);
  }, [holdMetaKey]);

  return (
    <TransformWrapper
      // The docs states that ref prop is allowed
      // https://bettertyped.github.io/react-zoom-pan-pinch/?path=/story/docs-props--page
      ref={transformWrapperRef}
      initialScale={0.5}
      limitToBounds={false}
      disabled={!isMovingViewer}
      minScale={0.1}
      maxScale={1}
      centerOnInit
      smooth={true}
      wheel={{
        smoothStep: 0.001,
        disabled: !holdMetaKey,
      }}
      onWheelStart={() => {
        msg({ type: 'hide-overlay' });
      }}
      onWheelStop={() => {
        msg({ type: 'show-overlay' });
      }}
      onPanningStart={() => {
        msg({ type: 'hide-overlay' });
      }}
      onPanningStop={() => {
        // This pan has a slow down effect which causes bound box to appear in the wrong nearby location.
        // This can only solved with an arbitrary timeout.
        setTimeout(() => msg({ type: 'show-overlay' }), 100);
      }}
    >
      <TransformComponent>
        <DeviceWrapper enablePerformanceMode={enablePerformanceMode}>
          <ResizableViewer
            srcDoc={srcDoc}
            holdMetaKey={holdMetaKey}
            blockingEditor={blockingEditor}
          />
        </DeviceWrapper>
      </TransformComponent>
      <LoadingOverlay
        visible={blockingEditor}
        zIndex={99999}
        overlayProps={{
          fixed: true,
        }}
        transitionProps={{ transition: 'fade', duration: 200 }}
        role="spinbutton"
      />
    </TransformWrapper>
  );
};

export default Viewer;
