import { Box, Code, Group, Stack, Text, Timeline } from '@mantine/core';
import { IconMessageDots } from '@tabler/icons-react';

import rowExpansionsClasses from './RowExpansion.module.css';

import { AnalysisRecordPartial, LeadMetadata } from '~/global.types';

const LeadRowDetails = ({
  leadId,
  pagesNb,
  sessionsNb,
  metadata,
  status,
}: AnalysisRecordPartial) => {
  const metadataObj = (metadata ? JSON.parse(metadata) : []) as LeadMetadata[];
  return (
    <Stack className={rowExpansionsClasses.details} p="xs" gap={6}>
      <Group gap={6}>
        <Box>
          <div className={rowExpansionsClasses.label}>ID</div>
          <div>{leadId}</div>
        </Box>
        <Box>
          <div className={rowExpansionsClasses.label}>Status</div>
          <div>{status}</div>
        </Box>
        <Box>
          <div className={rowExpansionsClasses.label}>Number of sessions:</div>
          <div>{sessionsNb}</div>
        </Box>
        <Box>
          <div className={rowExpansionsClasses.label}>Number of pages:</div>
          <div>{pagesNb}</div>
        </Box>
      </Group>
      <Group gap={6}>
        <Timeline active={1} bulletSize={24} lineWidth={2}>
          {metadataObj.map((item, index) => (
            <Timeline.Item key={index} bullet={<IconMessageDots size={12} />} lineVariant="dashed" title={item.level}>
            <Text c="dimmed" size="sm">{item.message}</Text>
            {item.data && <Code block>{JSON.stringify(item.data, null, 2)}</Code>}
          </Timeline.Item>
          ))}
        </Timeline>
      </Group>
    </Stack>
  );
};

export default LeadRowDetails;
