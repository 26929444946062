import { PageJsonSnippetObj } from '~/global.types';

export default {
  style_max1200_for_section: {
    width: '100% !important',
    margin: '0',
  },
  contains: [
    {
      '@slot': 'column-1',
      '@src': 'https://placehold.co/150X64',
      selector: 'elem-WUy1nsI4',
      style: {
        'max-height': '72px',
        margin: 'auto',
      },
      type: 'image',
      tagName: 'img',
      object: 'primitive',
    },
    {
      '@slot': 'column-2',
      '@alt': '',
      '@src': 'https://placehold.co/150X64',
      selector: 'elem-VgKKBeZs',
      style: {
        'max-height': '72px',
        margin: 'auto',
      },
      type: 'image',
      tagName: 'img',
      object: 'primitive',
    },
    {
      '@slot': 'column-3',
      '@alt': '',
      '@src': 'https://placehold.co/150X64',
      selector: 'elem-jS8WbBZa',
      style: {
        'max-height': '72px',
        margin: 'auto',
      },
      type: 'image',
      tagName: 'img',
      object: 'primitive',
    },
    {
      '@slot': 'column-4',
      '@alt': '',
      '@src': 'https://placehold.co/150X64',
      selector: 'elem-8lXoKvL7',
      style: {
        'max-height': '72px',
        margin: 'auto',
      },
      type: 'image',
      tagName: 'img',
      object: 'primitive',
    },
  ],
  'style_max1200_for_.column': {
    padding: '0',
    'justify-content': 'center',
    display: 'flex',
  },
  style_min1200_for_section: {
    margin: 'auto',
  },
  'style_max1200_for_.container': {
    padding: '0px',
    margin: '0 20px 20px 20px',
    'justify-content': 'center',
    'flex-direction': 'row',
    'flex-wrap': 'wrap',
    gap: '20px',
  },
  'style_for_.column': {
    margin: '0',
    'justify-content': 'center',
    display: 'flex',
  },
  selector: 'layout-OLPQxOCq',
  style: {
    padding: '60px 0px 40px 0px',
    justify: 'space-between',
    background: 'var(--color-white-100)',
  },
  tagName: 'div',
  'style_min1200_for_.container': {
    padding: '0 100px',
    'justify-content': 'center',
    gap: '80px',
  },
  layoutName: 'column-layout',
  object: 'section',
} as PageJsonSnippetObj;
