import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthCard, AuthHeadline, AuthSubHeadline, BrandLogo } from './Auth.styles';
import UserFrontStylesOverride from './UserfrontOverrides.styles';

interface AuthBaseProps extends PropsWithChildren {
  headline: string;
  subHeadline?: string;
  socialSignInPrefix?: string;
  submitButtonText?: string;
}

const AuthBase = ({
  children,
  headline,
  subHeadline,
  socialSignInPrefix,
  submitButtonText,
}: AuthBaseProps) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // This is to cater social signin when transitioning between vendor back to login page
    // It will show our own custom card and "hides" the Userfront login component that triggers redirecting.
    if (location.search !== '') setIsRedirecting(true);
  }, [location]);

  return (
    <>
      <BrandLogo />
      <AuthCard>
        <div {...(isRedirecting && { hidden: true })}>
          <AuthHeadline>{headline}</AuthHeadline>
          <AuthSubHeadline>{subHeadline}</AuthSubHeadline>
          <UserFrontStylesOverride
            socialSignInPrefix={socialSignInPrefix}
            submitButtonText={submitButtonText}
          />
          {children}
        </div>

        <div {...(!isRedirecting && { hidden: true })}>
          <AuthHeadline>Almost there</AuthHeadline>
          <AuthSubHeadline>Please be patient. Logging you in...</AuthSubHeadline>
        </div>
      </AuthCard>
    </>
  );
};

export default AuthBase;
