import { Button, Grid, GridCol, ScrollArea, SimpleGrid, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { IconPlus, IconShare } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import FrameAsPageThumbnail from './FrameAsPageThumbnail';

import CardSkeleton from '~/components/CardSkeleton';
import DefaultLayout from '~/components/DefaultLayout';
import InviteUserModal from '~/components/InviteUserModal';
import PageCard from '~/components/PageCard';
import { ParsedLandingpageObject } from '~/global.types';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { listPages } from '~/services/PageServices';
import { refreshUserToken } from '~/services/UserServices';
import { createWorkspace } from '~/services/WorkspaceServices';

const StyledContainer = styled.div`
  background: #ffff;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  height: 85vh;
  border-radius: 8px;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.05);
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [pageList, setPageList] = useState<ParsedLandingpageObject[]>([]);
  const { userAndWorkspaceInfo, updateUserAndWorkspace, isLoadingWorkspace, featureFlags } =
    useFeatureFlags();
  const { pageThumbnailService, manageWorkspace } = featureFlags;
  const { v2ActiveWorkspace, v2Workspaces } = userAndWorkspaceInfo;

  useEffect(() => {
    if (!isLoadingWorkspace) {
      if (v2ActiveWorkspace) {
        setLoading(true);
        listPages({ workspaceId: v2ActiveWorkspace.workspaceId })
          .then(({ data }) => {
            setPageList(data.pages);
          })
          .catch((error) => {
            console.log('Unable to fetch pages:', error);
            setPageList([]);
          })
          .finally(() => setLoading(false));
      } else {
        createWorkspace({ product: 'V2' })
          .then(async (workspace) => {
            const newV2Workspace = {
              name: 'my-v2-workspace',
              properties: {},
              type: 'V2',
              ...workspace,
            };
            await refreshUserToken();
            updateUserAndWorkspace({
              hasV2Workspace: true,
              v2Workspaces: [...v2Workspaces, newV2Workspace],
              v2ActiveWorkspace: newV2Workspace,
            });
          })
          .catch((error) => console.error('Error creating user workspace!', error));
      }
    }
  }, [isLoadingWorkspace, v2ActiveWorkspace]);

  const removePageFromList = (nanoId: string) => {
    setPageList(pageList.filter((page) => page.nanoId !== nanoId));
  };

  const addPageInList = (nanoId: string, name: string, definition: string) => {
    setPageList([
      ...pageList,
      {
        nanoId,
        name,
        definition,
        status: 'ACTIVE',
        createdDate: Date.now().toString(),
        updatedDate: Date.now().toString(),
      },
    ]);
  };

  const upatePageNameInList = (nanoId: string, name: string) => {
    const newPageList = pageList.map((page) => {
      if (page.nanoId === nanoId) {
        page.name = name;
      }
      return page;
    });
    setPageList(newPageList);
  };

  return (
    <DefaultLayout>
      <StyledContainer>
        <Grid p={24} pt={16}>
          <GridCol span={6}>
            <span style={{ fontSize: 24, fontWeight: 700 }}>Landing Pages</span>
          </GridCol>
          <GridCol span={6}>
            {manageWorkspace?.enabled && v2ActiveWorkspace?.workspaceId && (
              <Tooltip label="Share workspace" position="top">
                <Button variant="default" style={{ float: 'right', marginLeft: 10 }} onClick={open}>
                  <IconShare size={24} />
                </Button>
              </Tooltip>
            )}
            <Button
              leftSection={<IconPlus size={24} />}
              onClick={() => navigate('/pages/new')}
              style={{ float: 'right' }}
            >
              Create new page
            </Button>
          </GridCol>
        </Grid>
        <ScrollArea h="90%" p={24} pt={0}>
          <SimpleGrid
            cols={{ base: 1, sm: 1, lg: 4 }}
            spacing={{ base: 10, sm: 'xl' }}
            verticalSpacing={{ base: 'md', sm: 'xl' }}
            style={{ marginTop: 40 }}
          >
            <ModalsProvider>
              {pageList.map((page) => (
                <PageCard
                  key={page.nanoId}
                  page={{ ...page, status: page.status }}
                  onDeleteSuccess={removePageFromList}
                  onCopySuccess={addPageInList}
                  onNameChange={upatePageNameInList}
                  {...(pageThumbnailService?.enabled
                    ? {
                        cardImageOverride: <FrameAsPageThumbnail nanoId={page.nanoId} />,
                      }
                    : {
                        image: '/preview-thumb.jpg',
                      })}
                />
              ))}
              {loading && (
                <>
                  <CardSkeleton visible={loading} />
                  <CardSkeleton visible={loading} />
                  <CardSkeleton visible={loading} />
                  <CardSkeleton visible={loading} />
                </>
              )}
            </ModalsProvider>
          </SimpleGrid>
        </ScrollArea>
      </StyledContainer>
      <InviteUserModal
        workspaceId={v2ActiveWorkspace?.workspaceId ?? ''}
        opened={opened}
        close={close}
      />
    </DefaultLayout>
  );
};

export default Dashboard;
