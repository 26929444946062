import { ElementHoverAreaGuideDecor } from '../../PannableOverlays.styles';
import { DropzoneProps } from '../DropzoneOverlay';

export const AreaOverlay = ({ hoverBound, style }: DropzoneProps) => (
  <ElementHoverAreaGuideDecor
    style={{
      left: hoverBound.x || -99,
      top: hoverBound.y || -99,
      width: hoverBound.width || 0,
      height: hoverBound.height || 0,
      ...style,
    }}
  />
);

export const SectionLines = ({ hoverBound }: DropzoneProps) => (
  <AreaOverlay
    hoverBound={hoverBound}
    style={{ borderLeft: 0, borderRight: 0, background: 'transparent' }}
  />
);
