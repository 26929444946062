import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef } from 'react';

type NameModalProps = {
  title: string;
  name: string;
  handleSubmit: ({ name }: { name: string }) => void;
  close: () => void;
  updating?: boolean;
  defaultDisabled?: boolean;
};

const NameModal = ({
  title,
  name,
  handleSubmit,
  close,
  updating = false,
  defaultDisabled = true,
}: NameModalProps) => {
  const form = useForm({
    validateInputOnChange: true,
    initialValues: { name },
  });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Add delay to allow the modal to open and render the input
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      opened
      onClose={close}
      title={title}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <TextInput
          data-testid="page-name"
          label="Enter a new page name:"
          ref={inputRef}
          {...form.getInputProps('name')}
          required
        />
        <Group mt="md" justify="flex-end">
          <Button aria-label="Close Modal" onClick={close} variant="default" type="button">
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={updating}
            disabled={defaultDisabled && name === inputRef.current?.value}
          >
            Ok
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
export default NameModal;
