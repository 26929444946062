import { ButtonContainer, ButtonPreview, HeaderPreview, SelectedButton } from './TopNavbar.styles';

interface TopNavbarProps {
  tab: string;
  setTab: (tab: 'desktop' | 'mobile' | 'tablet') => void;
}
const TopNavbar: React.FC<TopNavbarProps> = ({ tab, setTab }) => {
  return (
    <HeaderPreview>
      <ButtonContainer>
        <ButtonPreview
          onClick={() => setTab('desktop')}
          style={tab === 'desktop' ? { fontWeight: 600 } : {}}
        >
          {' '}
          Desktop
        </ButtonPreview>
        {tab === 'desktop' && <SelectedButton />}
      </ButtonContainer>
      <ButtonContainer>
        <ButtonPreview
          onClick={() => setTab('tablet')}
          style={tab === 'tablet' ? { fontWeight: 600 } : {}}
        >
          {' '}
          Tablet
        </ButtonPreview>
        {tab === 'tablet' && <SelectedButton />}
      </ButtonContainer>
      <ButtonContainer>
        <ButtonPreview
          onClick={() => setTab('mobile')}
          style={tab === 'mobile' ? { fontWeight: 600 } : {}}
        >
          {' '}
          Mobile
        </ButtonPreview>
        {tab === 'mobile' && <SelectedButton />}
      </ButtonContainer>
    </HeaderPreview>
  );
};

export default TopNavbar;
