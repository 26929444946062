import { Container, Space } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';

import IntentMenu from '../../components/IntentMenu/IntentMenu';
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer.styles';
import { getTrackingStatus } from '../../services/IntentAPI';
import PageLoader from '../PageLoader';
import ProductHuntAlert from '../ProductHuntAlert/ProductHuntAlert';

import { TrackingStatus, TrackingStatusInfo, UserAndWorkspaceInfo } from '~/global.types';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import Pricing from '~/screens/Pricing';
import { updateProperty } from '~/services/PropertyServices';

const StyledContainer = styled(Container)`
  max-width: 1148px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

const TRACKING_STATUS_LOADING: TrackingStatusInfo = {
  status: 'loading' as TrackingStatus | 'loading',
  lastEventCreatedAt: null,
};

const IntentLayout = ({ children }: PropsWithChildren) => {
  const { isLoadingWorkspace, userAndWorkspaceInfo } = useFeatureFlags();
  const { intentActiveWorkspace, intentProperties, hasActiveIntentSubscription, user } =
    userAndWorkspaceInfo as UserAndWorkspaceInfo;
  const activeProperty = intentProperties.length ? intentProperties[0] : null;
  const [openPricing, setOpenPricing] = useState<boolean>(!hasActiveIntentSubscription);

  const [trackingStatusInfo, setTrackingStatusInfo] =
    useState<TrackingStatusInfo>(TRACKING_STATUS_LOADING);

  useEffect(() => {
    if (!isLoadingWorkspace && !activeProperty) {
      window.location.href = '/onboarding-website';
    }
  }, [isLoadingWorkspace, activeProperty]);

  const onWorkspaceNameChange = async (name: string) => {
    if (intentActiveWorkspace && activeProperty) {
      await updateProperty({
        workspaceId: intentActiveWorkspace.workspaceId,
        propertyId: activeProperty.id,
        name,
      });

      notifications.show({
        color: 'blue',
        title: 'Update successful!',
        message: '',
        autoClose: 3000,
      });
    }
  };

  const checkTrackingStatus = (workspaceId?: string, propertyId?: string) => {
    setTrackingStatusInfo(TRACKING_STATUS_LOADING);
    if (!workspaceId || !propertyId) return Promise.resolve();
    return getTrackingStatus({ workspaceId, propertyId })
      .then((data) => {
        setTrackingStatusInfo({
          status: data.trackingStatus,
          lastEventCreatedAt: data.lastEventCreatedAt,
        });
      })
      .catch((error) => {
        setTrackingStatusInfo(TRACKING_STATUS_LOADING);
        console.error(error);
        notifications.show({
          color: 'red',
          title: 'Error retrieving tracking status',
          message: 'Our team has been notified. Contact us if the problem persists.',
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (intentActiveWorkspace && activeProperty) {
      checkTrackingStatus(intentActiveWorkspace.workspaceId, activeProperty.id);
    }
  }, [activeProperty, intentActiveWorkspace]);

  if (!activeProperty && !intentActiveWorkspace) return <PageLoader />;

  return (
    <ScreenContainer
      style={{
        backgroundColor: '#E9ECEF',
        overflow: 'auto',
      }}
    >
      {!user?.data?.stripeCustomerId && (
        <ProductHuntAlert handleClickClaimPlan={() => setOpenPricing(true)} />
      )}

      {openPricing && <Pricing withCloseButton onClose={() => setOpenPricing(false)} />}
      <StyledContainer>
        <IntentMenu
          workspaceName={intentProperties[0]?.name ?? ''}
          loading={trackingStatusInfo.status === TRACKING_STATUS_LOADING.status}
          onWorkspaceNameChange={onWorkspaceNameChange}
          trackingStatusInfo={trackingStatusInfo}
          onTrackingStatusClick={() =>
            checkTrackingStatus(intentActiveWorkspace?.workspaceId, activeProperty?.id)
          }
          openPricing={() => setOpenPricing(true)}
        />
        <Space h="md" />
        {React.Children.map(React.Children.toArray(children), (child) => {
          return React.cloneElement(child as React.ReactElement, {
            workspaceId: intentActiveWorkspace?.workspaceId,
            propertyId: activeProperty?.id,
            trackingStatusInfo,
          });
        })}
      </StyledContainer>
    </ScreenContainer>
  );
};

export default IntentLayout;
