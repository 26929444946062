import { notifications } from '@mantine/notifications';
import { createContext, PropsWithChildren, useContext, useState } from 'react';

import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { fetchMediaCollection } from '~/services/AssetServices';

type MediaCollectionContextType =
  | null
  | [
      [boolean, React.Dispatch<React.SetStateAction<boolean>>],
      [string, React.Dispatch<React.SetStateAction<string>>],
      [MediaCollectionItem[], React.Dispatch<React.SetStateAction<MediaCollectionItem[]>>],
    ];

const MediaCollectionContext = createContext<MediaCollectionContextType>(null);

// eslint-disable-next-line react-refresh/only-export-components
export const useMediaCollection = () => {
  const context = useContext(MediaCollectionContext);
  if (!context) {
    throw new Error('useMediaCollection must be used within a MediaCollectionProvider');
  }
  return context;
};

export interface MediaCollectionItem {
  tagName?: string;
  '@src'?: string;
  '@alt'?: string;
}

export const MediaCollectionProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(true);
  const [workspace, setWorkspace] = useState('');
  const [imageList, setImageList] = useState<MediaCollectionItem[]>([]);

  useViewerMessage(async ({ data }) => {
    if (data.type === 'first-fetch-page-data-completed') {
      const { workspaceId = '' } = data.pageDetails;
      setWorkspace(workspaceId);

      if (workspaceId) {
        try {
          const collection = await fetchMediaCollection({
            workspaceId,
            perPage: 200,
          });

          const images = collection.images.map((image) => ({
            tagName: 'img',
            '@src': image.thumbnail,
            '@alt': image.altText,
          }));

          setImageList(images);
          setLoading(false);
        } catch (error) {
          notifications.show({
            color: 'red',
            message: 'Failed to retrieve media collection.',
            autoClose: 3000,
          });
        }
      }
    }
  }, []);

  return (
    <MediaCollectionContext.Provider
      value={[
        [loading, setLoading],
        [workspace, setWorkspace],
        [imageList, setImageList],
      ]}
    >
      {children}
    </MediaCollectionContext.Provider>
  );
};
