import { ViewerData } from '~/global.types';
import { modifyStyleInIframe } from '~/helpers/viewerInteractions/viewerUpdateUponEditing';

export type BackgroundTypes = 'solid' | 'gradient' | 'image';

interface AdditionalBackgroundObj {
  backgroundSize: string;
  backgroundRepeat: string;
  backgroundPosition: string;
}

export interface BackgroundObj {
  type: 'backgroundImage' | 'backgroundColor';
  value: string | undefined;
  additional?: Partial<AdditionalBackgroundObj>;
}
interface BackgroundReducer {
  solid: BackgroundObj;
  gradient: BackgroundObj;
  image: BackgroundObj;
  activeTab: BackgroundTypes;
  viewerData: Partial<ViewerData>;
}

type BackgroundAction =
  | {
      type: BackgroundTypes;
      data: BackgroundObj;
      isInit?: boolean;
    }
  | {
      type: 'active';
      tab: string | null;
      isInit?: boolean;
      overridingValue?: string;
    }
  | { type: 'reset' }
  | { type: 'set-viewer-data'; viewerData: Partial<ViewerData> }
  | {
      type: 'set-image-additional';
      additional: Partial<AdditionalBackgroundObj>;
    };

function extractFirstColorFromGradientString(gradient: string): string | null {
  const processedGradient = gradient
    .replace('linear-gradient(rgb', 'linear-gradient(180deg, rgb')
    .replace('linear-gradient(var', 'linear-gradient(180deg, var');
  const hexRegex = /\b(?:linear|radial|conic)-gradient\([^)]+,\s*(#[0-9a-fA-F]{3,8})/i;
  const hexMatch = processedGradient.match(hexRegex);
  if (hexMatch) {
    return hexMatch[1].trim();
  }
  const rgbRegex = /\b(?:linear|radial|conic)-gradient\([^)]+,\s*(rgba?\([^)]+\))/i;
  const rgbMatch = processedGradient.match(rgbRegex);
  return rgbMatch ? rgbMatch[1].trim() : null;
}

export const defaultSolid = 'rgba(0,0,0,1)';

export const backgroundReducerInit: BackgroundReducer = {
  solid: { type: 'backgroundColor', value: undefined },
  gradient: {
    type: 'backgroundImage',
    value: undefined,
  },
  image: { type: 'backgroundImage', value: undefined, additional: { backgroundSize: 'cover' } },
  activeTab: 'solid',
  viewerData: {},
};

export const backgroundReducer = (state: BackgroundReducer, action: BackgroundAction) => {
  const { solid, gradient, image, viewerData } = state;

  switch (action.type) {
    case 'solid': {
      if (!action.isInit)
        modifyStyleInIframe('backgroundColor', action.data.value || '', viewerData);

      return {
        ...state,
        solid: action.data,
      };
    }

    case 'gradient': {
      if (!action.isInit)
        modifyStyleInIframe('backgroundImage', action.data.value || '', viewerData);

      return {
        ...state,
        gradient: action.data,
      };
    }

    case 'image': {
      if (!action.isInit) {
        modifyStyleInIframe('backgroundImage', action.data.value || '', viewerData);
        Object.entries(action.data.additional ?? {}).forEach(([key, val]) => {
          modifyStyleInIframe(key, val, state.viewerData);
        });
      }

      return {
        ...state,
        image: action.data,
      };
    }

    case 'active': {
      let nextTabVal = '';

      if (action.tab === 'solid' && !action.isInit) {
        nextTabVal =
          (solid.value || extractFirstColorFromGradientString(gradient.value || '')) ??
          'rgba(0,0,0,1)';
        modifyStyleInIframe('backgroundImage', 'none', viewerData);
        modifyStyleInIframe('backgroundColor', action.overridingValue || nextTabVal, viewerData);
      }

      if (action.tab === 'gradient' && !action.isInit) {
        nextTabVal =
          gradient.value ||
          `linear-gradient(180deg, ${solid.value || defaultSolid} 0%, rgba(255,255,255,1) 100%)`;
        modifyStyleInIframe('backgroundColor', 'none', viewerData);
        modifyStyleInIframe('backgroundImage', action.overridingValue || nextTabVal, viewerData);
      }

      if (action.tab === 'image' && !action.isInit) {
        nextTabVal = image.value || 'none';
        modifyStyleInIframe('backgroundColor', 'none', viewerData);
        modifyStyleInIframe('backgroundImage', action.overridingValue || nextTabVal, viewerData);
      }

      return {
        ...state,
        activeTab: (action.tab as BackgroundTypes) ?? 'solid',
      };
    }

    case 'reset': {
      return {
        ...backgroundReducerInit,
        viewerData: state.viewerData,
      };
    }

    case 'set-viewer-data': {
      return {
        ...state,
        viewerData: action.viewerData,
      };
    }

    case 'set-image-additional': {
      Object.entries(action.additional ?? {}).forEach(([key, val]) => {
        modifyStyleInIframe(key, val, viewerData);
      });

      return {
        ...state,
        image: {
          ...state.image,
          additional: {
            ...state.image.additional,
            ...action.additional,
          },
        },
      };
    }
  }
};
