import AccountProfile from './AccountProfile';

import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { updateProfile } from '~/services/UserServices';

export const AccountProfileContainer = () => {
  const { userAndWorkspaceInfo, updateUserAndWorkspace } = useFeatureFlags();
  const user = userAndWorkspaceInfo.user;
  return (
    <AccountProfile
      name={user?.name ?? ''}
      email={user?.email ?? ''}
      onChange={async (payload) => {
        const updatedUser = await updateProfile(payload);
        //to update feature flags with latest value
        updateUserAndWorkspace({
          user: updatedUser,
        });
      }}
    />
  );
};
