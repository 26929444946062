import { Box, ColorSwatch, Group, Overlay, Title, Tooltip } from '@mantine/core';
import { usePrevious } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import './SolidAndGradientColorEditor.css';

import { formatBrandColorName } from '~/helpers/cssVariableProcessing/cssVariableProcessing';
import { BrandToken } from '~/services/BrandServices';

interface SolidAndGradientColorEditorProps {
  value: string;
  width?: number;
  brandColors?: BrandToken[];
  onChange: (colorValue: string) => void;
}

export const SolidAndGradientColorEditor = ({
  value,
  width = 378,
  brandColors,
  onChange,
}: SolidAndGradientColorEditorProps) => {
  const [isPickerActive, setIsPickerActive] = useState(false);
  const [isUserInteract, setIsUserInteract] = useState(false);

  const { setSolid, valueToHex, setA, currentLeft, degrees, gradientType } = useColorPicker(
    value,
    onChange,
  );

  let valueForEditor = value;

  // Fix the issue where react-best-gradient-color-picker takes a getComputedStyled generated CSS
  // gradient value that does not have a degree value. This means it must be treated as 180deg,
  // and some string manipulation needs to be done here to prevent crashing the app
  if (gradientType === 'linear-gradient' && isNaN(degrees)) {
    valueForEditor = value.replace('linear-gradient(', 'linear-gradient(180deg, ');
  }

  const prevHex = usePrevious(valueToHex());

  const handlePresetClick = (colorName: string, colorValue: string) => {
    setSolid(colorValue);
    onChange(`var(--${colorName})`);
  };

  // isPickerActive is to solve when mouseup is happening in the iframe viewer. The mouseup location
  // is in another viewport thus it never gets listened by the Color Picker and remains under drag mode
  const handlePickerMouseDown = () => {
    setIsPickerActive(true);
  };

  const handleGlobalMouseUp = () => {
    setIsPickerActive(false);
  };

  useEffect(() => {
    window.removeEventListener('mouseup', handleGlobalMouseUp, true);
    window.addEventListener('mouseup', handleGlobalMouseUp, true);
    return () => window.removeEventListener('mouseup', handleGlobalMouseUp, true);
  }, [handleGlobalMouseUp, isPickerActive]);

  // Gradient stop pointer is a bit special. It has position absolute thus mousedown doesn't work
  useEffect(() => {
    setIsPickerActive(true);
  }, [currentLeft]);

  // Whenever a user deliberate make a change to the color, we assume that the user want to see
  // the color in full opacity and we need to remove the alpha channel. User can always add it back
  const hex = valueToHex();
  useEffect(() => {
    if (isUserInteract && hex !== prevHex) setA(100);
    setIsUserInteract(false);
  }, [hex, isUserInteract]);

  return (
    <>
      <Overlay
        backgroundOpacity={0}
        fixed
        data-testid="overlay"
        zIndex={0} // For some reason, this value works for react-best-gradient-color-picker
        style={{ display: isPickerActive ? 'block' : 'none' }}
      />
      <div onMouseDown={handlePickerMouseDown}>
        <ColorPicker
          width={width}
          value={valueForEditor}
          onChange={(val: string) => {
            setIsUserInteract(true);
            onChange(val);
          }}
          hidePresets
          hideEyeDrop
          hideAdvancedSliders
          hideColorGuide
          hideInputType
          hideColorTypeBtns
        />
        {brandColors && brandColors.length > 0 && (
          <>
            <Title order={6} fw={800} mt={15} mb={4} size={11}>
              BRAND COLORS
            </Title>
            <Group justify="flex-start" gap="xs">
              {brandColors.map((token: BrandToken, key: number) => {
                const colorName = Object.keys(token)[0];
                const colorValue = token[colorName];

                return (
                  <Tooltip
                    key={`${colorValue}-${key}`}
                    label={formatBrandColorName(colorName)}
                    color="Gray"
                    withArrow
                    zIndex={10000}
                  >
                    {!colorValue.includes('gradient') ? (
                      <ColorSwatch
                        component="button"
                        color={colorValue}
                        onClick={() => handlePresetClick(colorName, colorValue)}
                        size={28}
                        style={{ cursor: 'pointer' }}
                        aria-label={`Brand color: ${formatBrandColorName(colorName)}`}
                      />
                    ) : (
                      <Box
                        component="button"
                        bg={colorValue}
                        w={28}
                        h={28}
                        style={{
                          borderRadius: 28,
                          border: '1px solid #96959c',
                          zIndex: 1,
                          cursor: 'pointer',
                        }}
                        onClick={() => handlePresetClick(colorName, colorValue)}
                        aria-label={`Brand color: ${formatBrandColorName(colorName)}`}
                      />
                    )}
                  </Tooltip>
                );
              })}
            </Group>
          </>
        )}
      </div>
    </>
  );
};
