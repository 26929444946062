import { Card, Group, rem, Stack, Switch, Text, Title, useMantineTheme } from '@mantine/core';
import { IconCheck, IconMailFast, IconX } from '@tabler/icons-react';
import { useState } from 'react';

type EmailExportSwitchProps = {
  initialChecked?: boolean;
  onChange: (checked: boolean) => void;
};

const EmailExportSwitch = ({ initialChecked = true, onChange }: EmailExportSwitchProps) => {
  const theme = useMantineTheme();
  const [checked, setChecked] = useState(initialChecked);
  return (
    <Card shadow="none" padding="lg" radius="md" withBorder>
      <Group justify="space-between">
        <Group>
          <IconMailFast size={48} />
          <Stack style={{ width: '250px' }} gap="xs">
            <Title order={5}>Email</Title>
            <Text size="sm" style={{ color: 'var(--color-grey)' }}>
              Send summary to your inbox
            </Text>
          </Stack>
        </Group>
        <Switch
          checked={checked}
          onChange={(e) => {
            setChecked(e.currentTarget.checked);
            onChange(e.currentTarget.checked);
          }}
          color="teal"
          size="lg"
          thumbIcon={
            checked ? (
              <IconCheck
                style={{ width: rem(12), height: rem(12) }}
                color={theme.colors.teal[6]}
                stroke={3}
              />
            ) : (
              <IconX
                style={{ width: rem(12), height: rem(12) }}
                color={theme.colors.red[6]}
                stroke={3}
              />
            )
          }
        />
      </Group>
    </Card>
  );
};

export default EmailExportSwitch;
