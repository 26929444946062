import { NAME_WORD_LIMIT, VARIANTS_KEYWORDS_LIMIT } from '~/constants';

const overMaxAmount = (value: string) => value.split(',').length > VARIANTS_KEYWORDS_LIMIT;

const findDupe = (value: string) =>
  Array.from(
    value
      .split(',')
      .map((item) => item.trim())
      .filter(
        (item, index, arr) => arr.indexOf(item) !== index && new Set(arr.slice(0, index)).has(item),
      ),
  );

export const validateKeywords = (value: string) => {
  if (overMaxAmount(value))
    return `You’ve reached the limit! Use ${VARIANTS_KEYWORDS_LIMIT} keywords or less.`;

  const dupe = findDupe(value);
  if (dupe.find((v) => v === '') !== undefined) return 'Empty name is not allowed';
  else if (dupe.length > 0) return `Duplicated keyword found - ${dupe.join(', ')}`;

  return null;
};

export const validateName = (value: string) => {
  if (value.length > NAME_WORD_LIMIT) return 'The variant name is too long';
  return null;
};
