import { ViewerData } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import { BlockOutput } from '~/messages.types';

export type InlineToolProps = {
  handleClickToEmitEvent: (key: string) => void;
};

export const handleClickToEmitEvent = (selection: string, key: string, value: string) => {
  const outputData = {
    // This is to conform EditorJs data model. We may not need to use this down the track
    blocks: [
      {
        id: selection,
        type: 'paragraph',
        data: {
          [key]: value,
        },
      },
    ],
  };

  msg({ type: 'editing-performed', outputData });
  msg({ type: 'editor-sync', outputData });
};

type StyleKey = 'color' | 'fontSize';
const camelToKebabCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (match: string) => '-' + match.toLowerCase());
};

const commonStyleInIframeProcessing = (
  outputData: { blocks: BlockOutput[] },
  element?: HTMLElement,
  key?: string,
  value?: string,
) => {
  if (element && key && value) {
    if (key === 'backgroundColor') element.style.backgroundImage = 'none';
    if (key === 'backgroundImage') element.style.backgroundColor = 'none';
    if (['backgroundColor', 'backgroundImage'].includes(key)) element.style.background = 'none';
    element.style[key as StyleKey] = value;
    if (key === 'backgroundImage') element.style.backgroundSize = 'cover';
    msg({ type: 'editing-performed', outputData });
  }
};

export const modifyElementStyleInIframe = (
  key: string,
  value: string,
  viewerData: {
    viewer?: string;
    elementSelector?: string;
  },
) => {
  const outputData = {
    // This is to conform EditorJs data model. We may not need to use this down the track
    blocks: [
      {
        id: viewerData.elementSelector,
        type: 'paragraph',
        data: {
          [key]: value,
        },
      },
    ],
  };

  const styleType = camelToKebabCase(key);

  if (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement) {
    const viewer = (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement)
      .contentDocument;

    const element = viewer?.querySelector(
      `[data-selector="${viewerData.elementSelector}"]`,
    ) as HTMLElement;

    if (element) {
      const spans = element.querySelectorAll(`span[style*="${styleType}:"]`);
      if (spans.length > 0) {
        spans.forEach((span) => {
          const style = span.getAttribute('style');
          if (style && style.includes(styleType) && !style.includes('background-color')) {
            const parent = span.parentNode;
            if (parent) {
              while (span.firstChild) {
                parent.insertBefore(span.firstChild, span);
              }
              parent.removeChild(span);
            }
          }
        });
      }
    }

    commonStyleInIframeProcessing(outputData, element, key, value);
  }
};

export const modifyColumnLayoutElementStyleInIframe = (
  viewerData: Partial<ViewerData>,
  key: string,
  value: string,
) => {
  // "style_for_section" is now aliased with just "style" for the convenience of ingestion service
  const forSyntax =
    viewerData.columnLayoutInnerComponentType === 'section'
      ? undefined
      : '.' + viewerData.columnLayoutInnerComponentType;

  const outputData = {
    blocks: [
      {
        id: viewerData.rootNodeHost,
        type: 'column-layout',
        forSyntax,
        data: {
          [key]: value,
        },
      },
    ],
  };

  if (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement) {
    const viewer = (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement)
      .contentDocument;

    const hostElement = viewer?.querySelector(
      `[data-selector="${viewerData.rootNodeHost}"]`,
    ) as HTMLElement;

    let innerSelector = `.${viewerData.columnLayoutInnerComponentType}`;
    if (viewerData.columnLayoutInnerComponentType === 'section') innerSelector = 'section';

    const element = hostElement.shadowRoot?.querySelector(innerSelector) as HTMLElement;

    commonStyleInIframeProcessing(outputData, element, key, value);
  }
};

/**
 * Shortcut to auto select modifyColumnLayoutElementStyleInIframe and modifyElementStyleInIframe
 * This should be used more often.
 */
export const modifyStyleInIframe = (
  key: string,
  newValue: string,
  viewerData: Partial<ViewerData>,
) => {
  if (viewerData.columnLayoutInnerComponentType)
    modifyColumnLayoutElementStyleInIframe(viewerData, key, newValue);
  else modifyElementStyleInIframe(key, newValue, viewerData);
};

// Text range is managed by our EditorJS bridge plugin. Thus no complex operation is needed here
// is just clicking and filling invisible UIs.
export const modifyTextRangeStyleInFirame = (
  css: string,
  value: string | null,
  viewerData: {
    viewer?: string;
  },
) => {
  if (css && value) {
    if (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement) {
      const viewer = (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement)
        .contentDocument;
      const styleButton = viewer?.querySelector(
        `span[data-tool=${css}] #marker-button`,
      ) as HTMLButtonElement;
      const input = viewer?.querySelector(`span[data-tool=${css}] input`) as HTMLInputElement;
      if (input && styleButton) {
        input.value = `${css}:${value};`;
        styleButton?.click();
      }
    }
  }
};
