import { ScrollArea } from '@mantine/core';
import { IconSection } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components';

import buttonHeader from './presets/buttonHeader';
import heroSplit from './presets/heroSplit';
import heroSplitReverse from './presets/heroSplitReverse';
import multiHorizontalSplit from './presets/multiHorizontalSplit';
import multiLogos from './presets/multiLogos';
import multiVerticalSplit from './presets/multiVerticalSplit';
import simpleHeader from './presets/simpleHeader';
import smallerSplit from './presets/smallerSplit';
import smallerSplitReverse from './presets/smallerSplitReverse';

import BasePanel from '../BasePanel/BasePanel';

import { PageJsonSnippetObj, ToolbarPanelProps } from '~/global.types';
import renderPage from '~/helpers/renderPage/renderPage';
import {
  addListenerForViewerDropzones,
  removeListenerForViewerDropzones,
} from '~/helpers/viewerInteractions/dropzoneEventHandling';

interface AddSectionProps {
  dataItem: PageJsonSnippetObj;
  label: string;
  // Why I can't just CSS Zoom the card and call it the day?
  // Because Safari just refuses to follow the crowd!
  h: number;
}

const DraggableElement = styled.div`
  position: relative;
  padding: 16px;
  border: 1px solid #4806d54a;
  border-radius: 4px;
  box-shadow: 2px 6px 8px 0px #d5d2f0;
  width: calc(100% - 16px);
  margin-left: 8px;

  & > div {
    transform: scale(0.3);
    transform-origin: 0 0;
    position: absolute;
    width: 1100px;
  }
`;

const SectionName = styled.h6`
  margin-left: 8px;
  margin-bottom: 8px;
`;

const AddSection = ({ dataItem = {}, label, h }: AddSectionProps) => {
  const [data, setData] = useState<TrustedHTML>('');

  const [{ cursor }, dragRef] = useDrag(
    () => ({
      type: 'add-page-section',
      item: dataItem,
      collect: (monitor) => {
        if (monitor.isDragging()) addListenerForViewerDropzones();
        return {
          cursor: monitor.isDragging() ? 'grabbing' : 'grab',
        };
      },
      end: removeListenerForViewerDropzones,
    }),
    [],
  );

  useEffect(() => {
    renderPage('', '', { contains: [dataItem] }).then((htmlStr) => {
      const html = new DOMParser().parseFromString(htmlStr, 'text/html');
      const section = html.querySelector('column-layout')?.outerHTML;
      setData(section as TrustedHTML);
    });
  }, []);

  return (
    <>
      <SectionName>{label}</SectionName>
      <DraggableElement ref={dragRef} style={{ cursor, height: h }} aria-label={`${label} option`}>
        {/* The HTML content is all defined in presets folder and safely generated from the hydrator. */}
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </DraggableElement>
    </>
  );
};

const AddSectionPanel = ({ opened, handleClickToToggleSubPanel }: ToolbarPanelProps) => {
  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('add-section')}
      label="Add section"
      title="Section"
      icon={<IconSection />}
    >
      <ScrollArea style={{ height: 'calc(100vh - 210px)' }} type="never">
        <AddSection h={190} dataItem={heroSplit} label="Hero Split" />
        <AddSection h={190} dataItem={heroSplitReverse} label="Hero Split Reverse" />
        <AddSection h={90} dataItem={multiLogos} label="Multi Logos" />
        <AddSection h={90} dataItem={simpleHeader} label="Simple Header" />
        <AddSection h={160} dataItem={multiHorizontalSplit} label="Multi Horizontal Split" />
        <AddSection h={190} dataItem={smallerSplit} label="Smaller Split" />
        <AddSection h={190} dataItem={smallerSplitReverse} label="Smaller Split Reverse" />
        <AddSection h={360} dataItem={multiVerticalSplit} label="Multi Vertical Split" />
        <AddSection h={100} dataItem={buttonHeader} label="Button Header" />
      </ScrollArea>
    </BasePanel>
  );
};

export default AddSectionPanel;
