import styled from 'styled-components';

const Frame = styled.iframe`
  border: 0;
  border-bottom: 1px solid #ddd;
  pointer-events: none;
  user-select: none;
  width: 100%;
`;

const FrameAsPageThumbnail = ({ nanoId }: { nanoId: string }) => (
  <Frame
    sandbox="allow-scripts allow-same-origin"
    loading="lazy"
    src={`../pageThumbnail.html?id=${nanoId}`}
  />
);

export default FrameAsPageThumbnail;
