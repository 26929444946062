/* eslint-disable max-len */
import { rem } from '@mantine/core';

interface CustomIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export function IconRegenVariants({ size, style, ...others }: CustomIconProps) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path
        d="M15.8681 8.41864C15.5554 8.09646 15.0447 8.09646 14.732 8.41864L10.2414 13.0453L8.28019 11.0246C7.96748 10.7024 7.45675 10.7024 7.14405 11.0246C6.8361 11.3419 6.8361 11.8528 7.14405 12.17L9.67336 14.776C9.98606 15.0982 10.4968 15.0982 10.8095 14.776L15.8681 9.56409C16.1761 9.24681 16.1761 8.73592 15.8681 8.41864Z"
        fill="currentColor"
      />
      <path
        d="M11.2514 0.874925C11.2514 0.391717 10.8596 0 10.3764 0H6.27775C5.57734 0 4.90794 0.275344 4.41429 0.769419C4.13093 1.05154 3.91825 1.39217 3.78848 1.76259C3.62801 2.22061 4.03494 2.62477 4.52026 2.62477H4.71162C5.04411 2.62477 5.31175 2.35487 5.55523 2.10932C5.58976 2.0745 5.62379 2.04018 5.65746 2.00718C5.82287 1.84249 6.04515 1.74985 6.27775 1.74985H10.3764C10.8596 1.74985 11.2514 1.35813 11.2514 0.874925ZM14.355 2.22519C14.3567 2.22522 14.3585 2.22523 14.3603 2.22523H14.7477C14.7505 2.22523 14.7532 2.22522 14.756 2.22519H14.355ZM7.22536 4.37502C7.22536 3.89181 6.83365 3.5001 6.35044 3.5001H3.64195H2.7632C1.30809 3.5001 0.126953 4.6761 0.126953 6.12487V13.1243C0.126953 14.573 1.30809 15.749 2.7632 15.749H6.35044C6.83365 15.749 7.22536 15.3573 7.22536 14.8741C7.22536 14.3909 6.83365 13.9992 6.35044 13.9992H2.7632C2.2773 13.9992 1.88445 13.608 1.88445 13.1243V6.12487C1.88445 5.64109 2.2773 5.24995 2.7632 5.24995H6.35044C6.83365 5.24995 7.22536 4.85823 7.22536 4.37502Z"
        fill="currentColor"
      />
      <path
        d="M14.604 0.664307C14.3461 0.664307 14.137 0.86294 14.137 1.10797C14.137 1.69629 13.891 2.26053 13.4531 2.67654C13.0151 3.09255 12.4212 3.32626 11.8019 3.32626C11.544 3.32626 11.3349 3.52489 11.3349 3.76992C11.3349 4.01495 11.544 4.21358 11.8019 4.21358C12.4212 4.21358 13.0151 4.44729 13.4531 4.8633C13.891 5.27931 14.137 5.84354 14.137 6.43187C14.137 6.6769 14.3461 6.87553 14.604 6.87553C14.8619 6.87553 15.071 6.6769 15.071 6.43187C15.071 5.84354 15.317 5.27931 15.7549 4.8633C16.1928 4.44729 16.7867 4.21358 17.406 4.21358C17.664 4.21358 17.873 4.01495 17.873 3.76992C17.873 3.52489 17.664 3.32626 17.406 3.32626C16.7867 3.32626 16.1928 3.09255 15.7549 2.67654C15.317 2.26053 15.071 1.69629 15.071 1.10797C15.071 0.86294 14.8619 0.664307 14.604 0.664307ZM14.604 2.70762C14.7401 2.92289 14.9044 3.1234 15.0945 3.30397C15.2845 3.48453 15.4956 3.64056 15.7222 3.76992C15.4956 3.89928 15.2845 4.05531 15.0945 4.23587C14.9044 4.41644 14.7401 4.61695 14.604 4.83222C14.4678 4.61695 14.3036 4.41644 14.1135 4.23587C13.9234 4.05531 13.7124 3.89928 13.4858 3.76992C13.7124 3.64056 13.9234 3.48453 14.1135 3.30397C14.3036 3.1234 14.4678 2.92289 14.604 2.70762ZM11.0047 5.85827C10.9171 5.77507 10.8679 5.66222 10.8679 5.54456C10.8679 5.29953 10.6588 5.1009 10.4009 5.1009C10.143 5.1009 9.93389 5.29953 9.93389 5.54456C9.93389 5.66222 9.88468 5.77507 9.7971 5.85827C9.70952 5.94147 9.59073 5.98821 9.46688 5.98821C9.20895 5.98821 8.99987 6.18685 8.99987 6.43187C8.99987 6.6769 9.20895 6.87553 9.46688 6.87553C9.59073 6.87553 9.70952 6.92227 9.7971 7.00548C9.88468 7.08868 9.93389 7.20153 9.93389 7.31919C9.93389 7.56422 10.143 7.76285 10.4009 7.76285C10.6588 7.76285 10.8679 7.56422 10.8679 7.31919C10.8679 7.20153 10.9171 7.08868 11.0047 7.00548C11.0923 6.92227 11.2111 6.87553 11.3349 6.87553C11.5928 6.87553 11.8019 6.6769 11.8019 6.43187C11.8019 6.18685 11.5928 5.98821 11.3349 5.98821C11.2111 5.98821 11.0923 5.94147 11.0047 5.85827Z"
        fill="currentColor"
      />
    </svg>
  );
}
