import { PageJsonSnippetForEditing, PageJsonSnippetObj } from '~/global.types';

type GetPageObjectOptions<T> = { id?: string } & { [K in keyof T]: T[K] };

type GetPageObjectCallback<T> = (
  updatedObject: PageJsonSnippetObj & Partial<MarkDelete>,
  options: GetPageObjectOptions<T>,
) => PageJsonSnippetObj;

type GetPageObjectReturn = {
  fullUpdatedObject: PageJsonSnippetObj;
  changedObject?: Record<string, string>;
};

type MarkDelete = {
  isDelete: true;
  deletedSlot: string;
};

export const findAndChangePageData = <T>(
  obj: PageJsonSnippetObj,
  options: GetPageObjectOptions<T>,
  callback: GetPageObjectCallback<T>,
  changedObject?: Record<string, string>,
): GetPageObjectReturn => {
  const { id: elementSelector } = options;
  let updatedObject = JSON.parse(JSON.stringify(obj));
  let returnedChangedObject = changedObject;

  for (const key in updatedObject) {
    if (key === 'selector' && updatedObject[key] === elementSelector) {
      updatedObject = callback(updatedObject, options);
      returnedChangedObject = updatedObject;
    } else if (typeof updatedObject[key] === 'object') {
      const recurring = findAndChangePageData(
        updatedObject[key],
        options,
        callback,
        returnedChangedObject,
      );

      // Needs to keep track of the changedObject and return it during the last recursion
      updatedObject[key] = recurring.fullUpdatedObject;
      if (recurring.changedObject !== undefined) {
        returnedChangedObject = recurring.changedObject;
      }
    }
  }

  return { fullUpdatedObject: updatedObject, changedObject: returnedChangedObject };
};

export const findPageData = (
  obj: PageJsonSnippetObj,
  elementSelector?: string,
): PageJsonSnippetForEditing | undefined => {
  if (!elementSelector) return;

  const result = findAndChangePageData(
    obj,
    { id: elementSelector },
    (updatedObject) => updatedObject,
  );
  return result.changedObject;
};
