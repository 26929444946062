import { ActionIcon, HoverCard, Loader, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';

import { OverlayVariantRegenIndicator } from '../PannableOverlays.styles';
import {
  OverlayBoundRect,
  pinPointerFromElementBoundInViewer,
  transformPointerToElementBound,
} from '../SetBoundForOverlayElements';

import { IconRegenVariants } from '~/components/CustomIcons/IconRegenVariants';
import msg from '~/helpers/viewerInteractions/msg';
import { PageSetVariantStateObj, usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

interface VariantNodeOverlayProps {
  show: boolean;
}

type OverlayData = {
  id: string;
  status?: 'analyzing' | 'editing' | 'unsynced';
};

// Export this out only for mocking in the test
// eslint-disable-next-line react-refresh/only-export-components
export const setPins = ({
  editedVariantNodes,
}: PageSetVariantStateObj): OverlayBoundRect<OverlayData>[] => {
  const viewer = document.querySelector<HTMLIFrameElement>('iframe[id^="iFrameResizer"]');
  if (viewer) {
    const variantNodes = viewer.contentDocument?.querySelectorAll('[data-selector]') ?? [];

    const domRects = [...variantNodes].reduce(
      (arr: OverlayBoundRect<OverlayData>[], curr: Element) => {
        const editedNode =
          editedVariantNodes && editedVariantNodes[curr.getAttribute('data-selector') ?? ''];

        if (!editedNode) {
          return arr;
        }

        const pinId = `variant-node-${curr.id}`;
        const id = curr.getAttribute('data-selector') || '';
        pinPointerFromElementBoundInViewer(pinId, viewer.id, curr.getBoundingClientRect());
        transformPointerToElementBound(
          pinId,
          { id, status: editedNode.status },
          (result: OverlayBoundRect<OverlayData>) => {
            arr.push(result);
          },
        );

        return arr;
      },
      [],
    );

    return domRects;
  }

  return [];
};

const AnalyzingHoverCard = ({
  x,
  y,
  width,
  height,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  return (
    <HoverCard
      width={226}
      zIndex={1100}
      transitionProps={{ duration: 200, transition: 'pop', timingFunction: 'ease' }}
    >
      <HoverCard.Target>
        <ActionIcon variant="outline" color="violet">
          <Loader size={14} />
        </ActionIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown
        style={{
          left: x + width,
          top: y + height + 4,
        }}
      >
        <Stack align="center" gap={10}>
          <Loader size={16} />
          <Text size="10px" fw={400} ta="center" c="black" px={10} style={{ lineHeight: '14px' }}>
            Upflowy is checking for new variants!
            <br /> This may take a few seconds...
          </Text>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const OverlayStatusIcon = ({
  status,
  x,
  y,
  width,
  height,
}: {
  status: OverlayData['status'];
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  const iconProps = { variant: 'outline', color: 'violet' };
  const statusComponentMap = {
    unsynced: (
      <ActionIcon {...iconProps}>
        <IconRegenVariants />
      </ActionIcon>
    ),
    analyzing: <AnalyzingHoverCard x={x} y={y} width={width} height={height} />,
    editing: (
      <ActionIcon {...iconProps}>
        <IconRegenVariants color="var(--mantine-color-gray-5)" />
      </ActionIcon>
    ),
  };
  return statusComponentMap[status || 'editing'];
};

const VariantNodeOverlay = ({ show }: VariantNodeOverlayProps) => {
  const [bounds, setBounds] = useState<OverlayBoundRect<OverlayData>[]>([]);
  const { selectedVariant, variantState } = usePageSet();

  useEffect(() => {
    if (variantState?.editedVariantNodes) setBounds(setPins(variantState));
    else setBounds([]);
  }, [selectedVariant, variantState, show]);

  return bounds.map(({ data, x, y, width, height }) => {
    const withinScreenX = x >= 0 && x <= window.innerWidth;
    const withinScreenY = y >= 0 && y <= window.innerHeight;

    return !withinScreenX || !withinScreenY ? null : (
      <OverlayVariantRegenIndicator
        key={data.id}
        style={{
          left: x + width + 4,
          top: y,
        }}
        onClick={() =>
          data.status === 'unsynced' &&
          msg({
            type: 'show-sync-variant-modal',
          })
        }
      >
        <OverlayStatusIcon status={data.status} x={x} y={y} width={width} height={height} />
      </OverlayVariantRegenIndicator>
    );
  });
};

export default VariantNodeOverlay;
