import styled from 'styled-components';

export const Panel = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: var(--br-5xs);
  box-shadow: var(--m3-elevation-light-1);
  background-color: var(--m3-sys-light-on-primary);
  overflow: hidden;
  z-index: 999;
`;
