import styled from 'styled-components';

export const HeaderPreview = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`;

export const ButtonPreview = styled.button`
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SelectedButton = styled.div`
  position: absolute;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background: #3606a6;
  border-radius: 4px;
  margin-top: 4px;
  cursor: pointer;
`;
