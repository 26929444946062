import { DependencyList, useEffect } from 'react';

import { StudioMessageCollection } from '~/messages.types';

/**
 * This is now strictly typed. To listen to any kind of messages using this facility,
 * the type needs to be defined in message.type, or else it will warn you with type errors
 */
const useViewerMessage = (
  listenerCallback: (arg: MessageEvent<StudioMessageCollection>) => void,
  dependencyList?: DependencyList,
) =>
  useEffect(() => {
    const viewerMessageListener = listenerCallback;
    window.addEventListener('message', viewerMessageListener);

    return () => {
      window.removeEventListener('message', viewerMessageListener);
    };
  }, dependencyList);

export default useViewerMessage;
