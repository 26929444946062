import { Button, Image, List, Modal, rem } from '@mantine/core';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import styled from 'styled-components';

import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { setupDefaultSubscription } from '~/services/PaymentServices';
import './Pricing.css';
import 'https://js.stripe.com/v3/pricing-table.js';

const StyledListItem = styled(List.Item)`
  color: var(--dark-dark-7, #1a1b1e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

const ListHeading = styled.span`
  display: block;
  color: var(--dark-dark-7, #1a1b1e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`;

const Pricing = ({
  onClose,
  withCloseButton,
}: {
  onClose: () => void;
  withCloseButton: boolean;
}) => {
  const { userAndWorkspaceInfo, updateUserAndWorkspace } = useFeatureFlags();

  const getModalSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 2000) {
      return '90%';
    } else {
      return '60%';
    }
  };

  // Get the size based on the screen width
  const modalSize = getModalSize();

  const handleSkip = async () => {
    await setupDefaultSubscription();
    updateUserAndWorkspace({
      hasActiveIntentSubscription: true,
    });
    onClose();
  };

  return (
    <Modal
      size={modalSize}
      onClose={onClose}
      opened
      withCloseButton={withCloseButton}
      closeOnClickOutside={withCloseButton}
      closeOnEscape={withCloseButton}
    >
      <div className="pricing-container">
        <div className="banner-container">
          <Image src="/banner.png" alt="banner" />
          <div className="free-plan-container">
            <h4>Give our free plan a go</h4>
            <ListHeading>Get started with intent basics:</ListHeading>
            <List
              spacing="xs"
              size="sm"
              center
              icon={
                <IconCircleCheckFilled
                  style={{ width: rem(16), height: rem(16), color: '#8C8C8C' }}
                />
              }
            >
              <StyledListItem>Track up to 50 leads monthly</StyledListItem>
              <StyledListItem>Email notifications</StyledListItem>
              <StyledListItem>Integrate with Slack, CRMs and 1000+ tools</StyledListItem>
            </List>
            <StyledButton onClick={handleSkip}>Continue for free</StyledButton>
          </div>
        </div>
        <stripe-pricing-table
          pricing-table-id={import.meta.env.VITE_APP_PRICING_TABLE_ID}
          publishable-key={import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY}
          client-reference-id={userAndWorkspaceInfo.user?.userUuid}
          customer-email={userAndWorkspaceInfo.user?.email}
        />
      </div>
    </Modal>
  );
};
export default Pricing;
