import { Button, Center, Loader, Stack } from '@mantine/core';
import { SyntheticEvent } from 'react';
import styled from 'styled-components';

import FakeLeadSummary from './FakeLeadSummary';

const Wrapper = styled.div`
  position: relative;
`;

const StyledCenter = styled(Center)`
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
  margin-top: 25px;
`;

const StyledButton = styled(Button)`
  font-weight: 300;
`;

type AnalysisProcessingProps = {
  randomLength: number;
}
const AnalysisProcessing = ({ randomLength }: AnalysisProcessingProps) => {
  return (
    <Wrapper>
      <FakeLeadSummary randomLength={randomLength} />
      <StyledCenter>
        <Stack maw="60%" gap="xs">
          <StyledButton
            size="compact-sm"
            variant="filled"
            onClick={(e: SyntheticEvent) => {
              e.preventDefault();
              e?.stopPropagation();
            }}
          >
            <Loader size="xs" color="#fff" />&nbsp;&nbsp;&nbsp;Processing
          </StyledButton>
        </Stack>
      </StyledCenter>
    </Wrapper>
  );
};

export default AnalysisProcessing;
