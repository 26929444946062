import { PageJsonSnippetObj } from '~/global.types';
import { StudioMessage } from '~/messages.types';

function findOrAppendData(
  arrayObj: PageJsonSnippetObj[],
  [find, isValue]: [string, string],
  snippet: PageJsonSnippetObj,
) {
  const foundIndex = arrayObj.findIndex((node) => node[find] === isValue);

  if (foundIndex !== -1) {
    arrayObj[foundIndex] = snippet;
  } else {
    arrayObj.push(snippet);
  }
}

function modifyPublishSettings(
  obj: PageJsonSnippetObj,
  form: StudioMessage['EditingPublishSettings']['outputData'],
) {
  const { title, favicon, description, googleTagManagerId } = form;
  const objHead = (obj.head as PageJsonSnippetObj[]) || [];

  findOrAppendData(objHead, ['@name', 'description'], {
    '@name': 'description',
    textValue: '',
    '@content': description,
    tagName: 'meta',
  });

  return {
    ...obj,
    title,
    favicon,
    googleTagManagerId,
    head: objHead,
  };
}

export default modifyPublishSettings;
