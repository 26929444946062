import { IconPhoto } from '@tabler/icons-react';

import AddImage from './AddImage';

import BasePanel from '../BasePanel/BasePanel';

import ImageMasonry from '~/components/ImageMasonry/ImageMasonry';
import ImageUploader from '~/components/ImageUploader/ImageUploader';
import { ToolbarPanelProps } from '~/global.types';

const AddImagePanel = ({ opened, handleClickToToggleSubPanel }: ToolbarPanelProps) => {
  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('add-image')}
      label="Add images"
      title="Images"
      icon={<IconPhoto />}
    >
      <ImageUploader />

      <ImageMasonry itemComponentOverride={AddImage} />
    </BasePanel>
  );
};

export default AddImagePanel;
