/* eslint-disable react-refresh/only-export-components */
import { CSSProperties } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import dropToAddElementProcessing from './dropToAddElementProcessing/dropToAddElementProcessing';
import dropToAddSectionProcessing from './dropToAddSectionProcessing/dropToAddSectionProcessing';
import useDefineDropBound from './useDefineDropBound';
import { AreaOverlay, SectionLines } from './visualGuideComponents/AreaOverlay';
import Dropline from './visualGuideComponents/Dropline';
import ImageElementOverlay from './visualGuideComponents/ImageElementOverlay';

import { ElementClickedBoundRect } from '../SetBoundForOverlayElements';

import { TOOLTIPS_FOR_DRAG_DROP_TO_ADD, TOOLTIPS_FOR_DRAG_DROP_TO_MOVE } from '~/constants';
import { PageJsonSnippetObj, ParsedLandingpageObject } from '~/global.types';

export const LINE_THICKNESS = 2;
export const PADDED_CLICK_AREA_THICKNESS_PERCENTAGE = 0.2;
export const MAX_PADDED_CLICK_AREA = 24;

export const ACCEPT_MESSAGES = [
  'add-page-element',
  'add-from-collection',
  'add-page-section',
  'move-page-element',
  'move-page-section',
];
export const ACCEPT_MESSAGES_ONLY_SECTION = ['move-page-section', 'add-page-section'];
export const ACCEPT_MESSAGES_NOMOVE = ['add-from-collection'];

export interface DropzoneProps {
  hoverBound: Partial<ElementClickedBoundRect>;
  drop?: (node: PageJsonSnippetObj | { files: File[] }) => void;
  label?: string;
  style?: CSSProperties;
}
interface DropzoneOverlayProps {
  // Useful for uploading files, which require workspace id
  pageDetails?: Omit<ParsedLandingpageObject, 'content'>;
}

const DropzoneOverlay = ({ pageDetails }: DropzoneOverlayProps) => {
  const { hoverBound, decorBound, resetBound } = useDefineDropBound();

  const [{ itemType }] = useDrop(
    () => ({
      accept: [...ACCEPT_MESSAGES, NativeTypes.FILE],
      collect: (monitor) => ({
        itemType: monitor.getItemType(),
      }),
    }),
    [hoverBound],
  );

  const handleDropAddElement =
    (additionalState = {}) =>
    async (node: PageJsonSnippetObj | { files: File[] }) => {
      await dropToAddElementProcessing(
        (itemType as string) ?? '',
        node,
        hoverBound,
        pageDetails,
        additionalState,
      );
      resetBound();
    };

  const handleDropAddSection =
    (isTop?: boolean) => async (node: PageJsonSnippetObj | { files: File[] }) => {
      await dropToAddSectionProcessing(
        (itemType as string) ?? '',
        node,
        hoverBound,
        pageDetails,
        isTop,
      );
      resetBound();
    };

  const isMove = itemType === 'move-page-element';
  const isMoveSection = itemType === 'move-page-section';

  const toSectionToolTip = isMove
    ? TOOLTIPS_FOR_DRAG_DROP_TO_MOVE.TO_SECTION
    : TOOLTIPS_FOR_DRAG_DROP_TO_ADD.TO_SECTION;

  const moveSectionToolTip = isMoveSection && TOOLTIPS_FOR_DRAG_DROP_TO_MOVE.MOVE_SECTION;

  const toColumnToolTip = isMove
    ? TOOLTIPS_FOR_DRAG_DROP_TO_MOVE.TO_COLUMN
    : TOOLTIPS_FOR_DRAG_DROP_TO_ADD.TO_COLUMN;

  const defaultTooltip = isMove
    ? TOOLTIPS_FOR_DRAG_DROP_TO_MOVE.default
    : TOOLTIPS_FOR_DRAG_DROP_TO_ADD.default;

  // For Column Layout sections
  if (hoverBound.data.tagName === 'SECTION')
    return (
      <>
        <Dropline
          at="top"
          hoverBound={hoverBound}
          drop={handleDropAddSection(true)}
          label={moveSectionToolTip || toSectionToolTip}
        />
        <Dropline
          at="bottom"
          hoverBound={hoverBound}
          drop={handleDropAddSection()}
          label={moveSectionToolTip || toSectionToolTip}
        />
        <SectionLines hoverBound={hoverBound} />
      </>
    );

  // For Column Layout container - for adding new column
  if (
    !ACCEPT_MESSAGES_ONLY_SECTION.includes(itemType as string) &&
    hoverBound.data.slot &&
    !hoverBound.data.elementSelector
  )
    return (
      <>
        <AreaOverlay hoverBound={decorBound} />
        {+(hoverBound.data.slot?.match(/\d+/g) || '0') === 1 && (
          <Dropline
            hoverBound={hoverBound}
            drop={handleDropAddElement({ addNewCol: 1 })}
            label={toColumnToolTip}
          />
        )}
        <Dropline
          at="right"
          hoverBound={hoverBound}
          drop={handleDropAddElement({
            addNewCol: +(hoverBound.data.slot?.match(/\d+/g) || '0') + 1,
          })}
          label={toColumnToolTip}
        />
      </>
    );

  // Else, anything related inside Column Layout columns
  return ACCEPT_MESSAGES_ONLY_SECTION.includes(itemType as string) ? null : (
    <>
      <AreaOverlay hoverBound={decorBound} />

      {hoverBound.data.slottedIndex === 0 && (
        <Dropline
          at="top"
          hoverBound={hoverBound}
          drop={handleDropAddElement({ addBefore: hoverBound.data.elementSelector })}
          label={defaultTooltip}
        />
      )}
      <Dropline
        at="bottom"
        hoverBound={hoverBound}
        drop={handleDropAddElement({ addAfter: hoverBound.data.elementSelector })}
        label={defaultTooltip}
      />

      {hoverBound.data.tagName === 'IMG' && (
        <ImageElementOverlay
          hoverBound={hoverBound}
          drop={handleDropAddElement({ replaceSrc: hoverBound.data.elementSelector })}
        />
      )}

      {hoverBound.data.tagName === 'A' && hoverBound.data.isLinkWrappedMedia && (
        <ImageElementOverlay
          hoverBound={hoverBound}
          drop={handleDropAddElement({ replaceSrc: hoverBound.data.elementSelector })}
        />
      )}
    </>
  );
};

export default DropzoneOverlay;
