import { Button, FocusTrap, Group, Modal, NativeSelect, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

import { Role } from '~/global.types';
import { inviteUserToWorkspace } from '~/services/UserServices';

type InviteUserModalProps = {
  workspaceId: string;
  opened: boolean;
  close: () => void;
};

const InviteUserModal = ({ workspaceId, opened = false, close }: InviteUserModalProps) => {
  const [updating, setUpdating] = useState(false);

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      email: '',
      role: 'owner',
    },
  });
  const handleFormSubmit = async ({ email, role }: { email: string; role: string }) => {
    setUpdating(true);
    try {
      await inviteUserToWorkspace({
        workspaceId,
        email,
        role: role as Role,
        sendEmailInvitation: true,
      });
      notifications.show({
        color: 'blue',
        title: 'User has been invited to your workspace successfully!',
        message: '',
        autoClose: 3000,
      });
      form.reset();
      close();
    } catch (error) {
      console.log(error);
      notifications.show({
        color: 'red',
        title: 'Failed to invite user to workspace',
        message: 'Please try again',
        autoClose: 3000,
      });
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={'Share workspace'}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <FocusTrap active>
          <TextInput
            data-autofocus
            type="email"
            label="Enter user email:"
            placeholder="john.doe@example.com"
            {...form.getInputProps('email')}
            required
            style={{ marginBottom: 10 }}
          />
        </FocusTrap>
        <NativeSelect
          mt="md"
          label="Select user role"
          data={[
            {
              label: 'Owner',
              value: 'owner',
            },
            {
              label: 'Admin',
              value: 'admin',
            },
          ]}
          {...form.getInputProps('role')}
        />
        <Group mt="md" justify="flex-end">
          <Button aria-label="Close Modal" onClick={close} variant="default" type="button">
            Cancel
          </Button>
          <Button variant="primary" type="submit" loading={updating}>
            Invite
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default InviteUserModal;
