import { PageJsonSnippetObj } from '~/global.types';

/* eslint-disable max-len */
export default {
  contains: [
    {
      textValue: 'This is heading 2',
      '@slot': 'column-1',
      selector: 'elem-VLpHkUXp',
      style: {
        color: 'var(--color-text-lightbg)',
        'line-height': '1.5',
        'font-family': 'var(--font-family-headings)',
      },
      tagName: 'h2',
      type: 'text',
      object: 'primitive',
    },
    {
      textValue:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed diam mi, vehicula sit amet nunc vel, faucibus volutpat eros.',
      '@slot': 'column-1',
      selector: 'elem-Hu8NpqE3',
      style: {
        color: 'var(--color-text-lightbg)',
        'line-height': '1.5',
        'font-family': 'var(--font-family-paragraphs)',
        'font-size': '14px',
      },
      tagName: 'div',
      type: 'text',
      object: 'primitive',
    },
    {
      textValue: 'CTA Button',
      '@slot': 'column-1',
      selector: 'elem-usbNAMkQ',
      style: {
        cursor: 'pointer',
        'border-radius': 'var(--button-radius)',
        'background-color': 'var(--color-secondary-100)',
        border: '1px solid var(--color-secondary-100)',
        padding: '9px 22px',
        color: 'var(--color-text-darkbg)',
        'font-weight': '400',
        width: 'fit-content',
        'font-size': '14px',
        'line-height': '1.5',
        'font-family': 'var(--font-family-headings)',
        'text-align': 'center',
      },
      style_max1200: {
        width: '100%',
        'box-sizing': 'border-box',
      },
      tagName: 'a',
      type: 'button',
      '@href': '',
      '@role': 'button',
      object: 'primitive',
    },
    {
      '@loading': 'lazy',
      '@slot': 'column-2',
      '@alt': '',
      '@src': 'https://placehold.co/300',
      style: {
        'border-radius': 'var(--card-radius)',
        'align-self': 'center',
        width: 'min(400px, 100%)',
        height: 'auto',
      },
      selector: 'elem-oMAYiaqf',
      tagName: 'img',
      type: 'image',
      object: 'primitive',
    },
  ],
  'style_max1200_for_.column-2': {
    'align-items': 'center',
  },
  'style_for_.column': {
    'justify-content': 'center',
  },
  'style_max1200_for_.container': {
    padding: '32px',
  },
  style: {
    padding: '16px',
    gap: '80px',
  },
  selector: 'layout-usbNAMkQ',
  layoutName: 'column-layout',
  object: 'section',
} as PageJsonSnippetObj;
