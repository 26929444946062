import { ActionIcon, Box, Image, Switch, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { IconPhoto, IconTransform } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import isURL from 'validator/lib/isURL';

// eslint-disable-next-line max-len
import attachImageFromCollection from '../../PannableOverlays/DropzoneOverlay/attachImageFromCollection/attachImageFromCollection';
import BasePanel from '../BasePanel/BasePanel';

import ImageMasonry from '~/components/ImageMasonry/ImageMasonry';
import ImageUploader from '~/components/ImageUploader/ImageUploader';
import { PANEL_DEBOUNCE_UPDATE_INTERVAL, REGEX_PROTOCOL } from '~/constants';
import { ToolbarPanelProps } from '~/global.types';
import { isInputFromSidePanel } from '~/helpers/domChecks/domChecks';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { BlockOutput } from '~/messages.types';

const SelectedImagePanel = ({
  opened,
  handleClickToToggleSubPanel,
  dataFromPostMessage = {},
}: ToolbarPanelProps) => {
  const [selector, setSelector] = useState('');
  const [displayImageFullPath, setDisplayImageFullPath] = useState('');
  const [inReplaceImagePanel, setInReplaceImagePanel] = useDisclosure();
  const [isReplacingImage, setIsReplacingImage] = useDisclosure();

  const form = useForm({
    initialValues: {
      alt: '',
      anchorHref: '',
      anchorTarget: false,
    },
    validateInputOnChange: true,
    validate: {
      anchorHref: (value: string) =>
        // Is either blank or URL string - blank will transform image link back to decorative image
        value.length === 0 || (REGEX_PROTOCOL.test(value) && isURL(value))
          ? null
          : 'Enter your website URL in the correct format, like https://www.company.com',
    },
  });

  const handleEventToSubmit = () => {
    if (!opened || !form.isDirty()) return;

    const { values } = form;

    let outputData: BlockOutput = {
      id: dataFromPostMessage.selector as string,
      jsonOverride: {
        tagName: 'img',
        contains: undefined,
        '@alt': values.alt,
        '@src': dataFromPostMessage['@src'],
        '@href': undefined,
        '@data-image-link': undefined,
        '@target': undefined,
      },
    };

    if (values.anchorHref && values.anchorHref.length > 0) {
      outputData = {
        id: dataFromPostMessage.selector as string,
        jsonOverride: {
          tagName: 'a',
          '@alt': undefined,
          '@href': values.anchorHref,
          '@target': values.anchorTarget ? '_blank' : undefined,
          '@data-image-link': true,
          contains: [
            {
              '@height': '100%',
              '@width': '100%',
              '@alt': values.alt,
              '@src': dataFromPostMessage['@src'],
              '@data-wrapped-element': true,
              tagName: 'img',
            },
          ],
        },
      };
    }

    msg({ type: 'editing-performed-from-panel', outputData });
  };

  const [debounced] = useDebouncedValue(form.values, PANEL_DEBOUNCE_UPDATE_INTERVAL);

  // All input has debounced trigger message to achieve page data transform and autosave
  useEffect(() => {
    if (form.isValid()) handleEventToSubmit();
  }, [debounced]);

  // useForm initialValues does not truly grab the props, as it is a Collapsible, it has already
  // been mounted with empty dataFromPostMessage props
  useEffect(() => {
    if (opened && !isInputFromSidePanel(document.activeElement, 'Image')) {
      const obj = {
        alt: (dataFromPostMessage['@alt'] as string) || '',
        anchorHref: (dataFromPostMessage['@href'] as string) || '',
        anchorTarget: dataFromPostMessage['@target'] === '_blank',
      };

      // If is image link, it needs to grab the element in contains
      if (dataFromPostMessage['@data-image-link'] && Array.isArray(dataFromPostMessage.contains)) {
        const nested = dataFromPostMessage.contains.find((item) => item['@data-wrapped-element']);
        obj.alt = nested['@alt'];
      }

      form.setValues(obj);
      form.resetDirty(obj);
    }
  }, [opened, dataFromPostMessage]);

  useViewerMessage(async ({ data }) => {
    // The full path source can only easily grabbed from the rendered result
    // The JSON data usually contains only the relative path
    if (data.type === 'element-selected-in-viewer') {
      setDisplayImageFullPath(data.imgAttr?.src || '');
      setSelector(data.elementSelector ?? '');
      setInReplaceImagePanel.close();
      setIsReplacingImage.close();
    }
  }, []);

  const imageDetailForm = (
    <>
      <Box
        pos="relative"
        display="inline-block"
        className={isReplacingImage ? 'replacing-image' : ''}
      >
        <Image
          radius="md"
          h={'auto'}
          w={'auto'}
          mih={96}
          miw={96}
          maw="100%"
          mah={300}
          src={displayImageFullPath}
          fit="unset"
        />
        <ActionIcon
          pos="absolute"
          bottom={8}
          right={8}
          size="sm"
          onClick={setInReplaceImagePanel.open}
          aria-label="Replace"
        >
          <IconTransform size={12} />
        </ActionIcon>
      </Box>
      <Title order={5} mt={32}>
        Image data
      </Title>

      <TextInput
        label="ALT text"
        description={
          <span>
            Describe the purpose of the image.
            <br />
            Leave empty if the image is decorative.
          </span>
        }
        placeholder="Media alternative text"
        size="sm"
        mt="md"
        {...form.getInputProps('alt')}
      />

      <TextInput
        label="Image link"
        description={
          <span>
            Make this image a link.
            <br />
            Leave this blank to remove the link.
          </span>
        }
        placeholder="e.g. https://mywebsite.com"
        size="sm"
        my="md"
        {...form.getInputProps('anchorHref')}
      />
      <Switch
        label="Open in new tab"
        {...form.getInputProps('anchorTarget', { type: 'checkbox' })}
        disabled={form.values.anchorHref === '' || !form.values.anchorHref}
      />
    </>
  );

  const replaceImage = (
    <>
      <ImageUploader />
      <ImageMasonry
        onItemClick={(item, workspaceId) => {
          const src = '@src' in item && typeof item['@src'] === 'string' ? item['@src'] : '/';
          const node = attachImageFromCollection(workspaceId, src);

          msg({
            type: 'replace-and-upload-image',
            imageSrc: node['@data-image-src'] as string,
            selector,
          });

          setInReplaceImagePanel.close();
          setIsReplacingImage.open();
        }}
      />
    </>
  );

  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('selected-image-panel')}
      label="Image"
      title={inReplaceImagePanel ? 'Replace Image' : 'Image'}
      icon={<IconPhoto />}
    >
      {inReplaceImagePanel ? replaceImage : imageDetailForm}
    </BasePanel>
  );
};

export default SelectedImagePanel;
