import { Button, Code, CopyButton } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const StyledButton = styled(Button)`
  position: absolute;
  top: 4px;
  right: 4px;
  font-weight: 400;
  font-size: small;
`;

const CodeWithCopyButton = ({ code }: { code: string }) => (
  <Wrapper>
    <Code block>{code}</Code>
    <CopyButton value={code}>
      {({ copied, copy }) => (
        <StyledButton
          onClick={copy}
          variant="default"
          size="compact-md"
          leftSection={copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
        >
          {copied ? 'Copied!' : 'Copy'}
        </StyledButton>
      )}
    </CopyButton>
  </Wrapper>
);

export default CodeWithCopyButton;
