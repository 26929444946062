import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { ElementHoverImageElement, InvisibleLayer } from '../../PannableOverlays.styles';
import {
  ACCEPT_MESSAGES_NOMOVE,
  DropzoneProps,
  PADDED_CLICK_AREA_THICKNESS_PERCENTAGE,
} from '../DropzoneOverlay';

const ImageElementOverlay = ({ drop, hoverBound }: DropzoneProps) => {
  const [{ canDrop, isOver }, dropzone] = useDrop(
    () => ({
      accept: [...ACCEPT_MESSAGES_NOMOVE, NativeTypes.FILE],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop,
    }),
    [hoverBound],
  );

  const { x = -99, y = -99, width = 0, height = 0 } = hoverBound;
  const heightOffset = PADDED_CLICK_AREA_THICKNESS_PERCENTAGE * height;

  return (
    canDrop && (
      <InvisibleLayer
        {...(dropzone && { ref: dropzone })}
        style={{
          left: x,
          top: y + heightOffset,
          width,
          height: height - heightOffset * 2,
        }}
      >
        <ElementHoverImageElement
          className={`${isOver ? 'is-hovered' : ''}`}
          style={{
            left: x,
            top: y,
            width,
            height,
          }}
        />
      </InvisibleLayer>
    )
  );
};

export default ImageElementOverlay;
