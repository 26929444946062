import { Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

const NoActivityDetected = () => (
  <Alert
    variant="outline"
    color="#5A1CEC"
    title="We haven’t detected any activity on your site"
    icon={<IconInfoCircle />}
  >
    {'Since you signed up we haven’t received any session data from your website. ' +
      'Follow the instructions below to enable tracking on your page.'}
  </Alert>
);

export default NoActivityDetected;
