import { Popover, PopoverWidth, Text, Tooltip } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

import { VariantButton } from './VariantDropdownButton.styles';

import IconVariantGradient from '~/components/CustomIcons/IconVariantGradient';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';

interface VariantDropdownButtonProps {
  isLoading?: boolean;
  popoverWidth: PopoverWidth;
  selectedVariantName?: string;
  children: React.ReactNode;
}

const VariantDropdownButton: React.FC<VariantDropdownButtonProps> = ({
  isLoading,
  popoverWidth,
  selectedVariantName,
  children,
}) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setOpened(false);
    }
  }, [isLoading]);

  useViewerMessage(({ data }) => {
    // Anything selected inside the viewer, which is actually iframe, a seperate
    // viewport. It should consider this as a click out from the variant dropdown.
    if (data.type === 'element-selected-in-viewer') {
      setOpened(false);
    }
  }, []);

  return isLoading ? (
    <VariantButton
      variant="light"
      loading={isLoading}
      loaderProps={{ type: 'dots' }}
      $opened={opened}
    />
  ) : (
    <Popover
      width={popoverWidth}
      trapFocus
      position="bottom"
      withArrow
      withinPortal={false}
      shadow="md"
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Tooltip
          label="View variants"
          withArrow
          color="var(--mantine-color-gray-7)"
          p={8}
          zIndex={10000}
          disabled={opened}
        >
          <VariantButton
            variant="light"
            leftSection={<IconVariantGradient />}
            rightSection={<IconChevronDown />}
            onClick={() => {
              setOpened((o) => !o);
            }}
            px={6}
            $opened={opened}
          >
            <Text truncate="end">{selectedVariantName}</Text>
          </VariantButton>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown p={0}>{children}</Popover.Dropdown>
    </Popover>
  );
};

export default VariantDropdownButton;
