import { LoginForm, PasswordResetForm, SignupForm } from '@userfront/toolkit/react';
import { Link } from 'react-router-dom';

import { TermsConditions } from './Auth.styles';
import AuthBase from './AuthBase';


export const Login = () => (
  <AuthBase
    headline="Sign in"
    socialSignInPrefix="Continue with "
    submitButtonText="Sign in"
  >
    <style>{`#userfront-password-rules { display: none; }`}</style>
    <LoginForm />
    <h3>
      <span>Don't have an account?</span>
    </h3>
    <Link to="/" style={{ fontSize: 16, fontWeight: 600}}>Sign up for free</Link>
  </AuthBase>
);

export const PasswordReset = () => (
  <AuthBase
    headline="Password Reset"
    subHeadline="Use your email to reset your password."
    submitButtonText="Continue"
  >
    <PasswordResetForm />
  </AuthBase>
);

export const SignUp = () => (
  <AuthBase
    headline="Sign up for free"
    subHeadline="Get started in minutes, no credit card required"
    socialSignInPrefix="Continue with "
    submitButtonText="Continue"
  >
    <SignupForm />
    <TermsConditions>
      By signing up, you agree to the
      {' '}<Link target="_blank" to="https://www.upflowy.com/privacy-policy">Privacy Policy </Link>,
      {' '}<Link target="_blank" to="https://www.upflowy.com/data-processing">Data Processing</Link> and
      {' '}<Link target="_blank" to="https://www.upflowy.com/terms-of-use">Terms of Service</Link>.
    </TermsConditions>
    <h3>
      <span>Already have an account?</span>
    </h3>
    <Link to="/login" style={{ fontSize: 16, fontWeight: 600}}>Sign in</Link>
  </AuthBase>
);
