import { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import PageLoader from './components/PageLoader';
import { useFeatureFlags } from './providers/FeatureFlagProvider';
import { isUserAuthenticated } from './services/UserServices';

const PrivateRoute = ({
  element,
  requireAuth = true,
  requireIntentSubscription = false,
  requireV2Subscription = false,
}: {
  element: ReactNode;
  requireAuth?: boolean;
  requireIntentSubscription?: boolean;
  requireV2Subscription?: boolean;
}) => {
  const { featureFlags, isLoadingSubscription, userAndWorkspaceInfo } = useFeatureFlags();
  const { hasActiveV2Subscription } = userAndWorkspaceInfo;
  const { hasV2Access } = featureFlags;

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await isUserAuthenticated();
      setIsAuthenticated(authStatus);
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null || isLoadingSubscription) {
    return <PageLoader />;
  }

  if (!requireAuth && !requireIntentSubscription && !requireV2Subscription) {
    return element; // No validation needed, allow access.
  }

  if (!isAuthenticated && requireAuth) {
    return <Navigate to="/login" />;
  }

  if ((!hasV2Access.enabled || !hasActiveV2Subscription) && requireV2Subscription) {
    return <Navigate to="/403" />;
  }

  return element;
};

export default PrivateRoute;
