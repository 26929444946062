import { Loader } from '@mantine/core';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { HideChat } from '~/screens/Studio/Studio.styles';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--m3-sys-light-surface);
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CustomLoader = styled(Loader)`
  margin-bottom: 10px;
`;

const MessageWrapper = styled.div`
  font-size: var(--m3-title-large-size);
`;

const PageLoader = ({ message }: { message?: ReactNode }) => {
  return (
    <Container>
      <HideChat />
      <LoaderWrapper>
        <CustomLoader size={50} color="#6931ed" />
        {message && <MessageWrapper>{message}</MessageWrapper>}
      </LoaderWrapper>
    </Container>
  );
};

export default PageLoader;
