import { Center, Stack, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BottomLeftImage from './components/BottomLeftImage';
import StepHeader from './components/StepHeader';

import Pricing from '../Pricing';

import { WebsiteForm } from '~/components/WebsiteForm/WebsiteForm';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { createWorkspace } from '~/services/WorkspaceServices';

const Website = () => {
  const navigate = useNavigate();
  const { isLoadingWorkspace, userAndWorkspaceInfo, updateUserAndWorkspace } = useFeatureFlags();
  const { hasIntentWorkspace, intentProperties, hasActiveIntentSubscription } =
    userAndWorkspaceInfo;
  const [openPricing, setOpenPricing] = useState(!hasActiveIntentSubscription);

  useEffect(() => {
    if (!isLoadingWorkspace && !hasIntentWorkspace) {
      createWorkspace({ product: 'INTENT', assignDefaultWorkspaces: true })
        .then((workspace) => {
          updateUserAndWorkspace({
            hasIntentWorkspace: true,
            intentActiveWorkspace: {
              ...workspace,
              name: 'my-intent-workspace',
              properties: {},
              type: 'INTENT',
            },
            intentProperties: [],
          });
        })
        .catch((err) => console.log('Error creating workspace', err));
    }

    if (intentProperties.length > 0) {
      navigate('/intent/dashboard');
    }
  }, [isLoadingWorkspace]);

  return (
    <>
      {openPricing && <Pricing withCloseButton={false} onClose={() => setOpenPricing(false)} />}
      <BottomLeftImage
        src="/assets/img-watcher-website.png"
        alt="Upflowy Watcher Onboarding Website Details"
      />
      <Center h="100%">
        <Stack maw={440} my="auto" py="2rem">
          <StepHeader size="xs" style={{ color: 'var(--color-grey)' }}>
            Step 1/3
          </StepHeader>
          <Title order={3}>What website do you want to supercharge? ⚡</Title>
          <WebsiteForm
            w="100%"
            hostnameOnly
            onSuccess={(url) => {
              navigate(`/onboarding-sdk-setup?url=${encodeURIComponent(url)}`);
            }}
          />
        </Stack>
      </Center>
    </>
  );
};

export default Website;
