import { Button, Flex, ScrollArea, Text, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconArrowBarUp, IconConfetti, IconCopy, IconExternalLink } from '@tabler/icons-react';
import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';

import BasePanel from '../BasePanel/BasePanel';

const { VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME = '*.testing.upflowyexperience.com' } =
  import.meta.env || {};

export interface PublishConfirmationPanelProps {
  opened: boolean;
  onClickToClose: () => void;
  setTab: Dispatch<SetStateAction<'settings' | 'confirmation'>>;
  formDomain: UseFormReturnType<
    {
      domain: string;
      error: boolean;
      cname: string;
    },
    (values: { domain: string; cname: string; error: boolean }) => {
      domain: string;
      error: boolean;
      cname: string;
    }
  >;
  formForNotContent: UseFormReturnType<
    {
      nanoId: string;
      workspaceId: string;
      urlSlug: string;
    },
    (values: { nanoId: string; workspaceId: string; urlSlug: string }) => {
      nanoId: string;
      workspaceId: string;
      urlSlug: string;
    }
  >;
}
const StyledText = styled(Text)`
  color: var(--m3-sys-light-primary);
  font-weight: 800;
  font-size: var(--m3-label-large-size);
`;

const StyledFlex = styled(Flex)`
  color: var(--m3-sys-light-primary);
  align-items: center;
  gap: 8px;
`;

const PublishConfirmationPanel = ({
  onClickToClose,
  formDomain,
  setTab,
  formForNotContent,
  opened,
}: PublishConfirmationPanelProps) => {
  const live_url = `https://${VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME.replace(
    '*',
    formForNotContent.values.workspaceId,
  )}/${formForNotContent.values.urlSlug}`;

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    notifications.show({
      color: 'green',
      message: 'Copied to clipboard!',
      autoClose: 3000,
    });
  };

  const handleCloseButton = () => {
    onClickToClose();
    //wait the change tab if not we can see the tab swap while closing
    setTimeout(() => {
      setTab('settings');
    }, 200);
  };

  useEffect(() => {
    if (!opened) {
      setTimeout(() => {
        setTab('settings');
      }, 200);
    }
  }, [opened]);
  return (
    <BasePanel
      opened={opened}
      onClickToClose={handleCloseButton}
      label="Publish"
      title="Go live"
      icon={<IconArrowBarUp />}
    >
      <ScrollArea style={{ height: 'calc(100vh - 240px)' }} type="never">
        <StyledFlex mt={20}>
          <IconConfetti size={26} />
          <StyledText>Congratulations! Your page is published!</StyledText>
        </StyledFlex>

        <Flex align={'center'} gap={'xs'} mt={32}>
          <Text size="sm" style={{ wordBreak: 'break-all' }}>
            {live_url}
          </Text>
          <IconCopy
            size={20}
            cursor={'pointer'}
            onClick={() => handleCopyToClipboard(live_url)}
            data-testid="copy-button"
          />
        </Flex>
        <Button variant="default" w="100%" mt={20} onClick={() => window.open(live_url, '_newtab')}>
          <IconExternalLink />
          <Text ml={8}>Live page</Text>
        </Button>

        {/* Display CNAME value if needed */}
        {!formDomain.values.error && (
          <>
            <Text size="sm" fw={500} mt={32}>
              Subdomain instructions
            </Text>

            <Text size="xs">
              Before your page will be displayed on your designed subdomain you must first set your{' '}
              <span style={{ fontWeight: 700, color: '#495057' }}>CNAME</span> record with your DNS
              provider.
            </Text>
            <br></br>
            <Text size="xs">
              <a href="#">Click here for instructions</a> on how to set your{' '}
              <span style={{ fontWeight: 700, color: '#495057' }}>CNAME</span> record, and you will
              find your <span style={{ fontWeight: 700, color: '#495057' }}>CNAME</span> value
              below.
            </Text>

            <TextInput
              label="CNAME name"
              placeholder="pages"
              readOnly
              rightSection={<IconCopy size={16} style={{ cursor: 'pointer' }} />}
              rightSectionProps={{
                onClick: () => {
                  handleCopyToClipboard(formDomain.values.domain.split('.')[0]);
                },
              }}
              size="sm"
              mt="md"
              value={formDomain.values.domain.split('.')[0]}
            />

            <TextInput
              label="CNAME value"
              size="sm"
              readOnly
              rightSection={
                <IconCopy size={16} style={{ cursor: 'pointer' }} data-testid="copy-button" />
              }
              rightSectionProps={{
                onClick: () => {
                  handleCopyToClipboard(formDomain.values.cname);
                },
              }}
              mt="md"
              {...formDomain.getInputProps('cname')}
            />
          </>
        )}
      </ScrollArea>
    </BasePanel>
  );
};

export default PublishConfirmationPanel;
