import styled from 'styled-components';

const ScreenContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: var(--m3-label-large-size);
  color: var(--m3-sys-light-on-surface-variant);
  font-family: var(--m3-label-large);
  background-color: #e9ecef;
`;

export default ScreenContainer;
