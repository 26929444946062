import { useDrag } from 'react-dnd';

import {
  addListenerForViewerDropzones,
  removeListenerForViewerDropzones,
} from '~/helpers/viewerInteractions/dropzoneEventHandling';

interface AddTextProps {
  dataItem?: {
    tagName?: string;
    textValue?: string;
  };
  label?: string;
}

const AddText = ({ dataItem = {}, label }: AddTextProps) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'add-page-element',
      item: dataItem,
      collect: (monitor) => {
        if (monitor.isDragging()) addListenerForViewerDropzones();
        return {
          opacity: monitor.isDragging() ? 0.5 : 1,
        };
      },
      end: removeListenerForViewerDropzones,
    }),
    [],
  );

  return (
    <div
      ref={dragRef}
      style={{
        width: 'fit-content',
        padding: '8px',
        border: '1px solid #000',
        marginRight: 8,
        opacity,
      }}
    >
      {label || dataItem.tagName?.toUpperCase()}
    </div>
  );
};

export const AddH1Text = () => (
  <AddText dataItem={{ tagName: 'h1', textValue: 'Add H1 heading here...' }} />
);
export const AddH2Text = () => (
  <AddText dataItem={{ tagName: 'h2', textValue: 'Add H2 heading here...' }} />
);
export const AddH3Text = () => (
  <AddText dataItem={{ tagName: 'h3', textValue: 'Add H3 heading here...' }} />
);
export const AddH4Text = () => (
  <AddText dataItem={{ tagName: 'h4', textValue: 'Add H4 heading here...' }} />
);
export const AddH5Text = () => (
  <AddText dataItem={{ tagName: 'h5', textValue: 'Add H5 heading here...' }} />
);
export const AddH6Text = () => (
  <AddText dataItem={{ tagName: 'h6', textValue: 'Add H6 heading here...' }} />
);
export const AddDivText = () => (
  <AddText
    label="Body"
    dataItem={{
      tagName: 'div',
      textValue:
        // eslint-disable-next-line max-len
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    }}
  />
);

export default AddText;
