import { useEffect } from 'react';

import { MessageBubble, StyledText } from './BriefPanel.styles';

export type ChatMessage = {
  message: string;
  role: 'Human' | 'System' | 'AI';
};

function Message({ message, role, onRemoveChat }: ChatMessage & { onRemoveChat: () => void }) {
  const isUser = role === 'Human';

  const printMessage =
    typeof message === 'string'
      ? message
      : 'There was an unexpected error. Please refresh the browser to restart the conversation.';

  useEffect(() => {
    if (typeof message !== 'string') onRemoveChat();
  }, []);

  return (
    <MessageBubble isUser={isUser}>
      <StyledText>{printMessage}</StyledText>
    </MessageBubble>
  );
}

export default Message;
