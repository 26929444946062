import ShortUniqueId from 'short-unique-id';

import { ElementClickedBoundRect } from '../../SetBoundForOverlayElements';
import attachImageFromCollection from '../attachImageFromCollection/attachImageFromCollection';
import validateFile from '../validateFile/validateFile';

import { PageJsonSnippetObj, ParsedLandingpageObject } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import imageUploadAndAttach from '~/screens/Studio/pageProcessing/imageUploadAndAttach/imageUploadAndAttach';

const { randomUUID } = new ShortUniqueId();

// If is files like images, upload the files first and then add the node
const addNodeIsFiles = async (
  node: PageJsonSnippetObj | { files: File[] },
  pageDetails?: Omit<ParsedLandingpageObject, 'content'>,
) => {
  if (Array.isArray(node.files)) {
    if (validateFile(node.files[0])) {
      const { workspaceId } = pageDetails || {};
      msg({ type: 'upload-to-collection', file: (node.files as File[])[0] });
      // The below uploads to assets folder via Asset Handling Service
      return await imageUploadAndAttach(workspaceId, (node.files as File[])[0]);
    } else {
      return;
    }
  } else {
    return node as PageJsonSnippetObj;
  }
};

const dropToAddSectionProcessing = async (
  itemType: string,
  node: PageJsonSnippetObj | { files: File[] },
  hoverBound: ElementClickedBoundRect,
  pageDetails?: Omit<ParsedLandingpageObject, 'content'>,
  isTop?: boolean,
) => {
  let addNode = await addNodeIsFiles(node, pageDetails);
  const { rootNodeHost, rootNodeHostBefore } = hoverBound.data;

  const processing: Record<string, () => void | Promise<void>> = {
    'add-from-collection'() {
      const { workspaceId } = pageDetails || {};
      const src = '@src' in node && typeof node['@src'] === 'string' ? node['@src'] : '/';

      if (workspaceId) {
        addNode = attachImageFromCollection(workspaceId, src);
      }

      processing.default();
    },

    'move-page-element'() {
      msg({
        type: 'move-element-in-new-section',
        messageData: {
          elementSelector: isTop ? rootNodeHostBefore : rootNodeHost,
        },
        selector: (addNode as PageJsonSnippetObj).selector as string,
      });
    },

    'move-page-section'() {
      if (hoverBound.data.rootNodeHost) {
        msg({
          type: 'move-page-section',
          selector: (addNode as PageJsonSnippetObj).selector as string,
          destination: (isTop ? rootNodeHostBefore : rootNodeHost) || '',
        });
      }
    },

    'add-page-section'() {
      msg({
        type: 'add-section',
        messageData: {
          elementSelector: isTop ? rootNodeHostBefore : rootNodeHost,
        },
        objectToBeAdded: addNode,
      });
    },

    default() {
      msg({
        type: 'add-section',
        messageData: {
          elementSelector: isTop ? rootNodeHostBefore : rootNodeHost,
        },
        containsToBeAdded: [
          {
            ...addNode,
            selector: `elem-${randomUUID(8)}`,
            '@slot': 'column-1',
          } as PageJsonSnippetObj,
        ],
      });
    },
  };

  if (addNode) (processing[itemType] || processing.default)();
};

export default dropToAddSectionProcessing;
