import {
  ActionIcon,
  Card,
  Flex,
  Image,
  LoadingOverlay,
  Menu,
  NavLink,
  rem,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowUpRight,
  IconCopy,
  IconDotsVertical,
  IconEdit,
  IconTrash,
  IconWriting,
} from '@tabler/icons-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CopyPageModal from './CopyPageModal';
import PageRenameModal from './PageRenameModal';

import IconVariantGradient from '../CustomIcons/IconVariantGradient';

import { ParsedLandingpageObject } from '~/global.types';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { deletePage, updatePage } from '~/services/PageServices';

const BackgroundCard = styled(Card)`
  height: 100%;
  position: absolute;
  width: 100%;

  &:nth-child(1) {
    transform: translateZ(-24px) translateY(-24px);
    background-color: #f4f4f4;
  }

  &:nth-child(2) {
    transform: translateZ(-16px) translateY(-10px);
    background-color: #e8e8e8;
  }

  &:nth-child(3) {
    transform: translateZ(-8px) translateY(3px);
    background-color: #dcdcdc;
  }
`;

type PageCardProps = {
  page: ParsedLandingpageObject;
  image?: string;
  cardImageOverride?: React.ReactElement;
  onDeleteSuccess: (pageId: string) => void;
  onCopySuccess: (pageId: string, name: string, definition: string) => void;
  onNameChange: (pageId: string, name: string) => void;
};

const PageCard = ({
  page,
  image,
  cardImageOverride,
  onDeleteSuccess,
  onCopySuccess,
  onNameChange,
  ...props
}: PageCardProps) => {
  const isPageSet = 'pageSetId' in page;
  const [loader, setLoader] = useState(false);
  const { userAndWorkspaceInfo } = useFeatureFlags();
  const { v2ActiveWorkspace } = userAndWorkspaceInfo;

  const workspaceId = v2ActiveWorkspace?.workspaceId ?? '';
  const domain = import.meta.env?.VITE_DOMAIN_NAME ?? '*.test.upflowyexperience.com';
  const { nanoId, name, definition, status } = page;

  const published_url = `https://${domain.replace('*', workspaceId)}/${definition}`;

  const [showEditNameModal, { open: openRenameModal, close: closeRenameModal }] =
    useDisclosure(false);
  const [showCopyPageModal, { open: openCopyModal, close: closeCopyModal }] = useDisclosure(false);
  const navigate = useNavigate();

  const handleNameUpdate = async (updatedName: string) => {
    await updatePage(workspaceId, nanoId, { name: updatedName });
    onNameChange(nanoId, updatedName);
    return true;
  };

  const handleClickDelete = () =>
    modals.openConfirmModal({
      title: 'Delete this page?',
      children: (
        <Text size="sm">
          You are about to delete <b>{name}</b>. This can't be undone.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: async () => {
        try {
          setLoader(true);
          await deletePage({ nanoId: nanoId ?? '' });
          onDeleteSuccess(nanoId);
          notifications.show({
            color: 'green',
            message: 'Page deleted',
            autoClose: 3000,
            icon: <IconCheck />,
          });
        } catch (error) {
          notifications.show({
            color: 'red',
            message: 'Unable to delete page!',
            autoClose: 3000,
          });
        } finally {
          setLoader(false);
        }
      },
    });

  return (
    <Stack
      style={{
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        perspective: '100px',
      }}
    >
      {isPageSet && (
        <>
          <BackgroundCard />
          <BackgroundCard />
          <BackgroundCard />
        </>
      )}
      <Card
        radius="md"
        {...props}
        onClick={() => navigate(`/${nanoId}/edit`)}
        style={[
          {
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: 'fit-content',
            marginTop: isPageSet ? '14px' : 0,
          },
        ]}
        shadow="sm"
        withBorder
        pb="0"
        pr="0"
      >
        <Card.Section
          style={{
            overflow: 'hidden',
            height: isPageSet ? '125px' : '140px',
          }}
          withBorder
        >
          {cardImageOverride}
          <LoadingOverlay visible={loader} />
          {!cardImageOverride && image && (
            <Image src={image} alt={name} fallbackSrc="/placeholder.png" />
          )}
        </Card.Section>

        <Flex justify="space-between" align="center" p="8 4 8 0">
          <Flex direction="column" gap="5px">
            <Text fw={600} size="sm" truncate maw={160}>
              {name}
            </Text>
            <Text size="10px" c="dimmed">
              {status}
            </Text>
          </Flex>
          <Flex justify="flex-end" align="center">
            {isPageSet && <IconVariantGradient width={26} height={25} />}
            <Menu withinPortal position="bottom-start" offset={2} withArrow zIndex={400}>
              <Menu.Target data-testid="menu-target">
                <ActionIcon
                  variant="transparent"
                  color="white"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <IconDotsVertical style={{ color: 'var(--color-black)' }} stroke={1.5} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Homepage</Menu.Label>
                <Menu.Divider />
                <Menu.Item
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(`/${nanoId}/edit`);
                  }}
                  leftSection={<IconEdit style={{ width: rem(16), height: rem(16) }} />}
                >
                  Edit Page
                </Menu.Item>
                <Menu.Item
                  onClick={(event) => {
                    event.stopPropagation();
                    openCopyModal();
                  }}
                  leftSection={<IconCopy style={{ width: rem(16), height: rem(16) }} />}
                  disabled={isPageSet}
                >
                  Make a copy
                </Menu.Item>
                <Menu.Item
                  onClick={(event) => {
                    event.stopPropagation();
                    openRenameModal();
                  }}
                  leftSection={<IconWriting style={{ width: rem(16), height: rem(16) }} />}
                >
                  Rename
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    href={published_url}
                    label="Visit live page"
                    leftSection={
                      <IconCircleArrowUpRight style={{ width: rem(16), height: rem(16) }} />
                    }
                    style={{
                      backgroundColor: 'transparent',
                      padding: 0,
                      textAlign: 'left',
                      justifyContent: 'flex-start',
                    }}
                    onClick={(event) => event.stopPropagation()}
                    target="_blank"
                  />
                </Menu.Item>
                <Menu.Item
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClickDelete();
                  }}
                  leftSection={<IconTrash style={{ width: rem(16), height: rem(16) }} />}
                >
                  Move to trash
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        {showEditNameModal && (
          <PageRenameModal
            name={name ?? 'Unnamed'}
            close={closeRenameModal}
            updatePageName={handleNameUpdate}
          />
        )}
        {showCopyPageModal && (
          <CopyPageModal
            page={{ nanoId, name: name ?? 'unnamed', slug: definition, workspaceId }}
            close={closeCopyModal}
            onCopySuccess={onCopySuccess}
          />
        )}
      </Card>
    </Stack>
  );
};

export default PageCard;
