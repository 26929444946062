import { AnalysisRecordPartial } from 'global.types';

const ContactCell = ({ contacts }: AnalysisRecordPartial) => {
  if (!contacts) return 'N/A';
  try {
    const parsedContacts = JSON.parse(contacts);
    return parsedContacts[parsedContacts.length-1].value;
  } catch (error) {
    return 'N/A';
  }
};

export default ContactCell;
