import { usePrevious } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { VariantGenerationState } from './types';
import updatePageContentWithVariant from './updatePageContentWithVariant';
import useVariantGenerationWebSocket, {
  VariantGenerationMessageCollection,
} from './useVariantGenerationWebSocket/useVariantGenerationWebSocket';
import variantReducer from './variantStateReducer/variantStateReducer';

import { LandingpageDetails, ViewerWindowObject } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useCommonWebSocket from '~/hooks/useCommonWebSocket/useCommonWebSocket';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { BlockOutput } from '~/messages.types';
import {
  createPageSet,
  createPageVariants,
  deletePageVariant,
  DeletePageVariantResponse,
  detectMarker,
  GetIdentifyVariantMarker,
  getPageSet,
  GetPageVariantData,
  getPageVariants,
  GetPageVariantsResponse,
  getVariant,
  regenerateVariants,
  updatePageSet,
} from '~/services/PageSetServices/PageSetServices';
import { getAuthedUser, getMessages } from '~/services/UserServices';

type PageSetContextData = GetPageVariantsResponse['data'];

export type PageSetVariantStateObj = {
  editedVariant?: string;
  generatedVariants?: Record<string, VariantGenerationState>;
  editedVariantNodes?: Record<
    string,
    | {
        status?: 'analyzing' | 'editing' | 'unsynced';
        selector?: string;
        textValue?: string;
        variantNodeId?: string;
      }
    | undefined
  >;
};

type PageSetContextType = null | {
  generated?: Record<string, boolean> | null;
  toBeGeneratedCount: number;
  isLoading?: boolean;
  pageSetId?: string;
  variantList: GetPageVariantData[];
  selectedVariant?: GetPageVariantData;
  variantState: PageSetVariantStateObj;
  getVariantView: (nanoId: string) => void;
  generateVariants: (variantName: string, variantKeywords: string[]) => void;
  addVariants: (variantKeywords: string[]) => void;
  regeneratePageVariant: (nanoId: string) => void;
  regenerateAllPageVariants: () => void;
  deleteVariant: (nanoId: string) => void;
  resetPageSetContext: () => void;
  markBluePrintEdited: (selector: string, outputData: BlockOutput, variantNodeId?: string) => void;
  clearBluePrintEdited: () => void;
};

const PageSetContext = createContext<PageSetContextType>(null);

// eslint-disable-next-line react-refresh/only-export-components
export const usePageSet = () => {
  const context = useContext(PageSetContext);
  if (!context) {
    throw new Error('usePageSet must be used within a PageSetProvider');
  }
  return context;
};

export const PageSetProvider = ({ children }: PropsWithChildren) => {
  const { send } = useCommonWebSocket<VariantGenerationMessageCollection>({
    filter: ({ data }) => data.includes('VARIANT_GENERATION'),
  });

  const {
    generated,
    toBeGeneratedCount,
    lastGenerationCompleteTimestamp,
    lastGenerationResult,
    subscribeVariantGenerationMessages,
    initVariantGeneration,
    abruptResetDueToRouteChange,
  } = useVariantGenerationWebSocket();

  const [isLoading, setIsLoading] = useState(true);
  const [variantList, setVariantList] = useState<PageSetContextData>([]);
  const [variantState, dispatch] = useReducer(variantReducer, {});
  const [selectedVariant, setSelectedVariant] = useState<GetPageVariantData>();
  const [pageDetails, setPageDetails] = useState<Partial<LandingpageDetails>>({});

  const prevSelectedVariant = usePrevious(selectedVariant);

  const { pageSetId, pageSetVariantStrategy, workspaceId, nanoId: pageNanoId } = pageDetails;

  const getVariantView = async (nanoId: string) => {
    setIsLoading(true);
    msg({ type: 'block-editor' });
    return await getVariant({ nanoId })
      .then((res) => {
        setSelectedVariant(res);
        return res;
      })
      .finally(() => {
        setIsLoading(false);
        msg({ type: 'unblock-editor' });
      });
  };

  const createVariantsPattern = async (pageSetNanoId: string, variantKeywords: string[]) => {
    const { data: newPageVariantList } = await createPageVariants({
      pageSetId: pageSetNanoId,
      variantKeywords,
    });

    const generatedVariants: Record<string, VariantGenerationState> = {};

    newPageVariantList.forEach((variant) => {
      generatedVariants[variant.nanoId] = 'GENERATING';
    });

    updatePageSet({
      nanoId: pageSetNanoId,
      state: {
        ...variantState,
        generatedVariants: {
          ...variantState.generatedVariants,
          ...generatedVariants,
        },
      },
    });

    await subscribeVariantGenerationMessages(newPageVariantList);
    setVariantList((prevList) => [...prevList, ...newPageVariantList]);
  };

  const addVariants = async (variantKeywords: string[]) => {
    if (!workspaceId || !pageNanoId || !pageSetId) return;
    initVariantGeneration(variantKeywords, variantState.generatedVariants);
    createVariantsPattern(pageSetId, variantKeywords);
  };

  const generateVariants = async (variantStrategy: string, variantKeywords: string[]) => {
    if (!workspaceId || !pageNanoId) return;
    setIsLoading(true);
    initVariantGeneration(variantKeywords, variantState.generatedVariants);
    const { data: pageSetData } = await createPageSet({
      workspaceId,
      pageNanoId,
      variantStrategy,
    });
    setPageDetails({
      ...pageDetails,
      pageSetId: pageSetData.nanoId,
      pageSetVariantStrategy: variantStrategy,
    });
    createVariantsPattern(pageSetData.nanoId, variantKeywords);
  };

  const regeneratePageVariant = async (nanoId: string) => {
    if (!workspaceId || !pageNanoId) return;
    setIsLoading(true);
    initVariantGeneration([nanoId], variantState.generatedVariants);

    await subscribeVariantGenerationMessages(
      variantList.filter((variant) => variant.nanoId === nanoId),
    );
    await regenerateVariants({ pageId: pageNanoId, pageVariantId: nanoId });
    setIsLoading(false);
  };

  const regenerateAllPageVariants = async () => {
    if (!workspaceId || !pageNanoId) return;
    setIsLoading(true);
    initVariantGeneration(
      variantList.map((variant) => variant.nanoId),
      variantState.generatedVariants,
    );

    await subscribeVariantGenerationMessages(variantList);
    await regenerateVariants({ pageId: pageNanoId, pageSetId });
    setIsLoading(false);
  };

  const deleteVariant = async (nanoId: string) => {
    if (!nanoId) return;
    const response: DeletePageVariantResponse = await deletePageVariant({ nanoId });

    if (response.message === 'Page variant deleted successfully!') {
      const isLastVariant = variantList.length === 1;
      const newVariantList = variantList.filter((variant) => variant.nanoId !== nanoId);
      setVariantList(newVariantList);

      if (isLastVariant) {
        setSelectedVariant(undefined);
      } else {
        if (selectedVariant?.nanoId === nanoId) {
          setSelectedVariant(newVariantList[0]);
          getVariantView(newVariantList[0]?.nanoId);
        }
      }
      notifications.show({
        color: 'green',
        message: 'Variant deleted successfully.',
        autoClose: 3000,
      });
    } else {
      notifications.show({
        color: 'red',
        message: 'Failed to delete the variant.',
        autoClose: 3000,
      });
    }
  };

  const detectMarkerAndUploadVariantState = () => {
    if (
      !pageNanoId ||
      !pageSetId ||
      !pageSetVariantStrategy ||
      !variantList ||
      !variantState?.editedVariantNodes
    ) {
      return;
    }

    const toDetect = Object.entries(variantState.editedVariantNodes)
      .filter(([_, data]) => data && data.status === 'editing')
      .map(([key, data]) => ({
        [key]: data?.textValue,
      }));

    if (toDetect.length === 0) return;

    // detectMarker supports bulk operation, but why I am still running a loop here?
    // This is because the current state of identifyVariantNode API is returning new
    // node id, and it can't be matched with the original id. Thus, I actually
    // have to run the loop and rely on JavaScript Closure to access the original node
    // id from the forEach callback, so that it matches the id and swap data correctly.
    toDetect.forEach((toDetectObj) => {
      const selector = Object.keys(toDetectObj)[0];

      dispatch({
        type: 'to-analyze-variant-marker',
        payload: { selector },
      });

      detectMarker({
        pageId: pageNanoId,
        pageSetId,
        variantStrategy: pageSetVariantStrategy,
        listVariants: variantList.map((variant) => variant.name),
        pageContent: toDetectObj,
      }).then((res: GetIdentifyVariantMarker) => {
        if (selectedVariant && res?.data) {
          dispatch({
            type: 'update-variant-marker',
            payload: {
              selector,
              outputData: res.data[0],
              pageSetId,
              selectedVariant,
              setSelectedVariant,
            },
          });
        }
      });
    });
  };

  const markBluePrintEdited = (
    selector: string,
    editedTextNode: BlockOutput,
    variantNodeId?: string,
  ) => {
    const { data, id } = editedTextNode;

    if (!data?.text || !id) return;

    dispatch({
      type: 'mark-blueprint-edited',
      payload: { selector, variantNodeId, outputData: editedTextNode },
    });
  };

  const clearBluePrintEdited = () => {
    dispatch({ type: 'reset-variant-state' });
  };

  const resetPageSetContext = () => {
    setIsLoading(true);
    setVariantList([]);
    setPageDetails({});
    setSelectedVariant(undefined);
    clearBluePrintEdited();
    abruptResetDueToRouteChange();
  };

  useViewerMessage(
    ({ data }) => {
      // 'first-fetch-page-data-completed' will determine whether this page is a page set or not
      // One cannot skip this process
      if (data.type === 'first-fetch-page-data-completed') {
        msg({ type: 'block-editor' });
        setPageDetails(data.pageDetails);
        const { pageSetId: pId } = data.pageDetails;

        if (pId) {
          // The state is recorded at page set level, so the frontend have to do this extra call
          let generatedVariantsState = variantState.generatedVariants || {};

          // We need to grab the variant "keyword" so that Manage Variant Panel'
          // can show. getPageSet in its current state only retrieves the list of variant ids
          Promise.all([getPageSet({ nanoId: pId }), getPageVariants({ pageSetId: pId })])
            .then(([pageSetResult, pageVariantsResult]) => {
              const { state } = pageSetResult;
              const variantsData = pageVariantsResult.data;

              if (state) {
                dispatch({ type: 'override-variant-state', payload: state });
                generatedVariantsState = state.generatedVariants || {};
              }

              setPageDetails({
                ...data.pageDetails,
                pageSetVariantStrategy: pageVariantsResult.variantStrategy,
              });
              setVariantList(variantsData);

              const selectedVariantId = state?.editedVariant || variantsData[0].nanoId;
              if (selectedVariantId) {
                getVariantView(selectedVariantId);
              } else {
                setIsLoading(false);
              }

              const variantIds = variantsData.map((variant) => variant.nanoId);

              const variantsGenerated: Record<
                string,
                { statusCode: VariantGenerationState; messageId: string }
              > = {};

              getMessages({ eventType: 'VARIANT_GENERATION' }).then(async (messages) => {
                const userData = await getAuthedUser();
                const currentUserId = userData?.userUuid;
                const messagesForTheVariants = messages?.data.filter((message) =>
                  variantIds.includes(message.objectId),
                );

                messagesForTheVariants.forEach((message) => {
                  variantsGenerated[message.objectId] = {
                    statusCode: message.statusCode as VariantGenerationState,
                    messageId: message.messageId,
                  };
                });
                console.log('Generated Variants State:', generatedVariantsState);
                console.log('Variants Generated:', variantsGenerated);
                // update state object
                if (Object.keys(variantsGenerated).length) {
                  Object.keys(variantsGenerated).forEach((nanoId) => {
                    generatedVariantsState[nanoId] = variantsGenerated[nanoId].statusCode;
                  });

                  const newState = {
                    ...variantState,
                    generatedVariants: generatedVariantsState,
                  };

                  updatePageSet({
                    nanoId: pId,
                    state: newState,
                  });

                  dispatch({ type: 'override-variant-state', payload: newState });

                  console.log('Updated Generated Variants State:', generatedVariantsState);
                }

                console.log('Acknowledge all messages:', messagesForTheVariants.length);
                // acknowledge all messages
                messagesForTheVariants.forEach((message) => {
                  send({
                    action: 'acknowledge',
                    userId: currentUserId,
                    messageId: message.messageId,
                  });
                });

                const variantsPending: string[] = [];
                const variantsProcessed: Record<string, boolean> = {};

                Object.keys(generatedVariantsState).forEach((v) => {
                  if (generatedVariantsState[v] === 'GENERATING') {
                    variantsPending.push(v);
                  } else {
                    variantsProcessed[v] = true;
                  }
                });

                console.log('Pending variants:', variantsPending.length);
                console.log('Variants Processed:', variantsProcessed);

                if (variantsPending.length) {
                  console.log('show modal to user that variants are still generating');
                  initVariantGeneration(
                    variantsPending,
                    variantState.generatedVariants,
                    variantsProcessed,
                  );
                }
              });
            })
            .catch((error) => {
              console.error('Failed to retrieve page variants:', error);
              notifications.show({
                color: 'red',
                message: 'Failed to retrieve page variants.',
                autoClose: 3000,
              });
              setIsLoading(false);
              msg({ type: 'unblock-editor' });
            });
        } else {
          setIsLoading(false);
          msg({ type: 'unblock-editor' });
        }
      }

      if (pageSetId && data.type === 'viewer-refreshed') {
        const viewer = document.querySelector(`iframe#${data.viewer}`);
        if (viewer && selectedVariant) {
          updatePageContentWithVariant(
            viewer as HTMLIFrameElement,
            selectedVariant.variantNodes,
            variantState,
          );
        }
      }

      if (pageSetId && data.type === 'reset-bound') {
        document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
          (
            (viewer as HTMLIFrameElement).contentWindow as ViewerWindowObject
          )?.revertCurrentEditorToUnit();
        });
      }

      // Full page reinit is triggered during Variant Generation Web Socket, it needs to preload the
      // first generated variant, only if is a new generation. Add more variant shouldn't trigger this.
      if (
        pageSetId &&
        data.type === 'full-page-reinit' &&
        variantList.length > 0 &&
        !selectedVariant
      ) {
        getVariantView(variantList[0].nanoId);
      }

      if (pageSetId && data.type === 'editing-performed' && data.outputData.blocks[0].data?.text) {
        markBluePrintEdited(data.fromSelector || '', data.outputData.blocks[0], data.variantNodeId);
      }

      if (pageSetId && data.type === 'updating-page-content') {
        detectMarkerAndUploadVariantState();
      }
    },
    [selectedVariant, variantList, variantState, pageSetId],
  );

  useEffect(() => {
    if (selectedVariant && prevSelectedVariant?.nanoId !== selectedVariant?.nanoId) {
      document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
        updatePageContentWithVariant(
          viewer as HTMLIFrameElement,
          selectedVariant.variantNodes,
          variantState,
        );
      });
    }
  }, [selectedVariant, variantState]);

  const postGenerationProcess = () => {
    if (pageSetId) {
      // This should trigger getting the latest page content, which includes
      // variantNodeIds. This is so that the updatePageContentWithVariant can do its job
      msg({ type: 'full-page-reinit' });
      clearBluePrintEdited();

      notifications.show({
        color: 'green',
        message: `Your pages have been generated`,
        autoClose: 3000,
      });

      const payload = { generatedVariants: lastGenerationResult };

      dispatch({ type: 'override-variant-state', payload });

      // Need to upload the state to PageSet object so it persist after browser refresh
      updatePageSet({
        nanoId: pageSetId,
        state: payload,
      });

      getVariantView((selectedVariant || variantList[0]).nanoId).then((res) => {
        msg({ type: 'reset-bound' });
        document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
          updatePageContentWithVariant(viewer as HTMLIFrameElement, res.variantNodes, {});
        });
      });
    }
  };

  useEffect(postGenerationProcess, [lastGenerationCompleteTimestamp]);

  return (
    <PageSetContext.Provider
      value={{
        generated,
        toBeGeneratedCount,
        isLoading,
        pageSetId,
        variantList,
        selectedVariant,
        variantState,
        getVariantView,
        generateVariants,
        addVariants,
        regeneratePageVariant,
        regenerateAllPageVariants,
        deleteVariant,
        resetPageSetContext,
        markBluePrintEdited,
        clearBluePrintEdited,
      }}
    >
      {children}
    </PageSetContext.Provider>
  );
};
