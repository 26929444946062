import { Box, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';

import { CircleIcon, StyledActionIcon } from '../../EditToolbar.styles';

import { ViewerData } from '~/global.types';
import {
  checkIsAssetsPathUrlInBackgroundImage,
  checkIsGradientAsBackgroundImage,
  checkIsUrlInBackgroundImage,
} from '~/helpers/cssVariableProcessing/cssVariableProcessing';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { BlockOutput } from '~/messages.types';
import { useStudioWorkspace } from '~/providers/WorkspaceProvider/WorkspaceProvider';

interface BackgroundColorToolProps {
  viewerData: Partial<ViewerData>;
}

export const BackgroundColorTool = ({ viewerData }: BackgroundColorToolProps) => {
  const [{ siteProvisioningUrl }] = useStudioWorkspace();
  const [value, setValue] = useState<string>();

  const setBackGroundValue = ({
    backgroundImage = '',
    backgroundColor,
  }: Partial<ViewerData['editorState'] | BlockOutput['data']> = {}) => {
    if (checkIsGradientAsBackgroundImage(backgroundImage)) {
      setValue(backgroundImage);
    } else if (checkIsUrlInBackgroundImage(backgroundImage)) {
      let imageValue = backgroundImage;

      if (checkIsAssetsPathUrlInBackgroundImage(backgroundImage)) {
        imageValue = imageValue.replace('assets/', siteProvisioningUrl + 'assets/');
      }

      setValue(imageValue + 'center / cover no-repeat');
    } else if (backgroundColor) {
      // Bear in mind that some editing will only send backgroundSize or backgroundRepeat
      // which makes all removes background image
      setValue(backgroundColor ?? '#ffffff00');
    }
  };

  useEffect(() => {
    setBackGroundValue(viewerData?.editorState);
  }, [viewerData]);

  useViewerMessage(
    ({ data }) => {
      if (data.type === 'editing-performed') {
        setBackGroundValue((data.outputData?.blocks[0] || {}).data);
      }
    },
    [siteProvisioningUrl],
  );

  const handleClickToSendMessage = () => {
    msg({
      type: 'open-toolbar-panel',
      openToolbarPanel: 'add-element',
      openToolbarSubPanel: 'background-setting',
    });
  };

  return (
    <Tooltip label="Background setting" color="Gray" withArrow zIndex={1001}>
      <StyledActionIcon
        aria-label="background-setting"
        variant="transparent"
        onClick={handleClickToSendMessage}
      >
        <CircleIcon color={'transparent'} size="2.5px">
          <Box w="100%" h="100%" bg={value} />
        </CircleIcon>
      </StyledActionIcon>
    </Tooltip>
  );
};
