import { Button, Center, rem, Stack, Title } from '@mantine/core';
import { IconConfetti } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import BottomLeftImage from './components/BottomLeftImage';
import StepHeader from './components/StepHeader';

import EmailExportSwitch from '~/components/EmailExport/EmailExportSwitch';
import SlackModal from '~/components/IntegrationModals/SlackModal';
import WebhookModal from '~/components/IntegrationModals/WebhookModal';
import PageLoader from '~/components/PageLoader';
import ServiceIntegration from '~/components/ServiceIntegration';
import { Connection } from '~/global.types';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { testWebhook } from '~/services/IntentAPI';
import { updateProperty } from '~/services/PropertyServices';
import { updateProfile } from '~/services/UserServices';

const DataExport = () => {
  const { isLoadingWorkspace, userAndWorkspaceInfo, updateUserAndWorkspace } = useFeatureFlags();
  const { intentActiveWorkspace, intentProperties } = userAndWorkspaceInfo;
  const activeProperty = intentProperties.length ? intentProperties[0] : null;
  const [userDefaults, setUserDefaults] = useState<boolean>(false);

  const [showWebhookModal, setShowWebhookModal] = useState<boolean>(false);
  const [showSlackModal, setShowSlackModal] = useState<boolean>(false);

  useEffect(() => {
    updateProfile(
      {
        enableEmailNotification: true,
      },
      true,
    ).finally(() => setUserDefaults(true));
  }, []);

  if (!userDefaults || isLoadingWorkspace) return <PageLoader />;

  const updatePropertyConfig = async (url: string, type: string) => {
    if (intentActiveWorkspace && activeProperty) {
      const config = {
        ...activeProperty?.config,
      };

      if (!config.connections) config.connections = [];

      // Auto deprecate the old WEBHOOK_URL until everyone has migrated
      const { WEBHOOK_URL } = activeProperty?.config || {};
      if (WEBHOOK_URL) {
        config.connections.push({ url: WEBHOOK_URL, type: 'webhook', status: 'connected' });
        delete config?.WEBHOOK_URL;
      }

      const findExisting = config.connections.find((item) => item.type === type);

      if (findExisting) {
        findExisting.url = url;
      } else {
        config.connections.push({ url, type, status: 'connected' });
      }

      await updateProperty({
        workspaceId: intentActiveWorkspace?.workspaceId,
        propertyId: activeProperty?.id,
        config,
      });

      const properties = intentActiveWorkspace?.properties ?? {};
      updateUserAndWorkspace({
        intentProperties: Object.values({
          ...properties,
          [activeProperty.id]: {
            ...activeProperty,
            config,
          },
        }),
      });
    }

    setShowWebhookModal(false);
    setShowSlackModal(false);
  };

  const getConnectionStatus = (type: string): Connection | undefined => {
    // The old WEBHOOK_URL to be deprecated
    const oldWebHookUrl = activeProperty?.config?.WEBHOOK_URL;
    if (oldWebHookUrl && type === 'webhook') {
      return {
        type: 'webhook',
        status: 'connected',
        url: oldWebHookUrl,
      };
    }

    // Official connections facility starts here
    const allConnections = activeProperty?.config?.connections || [];
    return allConnections.find((connection) => connection.type === type);
  };

  return (
    <>
      <BottomLeftImage
        src="/assets/img-watcher-export.png"
        alt="Upflowy Watcher Onboarding Export Details"
        style={{ zIndex: -1 }}
      />

      <WebhookModal
        initialWebhookURL={getConnectionStatus('webhook')?.url}
        opened={showWebhookModal}
        onSaveConnection={async (webhookUrl) => {
          await updatePropertyConfig(webhookUrl, 'webhook');
        }}
        onClose={() => setShowWebhookModal(false)}
        onTestWebhook={async (webhookUrl: string) =>
          testWebhook({
            propertyId: activeProperty?.id ?? '',
            workspaceId: intentActiveWorkspace?.workspaceId ?? '',
            webhookUrl,
          })
        }
      />
      <SlackModal
        initialWebhookURL={getConnectionStatus('slack')?.url}
        opened={showSlackModal}
        onSaveConnection={async (webhookUrl) => await updatePropertyConfig(webhookUrl, 'slack')}
        onClose={() => setShowSlackModal(false)}
        onTestWebhook={async (webhookUrl: string) =>
          testWebhook({
            propertyId: activeProperty?.id ?? '',
            workspaceId: intentActiveWorkspace?.workspaceId ?? '',
            webhookUrl,
          })
        }
      />

      <Center h="100vh">
        <Stack maw={440} my="auto" py="2rem">
          <StepHeader size="xs" style={{ color: 'var(--color-grey)' }}>
            Step 3/3
          </StepHeader>
          <Title order={4}>Get instant insights on new leads?</Title>
          <EmailExportSwitch
            initialChecked={true}
            onChange={async (value) => {
              await updateProfile({
                enableEmailNotification: value,
              });
            }}
          />
          <ServiceIntegration
            title="Slack"
            description="Send summary to a channel"
            image={
              <img
                src="/assets/thumbnail-slack.png"
                style={{ width: rem(48), height: rem(48) }}
                alt="Slack"
              />
            }
            onConnect={() => setShowSlackModal(true)}
          />

          <Title order={4}>Automatically add insights to your CRM</Title>
          <ServiceIntegration
            title="Connect to any CRM"
            description="using Zapier & Webhooks"
            image={
              <img
                src="/assets/thumbnail-zapier.png"
                style={{ width: rem(48), height: rem(48) }}
                alt="Zapier"
              />
            }
            onConnect={() => setShowWebhookModal(true)}
          />
          <Button
            fullWidth
            bg="var(--m3-sys-light-primary)"
            onClick={() => {
              // can't use navigate here due to data not refreshing
              window.location.href = '/intent/dashboard';
            }}
            rightSection={<IconConfetti size={16} />}
          >
            Done
          </Button>
        </Stack>
      </Center>
    </>
  );
};

export default DataExport;
