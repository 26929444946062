import { Button, Card, Group, Stack, Text, Title } from '@mantine/core';

type ServiceIntegrationProps = {
  image: React.ReactNode;
  title: string;
  description?: string | React.ReactNode;
  buttonText?: string;
  children?: React.ReactNode;
  onConnect?: () => void;
};

const ServiceIntegration = ({
  image,
  title,
  description,
  children,
  buttonText = 'Connect',
  onConnect,
}: ServiceIntegrationProps) => {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Group justify="space-between">
        <Group>
          {image}
          <Stack style={{ width: '200px' }} gap="xs">
            <Title order={5}>{title}</Title>
            {description && (
              <Text size="sm" style={{ color: 'var(--color-grey)' }} component="div">
                {description}
              </Text>
            )}
            {children}
          </Stack>
        </Group>
        <Button
          variant="default"
          data-disabled={onConnect ? false : true}
          onClick={() => {
            if (onConnect) {
              onConnect();
            }
          }}
        >
          {buttonText}
        </Button>
      </Group>
    </Card>
  );
};

export default ServiceIntegration;
