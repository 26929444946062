import { Center, Loader, ScrollArea, Title } from '@mantine/core';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import styled from 'styled-components';

import { MediaCollectionItem, useMediaCollection } from '~/providers/MediaCollectionProvider';

interface ImageMasonryCardProps {
  item: MediaCollectionItem;
  workspaceId: string;
  onItemClick?: (item: MediaCollectionItem, workspaceId: string) => void;
}

interface ImageMasonryProps {
  itemComponentOverride?: ({ item }: ImageMasonryCardProps) => React.ReactElement;
  onItemClick?: (item: MediaCollectionItem, workspaceId: string) => void;
}

const breakpointColumnsObj = { 350: 1, 750: 2, 900: 3 };

const NO_IMAGE_FALLBACK =
  'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg';

const CardElement = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  background: #f1f3f5;
  border-radius: 4px;
  cursor: pointer;
  box-shadow:
    0px 1px 3px 0px #0000000d,
    0px 1px 2px 0px #0000001a;

  & > * {
    margin: 0;
  }

  &:hover {
    box-shadow:
      0px 1px 3px 0px #0000000d,
      0px 4px 3.799999952316284px 0px #0000001a;
  }

  &:hover svg {
    color: #868e96;
  }

  img {
    height: auto;
    width: 100%;
    pointer-events: none;
    user-select: none;
  }
`;

export const ImageMasonryCard = ({
  item,
  workspaceId,
  onItemClick = () => {},
}: ImageMasonryCardProps) => {
  return (
    <CardElement onClick={() => onItemClick(item, workspaceId)}>
      <img
        src={item['@src'] ?? NO_IMAGE_FALLBACK}
        alt={item['@alt'] ?? 'Placeholder'}
        aria-hidden
      />
    </CardElement>
  );
};

const ImageMasonry = ({
  itemComponentOverride: Item = ImageMasonryCard,
  onItemClick = () => () => {},
}: ImageMasonryProps) => {
  const [[loading], [workspaceId], [imageList]] = useMediaCollection();

  return (
    <ScrollArea style={{ height: 'calc(100vh - 260px)' }} type="never">
      {imageList.length > 0 ? (
        <ResponsiveMasonry columnsCountBreakPoints={breakpointColumnsObj}>
          <Masonry gutter="8px" columnsCount={3}>
            {imageList.map((item, index) => (
              <Item
                item={item}
                workspaceId={workspaceId}
                key={`${item['@alt']}-${index}`}
                onItemClick={onItemClick}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      ) : (
        <Center h="calc(100vh - 260px)">
          {loading ? (
            <Loader role="status" />
          ) : (
            <Title order={6}>There are no files uploaded to collection.</Title>
          )}
        </Center>
      )}
    </ScrollArea>
  );
};

export default ImageMasonry;
