import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';

import PageLoader from '~/components/PageLoader';
import { getStripeCustomerPortal } from '~/services/PaymentServices';

const Accounts = () => {
  useEffect(() => {
    const handleSubscription = async () => {
      try {
        const response = await getStripeCustomerPortal();
        window.location.href = response.portalUrl;
      } catch (error) {
        console.error('Unable to connect stripe!');
        notifications.show({
          color: 'red',
          title: 'Unable to connect payment gateway!',
          message: 'Our team has been notified. Contact us if the problem persists.',
          autoClose: 3000,
        });
        window.location.href = '/intent/dashboard';
      }
    };
    handleSubscription();
  }, []);
  return <PageLoader />;
};
export default Accounts;
