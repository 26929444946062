import { BrandToken } from '../StylePanel/styleProcessing';

export type StylesList = Record<string, string>[][];

const getValue = (brandTokens: BrandToken[], key: string) => {
  const foundToken = brandTokens.find((token) => token[key] !== undefined && token[key] !== '');
  return foundToken ? foundToken[key] : undefined;
};

const isValueDefined = (brandTokens: BrandToken[], key: string) => {
  return brandTokens.some((token) => token[key] !== undefined && token[key] !== '');
};

const getColorsDefined = (brandTokens: BrandToken[]): string[][] => {
  const colorKeys = ['color-primary-100', 'color-secondary-100', 'color-tertiary-100'];
  const defaultColors = ['#5f33a6', '#000000', '#808080'];

  const colorList = colorKeys.map((key, index) => {
    const value = getValue(brandTokens, key);
    return value ? [`var(--${key})`, value] : [defaultColors[index], defaultColors[index]];
  });

  //two values each time: one for DataItem (ccss variable for style in JSON page data)
  //and one for the panel (real value)

  return colorList;
};

type RGB = { r: number; g: number; b: number };

const hexToRgb = (hex: string): RGB => {
  const sanitizedHex = hex.replace(/^#/, '');
  const bigint = parseInt(sanitizedHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
};

const getBrightness = ({ r, g, b }: RGB): number => {
  return 0.213 * r + 0.715 * g + 0.072 * b;
};

const isLightOrDark = (hexColor: string): 'light' | 'dark' => {
  const rgb = hexToRgb(hexColor);
  const brightness = getBrightness(rgb);
  return brightness > 128 ? 'light' : 'dark';
};

export const generateStylesList = (brandTokens: BrandToken[]): StylesList[] => {
  const colors = getColorsDefined(brandTokens);
  const buttonRadius = isValueDefined(brandTokens, 'button-radius')
    ? ['var(--button-radius)', getValue(brandTokens, 'button-radius')!]
    : ['4px', '4px'];
  const fontFamily = isValueDefined(brandTokens, 'font-family-headings')
    ? ['var(--font-family-headings)', getValue(brandTokens, 'font-family-headings')!]
    : ['Arial', 'Arial'];
  const darkText = isValueDefined(brandTokens, 'color-text-lightbg')
    ? ['var(--color-text-lightbg)', getValue(brandTokens, 'color-text-lightbg')!]
    : ['#000000', '#000000'];
  const lightText = isValueDefined(brandTokens, 'color-text-darkbg')
    ? ['var(--color-text-darkbg)', getValue(brandTokens, 'color-text-darkbg')!]
    : ['#ffffff', '#ffffff'];

  const baseStyle: Record<string, string>[] = [
    {
      'line-height': '1.5',
      cursor: 'pointer',
      padding: '10px 24px',
      'font-weight': '400',
      'font-size': '22px',
      'text-align': 'center',
      width: 'fit-content',
      'border-radius': buttonRadius[0],
      'font-family': fontFamily[0],
    },
    {
      lineHeight: '1.5',
      cursor: 'pointer',
      padding: '8px 20px',
      fontWeight: '400',
      fontSize: '16px',
      textAlign: 'center',
      width: 'fit-content',
      borderRadius: buttonRadius[1],
      fontFamily: fontFamily[1],
    },
  ];

  const stylesVariationListForOneColor = (color: string[]): Record<string, string>[][] => {
    const filledButtonStyle = [
      {
        ...baseStyle[0],
        'background-color': color[0],
        border: `1px solid ${color[0]}`,
        color: isLightOrDark(color[1]) === 'light' ? darkText[0] : lightText[0],
      },
      {
        ...baseStyle[1],
        backgroundColor: color[1],
        border: `1px solid ${color[1]}`,
        color: isLightOrDark(color[1]) === 'light' ? darkText[1] : lightText[1],
      },
    ];

    const outlinedButtonStyle = [
      {
        ...baseStyle[0],
        'background-color': 'white',
        border: `1px solid ${color[0]}`,
        color: color[0],
      },
      {
        ...baseStyle[1],
        backgroundColor: 'white',
        border: `1px solid ${color[1]}`,
        color: color[1],
      },
    ];

    const transparentButtonStyle = [
      {
        ...baseStyle[0],
        'background-color': 'transparent',
        color: color[0],
        'text-decoration': 'underline',
      },
      {
        ...baseStyle[1],
        backgroundColor: 'transparent',
        color: color[1],
        textDecoration: 'underline',
      },
    ];

    return [filledButtonStyle, outlinedButtonStyle, transparentButtonStyle];
  };

  const stylesVariationList: StylesList[] = colors.map((color) =>
    stylesVariationListForOneColor(color),
  );

  return stylesVariationList;
};
