import {
  Button,
  Center,
  Code,
  Container,
  Group,
  Skeleton,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

import StepHeader from './components/StepHeader';

import Alert from '../../components/Alert/Alert';
import CodeWithCopyButton from '../../components/CodeWithCopyButton/CodeWithCopyButton';
import WebsiteInstall from '../../components/WebsiteInstall/WebsiteInstall';
import { UserAndWorkspaceInfo } from '../../global.types';
import useQuery from '../../hooks/useQuery';

import composeSDKCode from '~/helpers/composeSDKCode/composeSDKCode';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { createProperty } from '~/services/PropertyServices';

const ERROR_MESSAGE =
  'There was an error when creating your site. Please reload the page or contact us.';

const StyledContainer = styled(Container)`
  max-width: 1148px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`;

const Centering = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SDKSetup = () => {
  const query = useQuery();
  const { userAndWorkspaceInfo } = useFeatureFlags();
  const { intentActiveWorkspace, intentProperties } = userAndWorkspaceInfo as UserAndWorkspaceInfo;

  const headTag = '<head>  </head>';

  const [code, setCode] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const url = query.get('url');

  useEffect(() => {
    async function fetchProperties() {
      if (!url) return;
      try {
        const workspaceId = intentActiveWorkspace?.workspaceId;
        const propertyId = intentProperties.length ? intentProperties[0].id : null;
        if (!propertyId && workspaceId) {
          const property = await createProperty({
            name: url,
            workspaceId,
          });
          setCode(composeSDKCode(property.propertyId));
        } else {
          setCode(composeSDKCode(propertyId ?? ''));
        }
      } catch (err) {
        setError((err as Error).message);
      }
    }
    fetchProperties();
  }, []);

  if (!code)
    return (
      <Center h="100%">
        {error && <Alert title="Error" message={ERROR_MESSAGE} onClose={() => setError(null)} />}
        <Stack style={{ width: '1148px' }}>
          <Skeleton height={17} width="40" />
          <Group justify="space-between" style={{ width: 1148 }}>
            <Skeleton height={36} width="300" />
            <Skeleton height={36} width="200" style={{ marginRight: 10 }} />
          </Group>

          <Skeleton height={21} width="70%" />
          <Skeleton height={76} width="99.3%" />

          <Skeleton height={27} width="30%" />
          <Group>
            <Skeleton height={130} width="24%" />
            <Skeleton height={130} width="24%" />
            <Skeleton height={130} width="23.5%" />
            <Skeleton height={130} width="23.5%" />
          </Group>
        </Stack>
      </Center>
    );

  return (
    <StyledContainer>
      <Centering>
        <Stack style={{ width: '100%' }} my="auto" py="2rem">
          <StepHeader size="xs" style={{ color: 'var(--color-grey)' }}>
            Step 2/3
          </StepHeader>
          <Group justify="space-between">
            <Title order={3}>🦸 Let’s add superpowers to your website </Title>
            <Button
              style={{ width: 200, textAlign: 'center' }}
              bg="var(--m3-sys-light-primary)"
              onClick={() => {
                // can't use navigate here due to data not refreshing
                window.location.href = '/onboarding-integration';
              }}
            >
              Next
            </Button>
          </Group>

          <Text size="sm" style={{ color: 'var(--color-grey)' }}>
            This tiny piece of code needs to make its way to your website, between the{' '}
            <Code>{headTag}</Code> tags.
          </Text>
          <CodeWithCopyButton code={code} />
          <Title order={4}>How would you like to install?</Title>
          <WebsiteInstall code={code} />
        </Stack>
      </Centering>
    </StyledContainer>
  );
};

export default SDKSetup;
