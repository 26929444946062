import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useState } from 'react';

import NameModal from './NameModal';

const PageRenameModal = ({
  name,
  updatePageName,
  close,
}: {
  name: string;
  updatePageName: (name: string) => Promise<boolean>;
  close: () => void;
}) => {
  const [updating, setUpdating] = useState(false);
  const handleSubmit = async (values: { name: string }) => {
    if (name === values.name) return;
    try {
      setUpdating(true);
      const isUpdated = await updatePageName(values.name);
      if (isUpdated) {
        notifications.show({
          color: 'green',
          title: 'Page renamed successfully!',
          message: '',
          autoClose: 3000,
          icon: <IconCheck />,
        });
        close();
      } else {
        notifications.show({
          color: 'red',
          title: 'Unable to Rename page!',
          message: 'URL already exist for other page. Please try another name.',
          autoClose: 3000,
        });
      }
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'Unable to Rename page',
        autoClose: 3000,
      });
      close();
    } finally {
      setUpdating(false);
    }
  };
  return (
    <NameModal
      title="Rename page"
      name={name}
      handleSubmit={handleSubmit}
      close={close}
      updating={updating}
    />
  );
};
export default PageRenameModal;
