import { VariantGenerationState } from '../types';

type GenerationState = {
  generated: Record<string, boolean> | null;
  lastGenerationCompleteTimestamp?: number;
  lastGenerationResult?: Record<string, VariantGenerationState>;
  toBeGeneratedCount: number;
};

type GenerationAction =
  | {
      type: 'init-generation';
      payload: {
        pageVariantList: string[];
        generatedVariants?: Record<string, boolean>;
        lastGenerationResult?: Record<string, VariantGenerationState>;
      };
    }
  | { type: 'mark-generated'; objectId: string; statusCode: VariantGenerationState }
  | { type: 'end-generation' }
  | { type: 'subscribe-message'; payload: { objectId: string; subscriptionId: string } }
  | { type: 'reset-state' };

const variantGenerationWebSocketReducer = (
  state: GenerationState,
  action: GenerationAction,
): GenerationState => {
  switch (action.type) {
    case 'init-generation': {
      const initialGenerated = action.payload.generatedVariants || {};
      return {
        generated: { ...initialGenerated },
        toBeGeneratedCount: action.payload.pageVariantList.length,
        lastGenerationResult: action.payload.lastGenerationResult || {},
        lastGenerationCompleteTimestamp: state.lastGenerationCompleteTimestamp,
      };
    }
    case 'mark-generated': {
      const updatedGenerated = { ...state.generated, [action.objectId]: true };

      return {
        ...state,
        generated: updatedGenerated,
        lastGenerationResult: {
          ...state.lastGenerationResult,
          [action.objectId]: action.statusCode,
        },
      };
    }
    case 'end-generation': {
      return {
        ...state,
        generated: null,
        lastGenerationCompleteTimestamp: Date.now(),
      };
    }
    case 'subscribe-message': {
      return {
        ...state,
        generated: { ...state.generated, [action.payload.objectId]: false },
      };
    }
    case 'reset-state': {
      return {
        generated: null,
        toBeGeneratedCount: 0,
      };
    }
    default:
      return state;
  }
};

export default variantGenerationWebSocketReducer;
