import ShortUniqueId from 'short-unique-id';

import { CreatePageResponse, DatabaseEntity } from '~/../api.types';
import ApiClient from '~/helpers/ApiClient';
import { callGraphQl } from '~/helpers/graphQl/graphQl';

const { randomUUID } = new ShortUniqueId();

const createEntity = async (entity: DatabaseEntity) => {
  await callGraphQl({
    query: `
      mutation CreateEntity($entity: CreateEntityInput!) {
        createEntity(input: $entity) {
          type
          nanoId
          definition
          status
          content
          createdDate
          updatedDate
          userId
          relatedEntityId
        }
      }
    `,
    variables: { entity },
  });
};

export const createBlankPage = async (userUuid: string, workspaceId: string) => {
  const date = Date.now().toString();
  const nanoId = randomUUID(16);
  const page = {
    type: 'PAGE',
    nanoId,
    definition: `a-new-page-${randomUUID(8)}`,
    status: 'INGESTING',
    content: '{"object": "page", "contains": [], "theme": []}',
    createdDate: date,
    updatedDate: date,
    userId: userUuid,
    relatedEntityId: workspaceId,
  };

  const log = {
    type: 'ENTITY_LOG',
    nanoId: randomUUID(16),
    definition: 'CREATED',
    createdDate: date,
    updatedDate: date,
    userId: userUuid,
    relatedEntityId: nanoId,
  };
  await Promise.all([createEntity(page), createEntity(log)]);
  return page.nanoId;
};

export const callBrandAnalysisService = async (
  workspaceId: string,
  nanoId: string,
  url: string,
) => {
  const client = new ApiClient(import.meta.env?.VITE_AWS_API_BASE_URL);
  await client.post('/call-brand-analysis-service', {
    workspaceId,
    pageId: nanoId,
    url,
  });
};

export const createPage = async (workspaceId: string, url: string) => {
  const client = new ApiClient(import.meta.env?.VITE_AWS_API_BASE_URL);
  const response = await client.post<CreatePageResponse>('/create-page', {
    workspaceId,
    url,
  });
  return response.data;
};
