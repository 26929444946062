import { html } from '@codemirror/lang-html';
import { ActionIcon, Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCode } from '@tabler/icons-react';
import CodeMirror from '@uiw/react-codemirror';
import React, { useEffect, useState } from 'react';

import { OverlayHeading } from '../PannableOverlays.styles';
import {
  OverlayBoundRect,
  pinPointerFromElementBoundInViewer,
  transformPointerToElementBound,
} from '../SetBoundForOverlayElements';

import { PageJsonSnippetObj } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';

const viewerPin: OverlayBoundRect<null> = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  data: null,
};

const setPins = (): OverlayBoundRect<null>[] => {
  // iFrameResizer would rotate the id
  const viewers = document.querySelectorAll<HTMLIFrameElement>('iframe[id^="iFrameResizer"]');
  if (viewers.length === 0) return [];

  const domRects = [...viewers].reduce((arr: OverlayBoundRect<null>[], curr: HTMLIFrameElement) => {
    const pinId = `heading-${curr.id}`;
    pinPointerFromElementBoundInViewer(pinId, curr.id, viewerPin);
    transformPointerToElementBound(pinId, null, (result: OverlayBoundRect<null>) => {
      arr.push(result);
    });

    return arr;
  }, []);

  return domRects;
};

const transformToHtml = (tags: { [key: string]: string }[]): string =>
  tags
    .map((tag) => {
      const attributes = Object.entries(tag)
        .filter(([key]) => key !== 'tagName' && key !== 'textValue')
        .map(([key, value]) => ` ${key.replace('@', '')}="${value}"`)
        .join('');
      const child = tag.textValue ? tag.textValue : '';

      if (['link', 'meta'].includes(tag.tagName)) return `<${tag.tagName}${attributes} />`;
      return `<${tag.tagName}${attributes}>${child}</${tag.tagName}>`;
    })
    .join('\n');

const htmlTransformToJson = (htmlString: string): PageJsonSnippetObj[] =>
  Array.from(
    new DOMParser().parseFromString(htmlString.replace(/\n/g, ''), 'text/html').head.children,
  ).map((element) => ({
    tagName: element.tagName.toLowerCase(),
    ...Array.from(element.attributes).reduce(
      (acc, attr) => {
        acc['@' + attr.name] = attr.value;
        return acc;
      },
      {} as { [key: string]: string },
    ),
    textValue: element.textContent?.trim(),
  }));

interface ViewerHeadOverlayProps {
  show: boolean;
  enablePerformanceMode?: boolean;
  allowDevMode: { enabled: boolean };
}

const ViewerHeadOverlay: React.FC<ViewerHeadOverlayProps> = ({
  show,
  enablePerformanceMode,
  allowDevMode,
}) => {
  const [codeMirrorInput, setCodeMirrorInput] = useState('');
  const [opened, { open, close }] = useDisclosure(false);
  const [bounds, setBounds] = useState<OverlayBoundRect<null>[]>([viewerPin, viewerPin, viewerPin]);

  useEffect(() => {
    msg({ type: 'trigger-broadcast-page-data' });
  }, [opened]);

  useViewerMessage(({ data }) => {
    if (data.type === 'broadcast-page-data') {
      setCodeMirrorInput(transformToHtml(JSON.parse(data.pageData || '{}').head || []));
    }

    if (data.type === 'update-bound') {
      setBounds(setPins());
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setBounds(setPins()), 300);
  }, []);

  useEffect(() => {
    setBounds(setPins());
  }, [show]);

  const handleClickToSaveHeadContains = () => {
    const htmlToData = htmlTransformToJson(codeMirrorInput);
    msg({ type: 'head-edited', head: htmlToData });
    notifications.show({
      color: 'blue',
      title: 'Success',
      message: `Saving document head data`,
      autoClose: 1200,
    });
  };

  return (
    <>
      <OverlayHeading
        onClick={() => (allowDevMode.enabled ? open() : null)}
        style={{
          left: bounds[0]?.x || -99,
          top: (bounds[0]?.y || -99) - 24,
          cursor: allowDevMode.enabled ? 'pointer' : 'default',
        }}
      >
        Desktop
        {allowDevMode.enabled && (
          <ActionIcon size="21" variant="transparent" color="#888">
            <IconCode />
          </ActionIcon>
        )}
      </OverlayHeading>

      {!enablePerformanceMode && (
        <>
          <OverlayHeading
            onClick={() => (allowDevMode.enabled ? open() : null)}
            style={{
              left: bounds[1]?.x || -99,
              top: (bounds[1]?.y || -99) - 24,
              cursor: allowDevMode ? 'pointer' : 'default',
            }}
          >
            Tablet
            {allowDevMode.enabled && (
              <ActionIcon size="21" variant="transparent" color="#888">
                <IconCode />
              </ActionIcon>
            )}
          </OverlayHeading>
          <OverlayHeading
            onClick={() => (allowDevMode.enabled ? open() : null)}
            style={{
              left: bounds[2]?.x || -99,
              top: (bounds[2]?.y || -99) - 24,
              cursor: allowDevMode.enabled ? 'pointer' : 'default',
            }}
          >
            Mobile
            {allowDevMode.enabled && (
              <ActionIcon size="21" variant="transparent" color="#888">
                <IconCode />
              </ActionIcon>
            )}
          </OverlayHeading>
        </>
      )}

      <Modal
        opened={opened}
        onClose={close}
        title={<b>Edit HTML head</b>}
        size="70%"
        zIndex={99999}
      >
        <CodeMirror
          extensions={[html()]}
          value={codeMirrorInput}
          height="70vh"
          style={{ border: '1px solid #888' }}
          onChange={(input) => setCodeMirrorInput(input)}
        />
        <br />
        <Button onClick={handleClickToSaveHeadContains}>Save</Button>
      </Modal>
    </>
  );
};

export default ViewerHeadOverlay;
