import { Stack } from '@mantine/core';
import styled from 'styled-components';

const StyledStack = styled(Stack)`
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
`;

export default StyledStack;
