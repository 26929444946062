import { useState } from 'react';

import { initialBound } from '../PannableOverlays';
import {
  ElementClickedBoundRect,
  pinPointerFromElementBoundInViewer,
  transformPointerToElementBound,
} from '../SetBoundForOverlayElements';

import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';

const useDefineDropBound = () => {
  const [hoverBound, setHoverBound] = useState<ElementClickedBoundRect>(initialBound);
  const [decorBound, setDecorBound] = useState<ElementClickedBoundRect>(initialBound);

  useViewerMessage(
    ({ data }) => {
      if (data?.type === 'hover-dropzone') {
        pinPointerFromElementBoundInViewer('select', data.viewer, data.rect);
        transformPointerToElementBound('select', data, setHoverBound);

        if (data.containerRect) {
          pinPointerFromElementBoundInViewer('select-decor', data.viewer, data.containerRect);
          transformPointerToElementBound('select-decor', data, setDecorBound);
        } else {
          setDecorBound(initialBound);
        }
      }

      if (data?.type === 'hover-dropzone-end') {
        setHoverBound(initialBound);
        setDecorBound(initialBound);
      }
    },
    [hoverBound],
  );

  return {
    hoverBound,
    decorBound,
    resetBound() {
      setHoverBound(initialBound);
    },
  };
};

export default useDefineDropBound;
