import { notifications } from '@mantine/notifications';

import { IMAGE_SIZE_LIMIT, SUPPORTED_IMAGE_TYPES } from '~/constants';

const supportedTypeMessage = SUPPORTED_IMAGE_TYPES.join(', ')
  .replaceAll('image/', '')
  .replace(/, ([^, ]*)$/, ' and $1')
  .replace('+xml', '');

const notifyUnsupportedMediaType = () => {
  notifications.show({
    color: 'red',
    title: 'Unsupported media type.',
    message: `The supported media types are ${supportedTypeMessage}`,
    autoClose: 3000,
  });
};

const notifyInvalidSize = () => {
  notifications.show({
    color: 'red',
    message: 'The image file is too large. Please try a smaller image.',
    autoClose: 3000,
  });
};

const validateFile = (file?: Record<string, unknown> | File) => {
  if (!file) return false;

  const isValidType =
    typeof file?.type === 'string' ? SUPPORTED_IMAGE_TYPES.includes(file.type ?? '') : false;

  const isValidSize = file instanceof File ? file.size <= IMAGE_SIZE_LIMIT : false;

  if (!isValidType) {
    notifyUnsupportedMediaType();
  }

  if (!isValidSize) {
    notifyInvalidSize();
  }

  return isValidType && isValidSize;
};

export default validateFile;
