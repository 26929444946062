import { useEffect, useReducer } from 'react';

import variantGenerationWebSocketReducer from './variantGenerationWebSocketReducer';

import { VariantGenerationState } from '../types';

import useCommonWebSocket from '~/hooks/useCommonWebSocket/useCommonWebSocket';
import { GetPageVariantData } from '~/services/PageSetServices/PageSetServices';
import { getAuthedUser } from '~/services/UserServices';

export type VariantGenerationProgress = {
  total: number;
  generateSuccess: number;
};

export type VariantGenerationMessage = {
  Subscribe: {
    eventType: 'VARIANT_GENERATION';
    subscriptionId: string;
    objectId: string;
  };

  Success: {
    eventType: 'VARIANT_GENERATION';
    statusCode: VariantGenerationState;
    messageId: string;
    objectId: string;
  };
};

export type VariantGenerationMessageCollection =
  VariantGenerationMessage[keyof VariantGenerationMessage];

const subscriptions: Record<string, VariantGenerationMessage['Subscribe']> = {};

const useVariantGenerationWebSocket = () => {
  const { receive, send } = useCommonWebSocket<VariantGenerationMessageCollection>({
    filter: ({ data }) => data.includes('VARIANT_GENERATION'),
  });
  const [state, dispatch] = useReducer(variantGenerationWebSocketReducer, {
    generated: null,
    toBeGeneratedCount: 0,
  });

  const initVariantGeneration = (
    pageVariantList: string[],
    lastGenerationResult?: Record<string, VariantGenerationState>,
    generatedVariants?: Record<string, boolean>,
  ) => {
    dispatch({
      type: 'init-generation',
      payload: { pageVariantList, generatedVariants, lastGenerationResult },
    });
  };

  const subscribeVariantGenerationMessages = async (pageVariantList: GetPageVariantData[]) => {
    const userData = await getAuthedUser();

    if (userData) {
      pageVariantList.forEach(({ nanoId }) => {
        send({
          userId: userData.userUuid,
          objectId: nanoId,
          action: 'subscribe',
          eventType: 'VARIANT_GENERATION',
        });
      });
    }
  };

  const abruptResetDueToRouteChange = () => {
    Object.values(subscriptions).forEach(({ subscriptionId, objectId }) => {
      send({
        action: 'unsubscribe',
        subscriptionId,
      });
      delete subscriptions[objectId];
    });

    dispatch({ type: 'reset-state' });
  };

  const handleReceiveMessage = () => {
    if (receive) {
      if ('statusCode' in receive && ['SUCCESS', 'FAILURE'].includes(receive.statusCode)) {
        dispatch({
          type: 'mark-generated',
          objectId: receive.objectId,
          statusCode: receive.statusCode,
        });

        if (subscriptions[receive.objectId]) {
          send({
            action: 'unsubscribe',
            subscriptionId: subscriptions[receive.objectId].subscriptionId,
          });
          delete subscriptions[receive.objectId];
        }
      }

      if ('subscriptionId' in receive) {
        subscriptions[receive.objectId] = receive;
        dispatch({
          type: 'subscribe-message',
          payload: { objectId: receive.objectId, subscriptionId: receive.subscriptionId },
        });
      }
    }
  };

  const handleEndGeneration = () => {
    if (state.generated) {
      const generatedValues = Object.values(state.generated);
      const allGenerated = generatedValues.every((val) => val === true);

      if (generatedValues.length > 0 && allGenerated) {
        // Add a buffer so that people can actually see the last variant been accumulated
        setTimeout(() => {
          dispatch({ type: 'end-generation' });
        }, 1500);
      }
    }
  };

  useEffect(handleReceiveMessage, [receive]);
  useEffect(handleEndGeneration, [state.generated]);

  return {
    generated: state.generated,
    lastGenerationCompleteTimestamp: state.lastGenerationCompleteTimestamp,
    lastGenerationResult: state.lastGenerationResult,
    toBeGeneratedCount: state.toBeGeneratedCount,
    initVariantGeneration,
    subscribeVariantGenerationMessages,
    abruptResetDueToRouteChange,
  };
};

export default useVariantGenerationWebSocket;
