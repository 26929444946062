import { Loader, Pagination, Table } from '@mantine/core';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import FieldSearchHeader from './FieldSearchHeader';
import { FilterParams } from './types';

import { UsersWithWorkspaceApiResult, UserWithWorkspace } from '~/global.types';
import { getAllUsersWithWorkspace } from '~/services/UserServices';

const StyledTableWrapper = styled.div`
  background-color: white;
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

const StyledPaginationWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end; /* Aligns pagination to the right */
`;

const StyledTableHeader = styled.th`
  padding: 10px;
  text-align: center;
`;

const StyledTableCell = styled.td`
  text-align: center;
  padding: 10px;
`;

const AdminDashboard: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UserWithWorkspace[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async (filterParams: FilterParams) => {
    try {
      setLoading(true);
      setData([]);
      setError(null);
      const response: UsersWithWorkspaceApiResult = await getAllUsersWithWorkspace(filterParams);
      setTotalPages(response.totalPages);
      setData(response.users);
      setError(null);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({ page: currentPage });
  }, [currentPage]);

  const handlePaginationChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <StyledTableWrapper>
        <Table>
          <thead>
            <tr style={{ backgroundColor: '#f0f0f0' }}>
              <StyledTableHeader>ID</StyledTableHeader>
              <StyledTableHeader>Name</StyledTableHeader>
              <StyledTableHeader>
                Email{' '}
                <FieldSearchHeader
                  name="email"
                  handleSearch={(email) => {
                    setCurrentPage(1);
                    fetchData({ page: 1, email });
                  }}
                />
              </StyledTableHeader>
              <StyledTableHeader>WorkspaceId</StyledTableHeader>
              <StyledTableHeader>
                TrackingId{' '}
                <FieldSearchHeader
                  name="trackingId"
                  handleSearch={(propertyId) => {
                    setCurrentPage(1);
                    fetchData({ page: 1, propertyId });
                  }}
                />
              </StyledTableHeader>
              <StyledTableHeader>Webhooks</StyledTableHeader>
              <StyledTableHeader>Created At</StyledTableHeader>
              <StyledTableHeader>Updated At</StyledTableHeader>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <StyledTableCell colSpan={8}>
                  <Loader style={{ marginTop: 100 }} />
                </StyledTableCell>
              </tr>
            )}
            {!loading && data.length === 0 && !error ? (
              <tr>
                <StyledTableCell colSpan={8}>No records found</StyledTableCell>
              </tr>
            ) : error ? (
              <tr>
                <StyledTableCell colSpan={8}>{error}</StyledTableCell>
              </tr>
            ) : (
              data.map((user, index) => {
                const workspace = user.workspaces.length ? user.workspaces[0] : null;
                const propertyIds = workspace ? Object.keys(workspace.properties).join(', ') : null;
                const webhooks = workspace
                  ? Object.values(workspace.properties).map(
                      (property) => property?.config?.connections?.map((connect) => connect.url),
                    )
                  : null;
                return (
                  <Fragment key={user.userId}>
                    <tr style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
                      <StyledTableCell>{user.userId}</StyledTableCell>
                      <StyledTableCell>{user.name}</StyledTableCell>
                      <StyledTableCell>{user.email}</StyledTableCell>
                      <StyledTableCell>{user.workspaceIds.join(', ')}</StyledTableCell>
                      <StyledTableCell>{propertyIds ?? '-'}</StyledTableCell>
                      <StyledTableCell style={{ wordBreak: 'break-word' }}>
                        {webhooks ? webhooks.join(', ') : '-'}
                      </StyledTableCell>
                      <StyledTableCell>{new Date(user.createdAt).toLocaleString()}</StyledTableCell>
                      <StyledTableCell>{new Date(user.updatedAt).toLocaleString()}</StyledTableCell>
                    </tr>
                  </Fragment>
                );
              })
            )}
          </tbody>
        </Table>
      </StyledTableWrapper>

      <StyledPaginationWrapper>
        <Pagination
          total={totalPages}
          value={currentPage}
          onChange={handlePaginationChange}
          size="md"
        />
      </StyledPaginationWrapper>
    </div>
  );
};

export default AdminDashboard;
