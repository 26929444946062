import { Menu } from '@mantine/core';
import {
  IconH1,
  IconH2,
  IconH3,
  IconH4,
  IconH5,
  IconH6,
  IconHeading,
  IconLetterP,
} from '@tabler/icons-react';

import { StyledActionIcon } from '../../EditToolbar.styles';

type TagNameToolProps = {
  handleClickToEmitEvent: (key: string, value: string) => void;
};

const iconProps = { size: 25, stroke: 1.5 };
const tagNames = [
  {
    icon: <IconLetterP {...iconProps} />,
    value: 'div',
  },
  {
    icon: <IconH1 {...iconProps} />,
    value: 'h1',
  },
  {
    icon: <IconH2 {...iconProps} />,
    value: 'h2',
  },
  {
    icon: <IconH3 {...iconProps} />,
    value: 'h3',
  },
  {
    icon: <IconH4 {...iconProps} />,
    value: 'h4',
  },
  {
    icon: <IconH5 {...iconProps} />,
    value: 'h5',
  },
  {
    icon: <IconH6 {...iconProps} />,
    value: 'h6',
  },
];

const TagNameTool = ({ handleClickToEmitEvent }: TagNameToolProps) => {
  return (
    <Menu aria-label="tag-name-tool" position="bottom" zIndex={1001}>
      <Menu.Target>
        <StyledActionIcon variant="transparent">
          <IconHeading size={20} stroke={1.5} />
        </StyledActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Text type</Menu.Label>
        {tagNames.map((tagName) => (
          <Menu.Item
            aria-label={`Set tag name to ${tagName.value}`}
            key={tagName.value}
            onClick={() => handleClickToEmitEvent('tagName', tagName.value)}
          >
            {tagName.icon}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default TagNameTool;
