import { Button, ButtonProps, MantineStyleProps } from '@mantine/core';
import { DOMAttributes } from 'react';
import styled from 'styled-components';

export const VariantButton = styled(Button)<
  ButtonProps &
    MantineStyleProps &
    DOMAttributes<HTMLButtonElement> & {
      $opened: boolean;
    }
>`
  background-color: ${(props) => (props.$opened ? '#F2EAFF' : 'white')};
  color: ${(props) => (props.$opened || props.loading ? 'upflowy-purple' : 'black')};
  border-radius: 4px;
  min-width: 72px;
  max-width: 200px;
  height: 40px;
  padding: 0 6px;

  &:hover {
    background-color: ${(props) =>
      !props.$opened && !props.loading ? 'var(--mantine-color-gray-1)' : undefined};
  }
`;
