import { useEffect } from 'react';

const toggleTrigger = (e: KeyboardEvent, key: string) => {
  return key === 'meta' ? e.metaKey : e.code === key;
};

const useKeyToToggle = (
  key: string,
  setStateCallback: (newValue: boolean) => void,
  update: React.DependencyList = [],
) => {
  const keyDownHandler = (e: KeyboardEvent) => toggleTrigger(e, key) && setStateCallback(true);
  const keyUpHandler = () => setStateCallback(false);

  useEffect(() => {
    document.body.addEventListener('keydown', keyDownHandler);
    document.body.addEventListener('keyup', keyUpHandler);

    return () => {
      document.body.removeEventListener('keydown', keyDownHandler);
      document.body.removeEventListener('keyup', keyUpHandler);
    };
  }, update);

  return;
};

export default useKeyToToggle;
