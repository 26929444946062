import { useEffect, useState } from 'react';

import useKeyToToggle from '~/hooks/useKeyToToggle/useKeyToToggle';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import useWheelToPanMessage from '~/hooks/useWheelToPanMessage/useWheelToPanMessage';
import { StudioMessageCollection } from '~/messages.types';

/**
 * Studio Editor relating to pan and zoom interaction is colocated here
 */
const usePanZoom = () => {
  const [holdMetaKey, setHoldMetaKey] = useState(false);
  const [isHideOverlay, setIsHideOverlay] = useState(false);

  // CMD + T to open new tab must be treated as no longer holding meta key, so that it won't get stuck
  useEffect(() => {
    window.onblur = () => {
      setHoldMetaKey(false);
    };
  }, []);

  // Hold down the CMD/CTRL key will enable default functions from react-pinch-pan-zoom
  // useKeyToToggle('meta', setHoldMetaKey);
  useKeyToToggle(
    'meta',
    (isHold: boolean) => {
      setHoldMetaKey(isHold);
      // This is to handle CMD + C/V/A to not break the overlay pattern.
      // Also, we only want to apply if the overlay is hidden otherwise users
      // will have trouble doing copying pasting
      if (isHideOverlay) setIsHideOverlay(isHold);
    },
    [isHideOverlay],
  );

  // But until the react-pinch-pan-zoom is interacting, we then hide the pannable overlays,
  // so that it won't disrupt the CMD/CTRL features - e.g. CMD+V, CMD+A, CMD+C
  useViewerMessage(({ data }: MessageEvent<StudioMessageCollection>) => {
    data.type === 'show-overlay'
      ? setIsHideOverlay(false)
      : data.type === 'hide-overlay' && setIsHideOverlay(true);
  }, []);

  // Need to listen to "Wheel" event from mouse-wheel or trackpad, to toggle show/hide pannable overlays
  useWheelToPanMessage(setIsHideOverlay);

  document.body.style.cursor = isHideOverlay ? 'grab' : 'default';

  return {
    holdMetaKey,
    setHoldMetaKey,
    isHideOverlay,
    setIsHideOverlay,
  };
};

export default usePanZoom;
