import { Button, Center, Image, Stack, Text, Title } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';

import TopLeftLogo from '../components/TopLeftLogo';

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <Center h="100%">
      <Link to="/dashboard">
        <TopLeftLogo src="/upflowy_with_gradient.svg" alt="Upflowy" />
      </Link>

      <Stack align="center" w="calc(100vw - 2rem)" maw={320}>
        <Image radius="md" src="/assets/welcome.jpg" />
        <Text fw={500} c="var(--upflowy-purple-9)">
          WELCOME TO UPFLOWY
        </Text>
        <Title order={3} ta="center">
          Lets grow your online business!
        </Title>
        <Text ta="center" c="var(--mantine-color-gray-7)">
          We’ll ask you a few questions to create your personalised web experiences. Are you ready?
        </Text>
        <Button
          fullWidth
          bg="var(--m3-sys-light-primary)"
          onClick={() => navigate('/onboarding-ingest-website')}
        >
          Let's go!
        </Button>
      </Stack>
    </Center>
  );
};
export default Welcome;
