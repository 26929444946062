import ShortUniqueId from 'short-unique-id';

import { PageJsonSnippetObj } from '~/global.types';
import { StudioMessage } from '~/messages.types';

const { randomUUID } = new ShortUniqueId();

function replaceSelectors(obj: PageJsonSnippetObj, isFirstLevel: boolean = true) {
  for (const key in obj) {
    if (key === 'selector') {
      obj[key] = isFirstLevel ? `layout-${randomUUID(8)}` : `elem-${randomUUID(8)}`;
    } else if (typeof obj[key] === 'object') {
      replaceSelectors(obj[key] as PageJsonSnippetObj, false);
    }
  }

  return obj;
}

function addSection(
  obj: PageJsonSnippetObj,
  { messageData, containsToBeAdded, objectToBeAdded }: StudioMessage['AddSection'],
) {
  const updatedObject = JSON.parse(JSON.stringify(obj));

  const { contains } = updatedObject;

  const sectionLocation = contains.findIndex(
    (item: PageJsonSnippetObj) => item.selector === messageData.elementSelector,
  );
  if (!sectionLocation)
    contains.findIndex((item: PageJsonSnippetObj) => item.category === messageData.id);

  contains.splice(
    sectionLocation + 1,
    0,
    objectToBeAdded
      ? replaceSelectors(objectToBeAdded)
      : {
          contains: containsToBeAdded,
          selector: `layout-${randomUUID(8)}`,
          layoutName: 'column-layout',
          style: {
            padding: '48px 0',
          },
        },
  );

  return updatedObject;
}

export default addSection;
