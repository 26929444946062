import { ActionIcon, Divider, Flex, Tabs, Text, Textarea, TextInput } from '@mantine/core';
import { useDebounceCallback } from '@mantine/hooks';
import { IconCode, IconPlus, IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import BasePanel from '../BasePanel/BasePanel';

import { ViewerData } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';

interface StyleEntry {
  key: string;
  value: string | undefined;
}

interface StyleInspectorPanelProps {
  opened: boolean;
  onClickToClose?: () => void;
}

const cssStringify = (styleJson: Record<string, string>): string =>
  Object.entries(styleJson).reduce((acc: string, [key, value]: [string, string]) => {
    return acc + `${key}: ${value};\n`;
  }, '');

const cssStringToObj = (value: string): Record<string, string> => {
  const obj: Record<string, string> = {};

  value.replace(/([^:;]+)\s*:\s*([^;]+)/g, (_, key, val) => {
    obj[key.trim()] = val.trim();
    return '';
  });

  return obj;
};

const StyleInspectorPanel = ({ opened, onClickToClose }: StyleInspectorPanelProps) => {
  const [viewerData, setViewerData] = useState<Partial<ViewerData>>({});
  const [styles, setStyles] = useState<StyleEntry[]>([]);
  const [displaySelectionText, setDisplaySelectionText] = useState('');
  const [newEntryName, setNewEntryName] = useState('');
  const {
    elementSelector,
    rootNodeHost,
    tagName,
    columnLayoutInnerComponentType = 'unit',
  } = viewerData || {};

  useViewerMessage(({ data }) => {
    if (data.type === 'get-selected-page-data') {
      setViewerData(data.messageData.data);
    }

    if (data.type === 'receive-selected-page-data' && data.result) {
      const styleEntries = Object.entries(data.result).reduce<StyleEntry[]>(
        (acc, [key, value]) =>
          key.startsWith('style')
            ? [...acc, { key, value: cssStringify(value as Record<string, string>) }]
            : acc,
        [],
      );

      if (!styleEntries.find((obj) => obj.key === 'style'))
        styleEntries.push({ key: 'style', value: '' });

      setStyles(styleEntries.sort((a, b) => a.key.localeCompare(b.key)));
    }
  }, []);

  useEffect(() => {
    setDisplaySelectionText(
      `${tagName} (${columnLayoutInnerComponentType}): ${elementSelector || rootNodeHost}`,
    );
  }, [styles]);

  const debounceAutoSave = useDebounceCallback(() => {
    const stylesWithObjValues = styles.map(({ key, value }) => ({
      key,
      value: value && cssStringToObj(value),
    }));
    const stylesObj = Object.fromEntries(stylesWithObjValues.map(({ key, value }) => [key, value]));
    if (elementSelector || rootNodeHost) {
      msg({
        type: 'replace-styles',
        stylesOveride: stylesObj,
        id: elementSelector || rootNodeHost,
      });
    }
  }, 700);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = event.currentTarget;
    const newStyle = styles.map((entry) => (entry.key === name ? { ...entry, value } : entry));
    setStyles(newStyle);
    debounceAutoSave();
  };

  const handleAddEntry = () => {
    if (newEntryName.trim() !== '') {
      setStyles([...styles, { key: `style_${newEntryName}`, value: '' }]);
      setNewEntryName('');
    }
  };

  const handleDeleteEntry = (key: string) => {
    const updatedStyles = [...styles];
    const deleteIndex = updatedStyles.findIndex((entry) => entry.key === key);
    updatedStyles[deleteIndex].value = undefined;
    setStyles(updatedStyles);
    debounceAutoSave();
  };

  return (
    <BasePanel
      opened={opened}
      onClickToClose={onClickToClose}
      label="Image"
      title="CSS Inspector (Dev)"
      icon={<IconCode />}
    >
      <Text size="sm" px={16} mt={0} pb={16}>
        {displaySelectionText}
      </Text>
      <Divider />
      <Tabs
        orientation="vertical"
        defaultValue={
          styles.find(({ key }) => key.includes(columnLayoutInnerComponentType))?.key ||
          (['unit', 'section'].includes(columnLayoutInnerComponentType) ? 'style' : '--')
        }
        mih="360"
        key={displaySelectionText}
      >
        <Tabs.List>
          {styles.map(({ key }) => (
            <Tabs.Tab key={`tab-${key}`} value={key} p={8} maw={200}>
              <Text
                size="xs"
                style={{
                  wordBreak: 'break-all',
                  whiteSpace: 'normal',
                }}
              >
                {key}{' '}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {styles.map(({ key, value }) => (
          <Tabs.Panel key={`panel-${key}`} value={key} p={8}>
            <ActionIcon onClick={() => handleDeleteEntry(key)} size="xs" color="red">
              <IconTrash />
            </ActionIcon>
            <br />
            <Textarea
              autoFocus
              size="xs"
              miw="100"
              h="100%"
              autosize
              defaultValue={value}
              name={key}
              onChange={handleChange}
            />
          </Tabs.Panel>
        ))}
        <Tabs.Panel value={'--'} p={8}>
          <Text size="xs">Please add a style sheet for this {columnLayoutInnerComponentType}</Text>
        </Tabs.Panel>
      </Tabs>
      <Divider />
      <Flex bg="#fff" p={8} gap={8}>
        <ActionIcon onClick={handleAddEntry} aria-label="Add stylesheet">
          <IconPlus />
        </ActionIcon>
        <TextInput
          placeholder="e.g. for_.column-1, for_.container:hover, max900 etc"
          size="xs"
          value={newEntryName}
          onChange={(e) => setNewEntryName(e.target.value)}
          w="100%"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              document.querySelector<HTMLButtonElement>('[aria-label="Add stylesheet"]')?.click();
            }
          }}
        />
      </Flex>
    </BasePanel>
  );
};

export default StyleInspectorPanel;
