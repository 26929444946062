import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import validator from 'validator';

import { copyPage, doesPageSlugExist } from '~/services/PageServices';

interface PageProps {
  nanoId: string;
  name: string;
  slug: string;
  workspaceId: string;
}

const CopyPageModal = ({
  page,
  onCopySuccess,
  close,
}: {
  page: PageProps;
  onCopySuccess: (pageId: string, name: string, definition: string) => void;
  close: () => void;
}) => {
  const [updating, setUpdating] = useState(false);
  const [slugError, setSlugError] = useState<string | null>(null);
  const { nanoId, name, slug, workspaceId } = page;

  const form = useForm({
    validateInputOnChange: true,
    initialValues: { name: `${name} (copy)`, slug: `${slug}-copy` },
    validate: {
      name: (value) => (value.length > 0 ? null : 'Name is required'),
    },
  });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Add delay to allow the modal to open and render the input
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const validSlug = async (slugValue: string) => {
    if (!slugValue) {
      setSlugError('Slug is required');
      return false;
    }
    if (!validator.isSlug(slugValue)) {
      setSlugError('Is not a valid slug');
      return false;
    }

    if (slugValue && validator.isSlug(slugValue)) {
      const response = await doesPageSlugExist({ workspaceId, slug: slugValue });
      if (response && response.exists) {
        setSlugError('Slug already exists');
        return false;
      }
    }
    setSlugError(null);
    return true;
  };

  const handleSubmit = async (values: { name: string; slug: string }) => {
    try {
      setUpdating(true);

      const isValidSlug = await validSlug(values.slug);
      if (isValidSlug) {
        const { data } = await copyPage({ nanoId, name: values.name, slug: values.slug });
        onCopySuccess(data.nanoId, data.name, data.definition);
        notifications.show({
          color: 'green',
          title: 'Page successfully duplicated',
          message: '',
          autoClose: 3000,
          icon: <IconCheck />,
        });
        close();
      }
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'Unable to copy page',
        autoClose: 3000,
      });
      console.log(error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Modal
      opened
      onClose={close}
      title="Copy page in account"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          data-testid="page-slug"
          label="Enter a new page slug:"
          ref={inputRef}
          {...form.getInputProps('slug')}
          onBlur={(e) => validSlug(e.target.value)}
          error={slugError}
          required
        />

        <TextInput label="Enter a new page name:" {...form.getInputProps('name')} required />

        <Group mt="md" justify="flex-end">
          <Button
            aria-label="Close Modal"
            onClick={close}
            variant="default"
            type="button"
            role="button"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            loading={updating}
            disabled={!form.isValid() || !!slugError}
            role="button"
          >
            Ok
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
export default CopyPageModal;
