import { Button, Divider, Group, Text } from '@mantine/core';
import { useState } from 'react';

import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

interface DeleteVariantProps {
  onClose: () => void;
  selectedVariantToDelete: { name: string; nanoId: string } | null;
}

const DeleteVariant = ({ onClose, selectedVariantToDelete }: DeleteVariantProps) => {
  const { deleteVariant } = usePageSet();
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    if (!selectedVariantToDelete) return;
    setDeleting(true);
    try {
      await deleteVariant(selectedVariantToDelete.nanoId);
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
      <Text size="sm" pb="20px">
        Are you sure you want to delete {selectedVariantToDelete?.name} from your Variants? This
        action cannot be undone, and all associated data will be permanently removed.
      </Text>
      <Divider my="sm" />
      <Group mt="md" justify="flex-start">
        <Button onClick={onClose} variant="default" disabled={deleting}>
          Cancel
        </Button>
        <Button onClick={handleDelete} color="#C92A2A" loading={deleting}>
          Delete
        </Button>
      </Group>
    </>
  );
};

export default DeleteVariant;
