import styled from 'styled-components';

const ProductLogo = styled.img.attrs(() => ({
  alt: 'Upflowy logo',
  src: '/logolight-upflowyicon1.svg',
}))`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
`;

export default ProductLogo;
