import { Dispatch, SetStateAction } from 'react';

import { ViewerData } from '~/global.types';

export interface OverlayBoundRect<T> {
  x: number;
  y: number;
  width: number;
  height: number;
  data: T;
}
export interface ElementClickedBoundRect extends OverlayBoundRect<Partial<ViewerData>> {}

export const pinPointerFromElementBoundInViewer = (
  type = 'unknown',
  viewerId = 'unknown',
  { x = 0, y = 0, width = 0, height = 0 } = {},
) => {
  document.querySelector(`#${type}-box-coord1`)?.remove();
  document.querySelector(`#${type}-box-coord2`)?.remove();

  document
    .querySelector(`#${viewerId}`)
    ?.parentElement?.insertAdjacentHTML(
      'beforeend',
      `<div id="${type}-box-coord1" style="position:absolute;left:${x}px;top:${y}px;"></div>`,
    );
  document
    .querySelector(`#${viewerId}`)
    ?.parentElement?.insertAdjacentHTML(
      'beforeend',
      `<div id="${type}-box-coord2" style="position:absolute;left:${x + width}px;top:${
        y + height
      }px;"></div>`,
    );
};

export const transformPointerToElementBound = <T>(
  type: string,
  data: T,
  setStateFunction:
    | Dispatch<SetStateAction<OverlayBoundRect<T>>>
    | { (result: OverlayBoundRect<T>): OverlayBoundRect<T> | void },
) => {
  const topLeftBound = document.querySelector(`#${type}-box-coord1`)?.getBoundingClientRect();
  const bottomRightBound = document.querySelector(`#${type}-box-coord2`)?.getBoundingClientRect();

  const returned = setStateFunction({
    x: topLeftBound?.x || 0,
    y: topLeftBound?.y || 0,
    width: (bottomRightBound?.x || 0) - (topLeftBound?.x || 0),
    height: (bottomRightBound?.y || 0) - (topLeftBound?.y || 0),
    data,
  });

  return returned || undefined;
};
