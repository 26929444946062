import { MantineProvider, Popover, Switch, TextInput } from '@mantine/core';
import { IconLink } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { StyledActionIcon } from '../../EditToolbar.styles';

import { ViewerData } from '~/global.types';

interface AddLinkToolProps {
  viewerData: Partial<ViewerData>;
}

// Export for testing/mocking convenience
// eslint-disable-next-line react-refresh/only-export-components
export const handleClickToEmitInlineToolEvent = (
  linkStr: string,
  viewerData: Partial<ViewerData>,
  isBlank: boolean,
) => {
  const viewer = (document.querySelector(`#${viewerData.viewer}`) as HTMLIFrameElement)
    .contentDocument;
  const getUi = viewer?.querySelector('[data-tool=link]') as HTMLLinkElement;
  getUi?.click();

  const getTargetSelect = viewer?.querySelector(
    '.ce-inline-tool-hyperlink--select-target',
  ) as HTMLInputElement;
  getTargetSelect.value = isBlank ? '_blank' : '_self';

  const getInput = viewer?.querySelector('.ce-inline-tool-hyperlink--input') as HTMLInputElement;
  getInput.value = linkStr;

  const getSaveButton = viewer?.querySelector(
    '.ce-inline-tool-hyperlink--button',
  ) as HTMLButtonElement;
  getSaveButton.click();
};

const AddLinkTool = ({ viewerData }: AddLinkToolProps) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState('');
  const [isBlank, setIsBlank] = useState<boolean>(false);

  useEffect(() => {
    const { editorState } = viewerData;
    setValue(editorState?.link || '');
    setIsBlank(editorState?.isBlank || false);
  }, [viewerData]);

  return (
    <Popover opened={opened} onChange={setOpened} position="bottom" zIndex={1001}>
      <Popover.Target>
        <StyledActionIcon
          aria-label="link-tool"
          variant="transparent"
          onClick={() => setOpened((o) => !o)}
        >
          <IconLink size={20} stroke={1.5} />
        </StyledActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <TextInput
          placeholder="Enter link"
          size="xs"
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClickToEmitInlineToolEvent(value, viewerData, isBlank);
              setOpened((o) => !o);
            }
          }}
        />
        <MantineProvider theme={{ cursorType: 'pointer' }}>
          <Switch
            labelPosition="left"
            checked={isBlank}
            onChange={() => setIsBlank((o) => !o)}
            label="Open link in new tab"
            mt="md"
          />
        </MantineProvider>
      </Popover.Dropdown>
    </Popover>
  );
};

export default AddLinkTool;
