export type BrandToken = Record<string, string>;

export function parseCSSVariables(css: string) {
  const result = [];
  const regex = /--([^:]+):\s*([^;]+);/g;
  let match;

  while ((match = regex.exec(css)) !== null) {
    const [, key, value] = match;
    result.push({ [key]: value.trim() });
  }

  return result;
}

export function cssVariablesArrayToCSS(arr: BrandToken[]) {
  let css = ':root {';
  arr.forEach((obj) => {
    const key = Object.keys(obj)[0];
    const value = obj[key];
    css += `--${key}: ${value};`;
  });
  css += '}';
  return css;
}

export function generateGoogleFontImports(fontFamilies: string[]): string {
  if (fontFamilies.length === 0) return '';

  const fontQueries = fontFamilies
    .map((fontFamily) => {
      const firstFont = fontFamily.split(',')[0].trim().replace(/ /g, '+');
      return `${firstFont}:wght@400;700`;
    })
    .join('&family=');

  return `@import url('https://fonts.googleapis.com/css2?family=${fontQueries}&display=swap');`;
}
