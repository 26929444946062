import { createGlobalStyle } from 'styled-components';

interface UserFrontStylesOverrideProps {
  submitButtonText?: string;
  socialSignInPrefix?: string;
}

const UserFrontStylesOverride = createGlobalStyle<UserFrontStylesOverrideProps>`
  @media (max-width: 550px) {
    body {
      background-color: var(--color-white);
    }
  }

  .userfront-container {
    --userfront-button-icon-size: 1.5rem;
    border: 0;
    box-shadow: none !important;
    font-family: var(--m3-label-large);
    color: var(--m3-sys-light-on-surface);
    padding: 0;
    margin-bottom: 1rem;
  }

  .userfront-container > h2 {
    display: none;
  }

  .userfront-input {
    border-color: var(--m3-sys-light-outline) !important;
  }

  .userfront-input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
  }

  .userfront-secured,
  .userfront-test-mode-notice {
    display: none !important;
  }

  .userfront-button-row>.userfront-button {
    flex: none!important;
    width: 48%;
  }

  .userfront-button {
    padding: 6px;
  }

  .userfront-button-primary {
    background-color: var(--m3-sys-light-primary) !important;
    border-radius: var(--br-base) !important;
    padding: 0;
    /*
     Userfront does not provide customisation to text inside form. But this can be done with CSS.
     This hides the original text, visually and from screen reader, so that custom text can be added.
     */
    font-size: 0;
    visibility: hidden;
    height: 2.75rem;
  }

  .userfront-button-primary::before {
    content: '${({ submitButtonText }) => submitButtonText || ''}';
    font-size: 14px;
    background-color: var(--m3-sys-light-primary) !important;
    border-radius: var(--br-base) !important;
    height: 100%;
    width: 100%;
    visibility: visible;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }

  .userfront-button-primary:hover:before {
    background-color: var(--m3-sys-light-primary-hover) !important;
  }

  .userfront-button-secondary {
    background: var(--sso-button-grey) !important;
    color: var(--color-black) !important;
    border-radius: var(--br-base) !important;
    padding: 10px 24px 10px 16px;
    height: 2.75rem;
    width: 100%;
  }

  .userfront-button-secondary:hover {
    color: var(--color-black) !important;
  }

  .userfront-button-secondary svg {
    margin-left: 95px;  
  }
  

  .userfront-button-secondary .userfront-icon-button-text {
    color: #20005F;
    font-size: 14px;
    font-weight: 500;
  }

  .userfront-icon-button-text::before {
    content: '${({ socialSignInPrefix }) => socialSignInPrefix || ''}';
  }
  
  .userfront-button:nth-child(3) .userfront-icon-button-text {
    visibility: hidden; /* Hide the original content */
  }
  
  .userfront-button:nth-child(3) .userfront-icon-button-text::before { 
    content: 'Continue with work email';
    visibility: visible;
  }

  h3 {
    text-align: center; 
    border-bottom: 1px solid #ccc; 
    line-height: 0.1em;
    margin: 10px 110px 20px 110px;
    font-size: 14px;
    font-weight: 400;
 } 
 
  h3 span { 
    background:#fff; 
    padding: 0 10px; 
  }

  @media (max-width: 550px) {
    h3 {
      margin: 10px 30px 20px;
    }
  }
`;

export default UserFrontStylesOverride;
