import Userfront from '@userfront/toolkit';
import useWebSocket, { Options } from 'react-use-websocket';

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL;

const useCommonWebSocket = <T>(options: Options = {}) => {
  const jwtToken = Userfront.tokens.accessToken;

  const { lastJsonMessage, sendJsonMessage, readyState } = useWebSocket<T>(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 5000,
    protocols: jwtToken,
    ...options,
  });

  return { receive: lastJsonMessage, send: sendJsonMessage, ready: readyState };
};

export default useCommonWebSocket;
