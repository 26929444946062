import { ActionIcon, Affix, Combobox, Flex, InputBase, Paper, useCombobox } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import {
  IconArrowBackUp,
  IconArrowForwardUp,
  IconChevronDown,
  IconZoomIn,
} from '@tabler/icons-react';
import { useState } from 'react';

import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';

interface SecondaryNavigationMenuProps {
  undoableFunctions: {
    undo: () => void;
    canUndo: boolean;
    redo: () => void;
    canRedo: boolean;
  };
}

const SecondaryNavigationMenu = ({
  undoableFunctions: { undo, canUndo, redo, canRedo },
}: SecondaryNavigationMenuProps) => {
  const [scale, setScale] = useState<string | null>('0');

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  useHotkeys([
    ['mod+Z', undo],
    ['mod+shift+Z', redo],
  ]);

  useViewerMessage(({ data }) => {
    if (data.type === 'viewer-zoom-update') setScale(Math.round(data.scale * 100).toString());
  }, []);

  const handleSelectToSetZoom = (val: string | null) => {
    msg({ type: 'set-viewer-zoom', scale: parseInt(val ?? '50', 10) / 100 });
    setScale(val);
    combobox.closeDropdown();
  };

  return (
    <Affix position={{ left: 8, bottom: 8 }} zIndex={9999}>
      <Paper shadow="xs" p="8" withBorder radius={10}>
        <Flex gap={8} align="center">
          <Combobox store={combobox} onOptionSubmit={handleSelectToSetZoom}>
            <Combobox.Target>
              <InputBase
                component="button"
                type="button"
                pointer
                leftSection={<IconZoomIn size={18} color="#000000" />}
                leftSectionPointerEvents="none"
                rightSection={<IconChevronDown size={18} color="#000000" />}
                rightSectionPointerEvents="none"
                onClick={() => combobox.toggleDropdown()}
              >
                {scale ?? '50'}%
              </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Options>
                {['10', '25', '50', '75', '100', '125', '150', '175', '200']
                  .reverse()
                  .map((item) => (
                    <Combobox.Option value={item} key={item}>
                      {item}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>

          <ActionIcon
            variant="default"
            style={{ border: 0, background: 'transparent' }}
            aria-label="undo"
            onClick={undo}
            disabled={!canUndo}
          >
            <IconArrowBackUp stroke={2} />
          </ActionIcon>
          <ActionIcon
            variant="default"
            style={{ border: 0, background: 'transparent' }}
            aria-label="redo"
            onClick={redo}
            disabled={!canRedo}
          >
            <IconArrowForwardUp stroke={2} />
          </ActionIcon>
        </Flex>
      </Paper>
    </Affix>
  );
};

export default SecondaryNavigationMenu;
