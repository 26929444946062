import {
  ActionIcon,
  Box,
  Button,
  FileButton,
  Flex,
  Group,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconBrowserCheck, IconInfoCircle, IconTrash } from '@tabler/icons-react';
import { useCallback, useMemo } from 'react';

import BasePanel from '../BasePanel/BasePanel';
import { PublishSubPanelsDataProps } from '../PublishSettings/PublishSettingsMenu';

import msg from '~/helpers/viewerInteractions/msg';
import { usePageSet } from '~/providers/PageSetProvider/PageSetProvider';

export interface PublishSettingsPanelProps {
  opened: boolean;
  onClickToClose: () => void;
  data?: PublishSubPanelsDataProps;
}

const {
  VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME = '*.testing.upflowyexperience.com',
  VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME,
} = import.meta.env || {};

const PublishSettingsPanel = ({ opened, onClickToClose, data }: PublishSettingsPanelProps) => {
  const { selectedVariant } = usePageSet();
  const { publishing, faviconFile, setFaviconFile } = data || {};
  const form = data?.form || {
    errors: {},
    values: { favicon: '', title: '', description: '' },
    getInputProps: () => {},
  };
  const formForNotContent = data?.formForNotContent || {
    errors: {},
    values: { nanoId: '', workspaceId: '', urlSlug: '' },
    getInputProps: () => {},
  };

  const formHasErrors =
    Object.keys(form.errors).length + Object.keys(formForNotContent.errors).length !== 0 ||
    formForNotContent.values.urlSlug.length === 0;

  const getUrlDescription = () => {
    if (VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME) {
      return 'URL path not shown in dev environment';
    }
    return `https://${VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME.replace(
      '*',
      formForNotContent.values.workspaceId,
    )}/${formForNotContent.values.urlSlug}`;
  };

  const urlDescription = useMemo(
    () => getUrlDescription(),
    [formForNotContent.values.workspaceId, formForNotContent.values.urlSlug],
  );

  const handleDeleteVariant = useCallback(() => {
    msg({ type: 'show-delete-variant-modal' });
  }, []);

  const onClickToOpenGoLivePanel = () => {
    msg({
      type: 'open-toolbar-panel',
      openToolbarPanel: 'publish-settings',
      openToolbarSubPanel: 'publish-go-live',
    });
  };

  return (
    <>
      <BasePanel
        opened={opened}
        onClickToClose={onClickToClose}
        label="Publish"
        title={'Settings' + (selectedVariant?.name ? `: Variant ${selectedVariant?.name}` : '')}
        icon={<IconBrowserCheck />}
        publishButton={
          <Box p={16} style={{ boxShadow: '0px -2px 2px 0px rgba(191, 191, 191, 0.25)' }}>
            <Button
              w="100%"
              disabled={formHasErrors}
              loaderProps={{ type: 'dots' }}
              onClick={onClickToOpenGoLivePanel}
            >
              Next
            </Button>
          </Box>
        }
      >
        <Flex direction="column" gap={32} w="100%">
          <TextInput
            leftSection={<span>/</span>}
            label={
              <Group gap={4}>
                <div>URL Slug</div>
                <Tooltip
                  // eslint-disable-next-line max-len
                  label="Customize the URL for this variant page. Use hyphens to separate words and avoid spaces or special characters"
                  zIndex={10000}
                  w={220}
                  withArrow
                  multiline
                  ta="center"
                  color="var(--mantine-color-gray-7)"
                >
                  <IconInfoCircle size={16} />
                </Tooltip>
              </Group>
            }
            size="sm"
            mt="md"
            description={urlDescription}
            {...formForNotContent.getInputProps('urlSlug')}
          />

          <Flex direction="column">
            <Title order={6} fw={500}>
              SEO Page title and favicon
            </Title>
            <Flex align="center" gap={8} mt={4} w="100%">
              <FileButton
                onChange={setFaviconFile!}
                accept="image/png,image/svg+xml"
                inputProps={{ name: 'favicon' }}
              >
                {(props) => {
                  let src = form.values.favicon || '/logolight-upflowyicon1.svg';
                  const { workspaceId } = formForNotContent.values;

                  if (form.values.favicon.length > 0) {
                    if (VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME) {
                      src = `https://${VITE_DEV_SITE_PROVISIONING_DOMAIN_NAME}/${form.values.favicon}`;
                    } else {
                      src = `https://${VITE_UPFLOWY_SITE_PROVISIONING_DOMAIN_NAME.replace(
                        '*',
                        workspaceId,
                      )}/${form.values.favicon}`;
                    }
                  }

                  if (faviconFile) {
                    src = URL.createObjectURL(faviconFile);
                  }

                  return (
                    <ActionIcon {...props} variant="default" aria-label="Upload favicon" size={36}>
                      <Box p={4}>
                        <img
                          src={src}
                          width="100%"
                          height="100%"
                          alt="favicon"
                          onError={(e) => {
                            (e.target as HTMLImageElement).src = '/logolight-upflowyicon1.svg';
                          }}
                        />
                      </Box>
                    </ActionIcon>
                  );
                }}
              </FileButton>
              <TextInput
                width="100%"
                aria-label="Set page title"
                placeholder="Title displays on the browser tab"
                size="sm"
                style={{ flex: '1 1 100%' }}
                {...form.getInputProps('title')}
              />
            </Flex>
          </Flex>

          <Textarea
            placeholder="Description meta tag"
            label="SEO Page description"
            size="sm"
            minRows={4}
            disabled={publishing}
            {...form.getInputProps('description')}
          />

          {selectedVariant?.name && (
            <Button
              variant="outline"
              color="var(--mantine-color-red-8)"
              size="sm"
              h={32}
              leftSection={<IconTrash size={16} />}
              fw={400}
              onClick={handleDeleteVariant}
            >
              Delete variant
            </Button>
          )}
        </Flex>
      </BasePanel>
    </>
  );
};

export default PublishSettingsPanel;
