import { Button, Group, Modal, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

import webHookModalClasses from './IntegrationModals.module.css';

import { REGEX_URL } from '../../constants';

import { TestWebhookResponse } from '~/global.types';

const INVALID_URL_MESSAGE = 'Enter your Slack webhook URL in the correct format';

const SlackModal = ({
  initialWebhookURL,
  opened,
  onSaveConnection,
  onClose,
  onTestWebhook,
}: {
  initialWebhookURL?: string;
  opened: boolean;
  onSaveConnection: (webhookUrl: string) => Promise<void>;
  onClose: () => void;
  onTestWebhook: (webhookUrl: string) => Promise<TestWebhookResponse>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isTesting, setIsTesting] = useState<boolean>(false);

  // Slack connection esssentially the same as Webhook connection
  const form = useForm({
    initialValues: {
      webhookUrl: initialWebhookURL ?? '',
    },

    validate: {
      webhookUrl: (value) => {
        if (value.length === 0) return 'Your webhook URL is required';
        return REGEX_URL.test(value) ? null : INVALID_URL_MESSAGE;
      },
    },
  });

  const handleClickClose = () => {
    onClose();
  };

  const handleClickTestConnection = async () => {
    setIsTesting(true);
    try {
      const response = await onTestWebhook(form.values.webhookUrl);
      notifications.show({
        color: 'blue',
        title: 'Success',
        message: `Webhook URL tested, status is ${response.statusText}`,
        autoClose: 3000,
      });
    } catch (error) {
      notifications.show({
        color: 'red',
        title: 'Failed',
        message: 'Unable to verify webhook!',
        autoClose: 3000,
      });
      console.error(error);
    } finally {
      setIsTesting(false);
    }
  };

  return (
    <Modal
      size="1116px"
      centered={true}
      opened={opened}
      onClose={() => !isSubmitting && handleClickClose()}
      keepMounted={false}
      classNames={{ ...webHookModalClasses, body: webHookModalClasses['modal-body'] }}
      title={
        <Group gap="xs">
          <img
            src="/assets/thumbnail-slack.png"
            style={{ width: '26px', height: '26px' }}
            alt="Slack"
          />
          <Text>Connect to Slack</Text>
        </Group>
      }
    >
      <iframe
        src="https://intent-upflowy-2.productfruits.help/en/article/slack?embed=1&nonav=1"
        style={{
          width: '100%',
          border: 'none',
          height: '380px',
          marginBottom: '20px',
        }}
      />
      <form
        onSubmit={form.onSubmit(async (values) => {
          setIsSubmitting(true);
          try {
            await onSaveConnection(values.webhookUrl);
            notifications.show({
              color: 'blue',
              title: 'Success',
              message: 'Webhook URL has been updated',
              autoClose: 3000,
            });
          } catch (e) {
            console.error(e);
          } finally {
            setIsSubmitting(false);
          }
        })}
      >
        <TextInput
          label="Follow the instructions to get your webhook URL and paste it here:"
          size="md"
          placeholder="https://api.placeholder.com"
          {...form.getInputProps('webhookUrl')}
        />
        <Group justify="space-between" mt="md" className={webHookModalClasses['form-buttons']}>
          <Button
            bg="var(--m3-sys-light-primary)"
            data-disabled={isSubmitting}
            loading={isTesting}
            onClick={async () => {
              if (!form.isValid()) {
                notifications.show({
                  color: 'red',
                  title: 'Invalid webhook URL',
                  message: 'Provide a valid webhook URL and try again',
                  autoClose: 3000,
                });
              } else {
                await handleClickTestConnection();
              }
            }}
          >
            Send test data to webhook
          </Button>
          <Group justify="flex-end">
            <Button
              variant="default"
              onClick={handleClickClose}
              data-disabled={isSubmitting || isTesting}
            >
              Close
            </Button>
            <Button
              type="submit"
              bg="var(--m3-sys-light-primary)"
              data-disabled={isTesting}
              loading={isSubmitting}
            >
              Save connection
            </Button>
          </Group>
        </Group>
      </form>
    </Modal>
  );
};

export default SlackModal;
