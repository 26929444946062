import useUndoable, { UseUndoable } from 'use-undoable';

import { LandingpageDetails } from '~/global.types';
import msg from '~/helpers/viewerInteractions/msg';

const historyMsg = (pageData: string, pageDetails: LandingpageDetails) => {
  msg({
    type: 'broadcast-history-change-page-data',
    pageData,
    pageDetails,
  });
};

const useUndoRedo = (
  pageDetails: LandingpageDetails,
  isPerformanceModeOn: boolean,
): UseUndoable<string> => {
  const [pageData, setPageData, undoableFunctions] = useUndoable('', {
    behavior: 'destroyFuture',
    historyLimit: isPerformanceModeOn ? 20 : 60,
  });

  const extendedUndoableFunctions = {
    ...undoableFunctions,

    undo() {
      const { past, canUndo } = undoableFunctions;
      if (canUndo) {
        undoableFunctions.undo();
        historyMsg(past[past.length - 1], pageDetails);
      }
    },

    redo() {
      const { future, canRedo } = undoableFunctions;
      if (canRedo) {
        undoableFunctions.redo();
        historyMsg(future[0], pageDetails);
      }
    },
  };

  return [pageData, setPageData, extendedUndoableFunctions];
};

export default useUndoRedo;
