import { Button, Divider, Group, Modal, Stack, Text } from '@mantine/core';
import { IconRotate2 } from '@tabler/icons-react';

interface SyncVariantLightBoxProps {
  opened: boolean;
  onClose: () => void;
  onSyncClick: () => void;
}

const SyncVariantLightBox: React.FC<SyncVariantLightBoxProps> = ({
  opened,
  onClose,
  onSyncClick,
}) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Sync required"
      closeButtonProps={{ iconSize: 16, c: 'gray' }}
      size={607}
      zIndex={99999}
      centered
      radius={4}
      padding={16}
      styles={{
        title: {
          color: 'var(--mantine-color-gray-8)',
          fontSize: 'calc(1.5rem* var(--mantine-scale))',
          fontWeight: 'var(--mantine-h3-font-weight)',
          lineHeight: 'var(--mantine-h3-line-height)',
        },
      }}
    >
      <Stack gap={24}>
        <Text size="sm" c="var(--mantine-color-gray-7)">
          You need to sync your edits before accessing other variants.
        </Text>

        <Divider c="var(--mantine-color-gray-2)" py={4} />

        <Group gap={14}>
          <Button variant="default" size="md" onClick={onClose}>
            <Text c="var(--mantine-color-dark-8)">Cancel</Text>
          </Button>
          <Button
            variant="filled"
            size="md"
            leftSection={<IconRotate2 size={20} />}
            onClick={onSyncClick}
          >
            <Text>Sync edits</Text>
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default SyncVariantLightBox;
