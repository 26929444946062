import { Center, Image, Loader, Stack, Text } from '@mantine/core';

import TopLeftLogo from '../components/TopLeftLogo';

const Ingesting = () => {
  return (
    <Center h="100%">
      <TopLeftLogo src="/upflowy_with_gradient.svg" alt="Upflowy" />

      <Stack align="center" w="calc(100vw - 2rem)" maw={320}>
        <Image radius="md" src="/assets/ingesting.svg" />
        <Loader color="purple" mt="-1rem" mb="1rem" />
        <Text ta="center">
          Just a second! We’re understanding your brand and business and compiling suggestions...
        </Text>
      </Stack>
    </Center>
  );
};
export default Ingesting;
