import { NumberInput } from '@mantine/core';
import { useState } from 'react';

import { ViewerData } from '~/global.types';
import {
  modifyElementStyleInIframe,
  modifyTextRangeStyleInFirame,
} from '~/helpers/viewerInteractions/viewerUpdateUponEditing';

interface FontSizeToolProps {
  viewerData: Partial<ViewerData>;
}

const FontSizeTool = ({ viewerData }: FontSizeToolProps) => {
  const [fontSize, setFontSize] = useState<string>(
    (viewerData?.editorState?.fontSize || '')?.toString(),
  );

  const handleFontSizeChange = (value: number | string) => {
    const newSize = value.toString() ?? '10';
    setFontSize(newSize);

    if (viewerData?.type === 'element-selected-in-viewer') {
      modifyElementStyleInIframe('fontSize', `${newSize}px`, viewerData);
    } else {
      modifyTextRangeStyleInFirame('font-size', `${newSize}px`, viewerData);
    }
  };

  return (
    <NumberInput
      aria-label="Set font size"
      size="xs"
      value={parseInt(fontSize, 10)}
      min={8}
      max={144}
      allowDecimal={false}
      allowNegative={false}
      onChange={handleFontSizeChange}
      style={{ width: 54 }}
    />
  );
};

export default FontSizeTool;
