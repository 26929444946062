import { Box, CloseButton, Collapse, Flex, Paper, Title } from '@mantine/core';
import React from 'react';

interface BasePanelProps {
  opened: boolean;
  onClickToClose?: () => void;
  label: string;
  title: string;
  isClosable?: boolean;
  icon: React.ReactNode;
  publishButton?: React.ReactNode;
  children: React.ReactNode;
}

const BasePanel = ({
  opened,
  label,
  title,
  isClosable = true,
  icon,
  children,
  publishButton,
  onClickToClose,
}: BasePanelProps) => {
  return (
    <Collapse
      in={opened}
      transitionDuration={100}
      transitionTimingFunction="linear"
      aria-label={label}
      w={'100%'}
      style={{
        position: 'absolute',
        right: 0,
      }}
    >
      <Paper shadow="xs" mt={9} withBorder radius={10} pos="relative">
        <Box p={16} style={{ overflow: 'auto' }}>
          <Flex justify="space-between">
            <Flex>
              {icon}
              <Title order={4} ml={8} mb={8} fw={400}>
                {title}
              </Title>
            </Flex>
            {isClosable && <CloseButton aria-label="Close" onClick={onClickToClose} />}
          </Flex>
          {children}
        </Box>
        {publishButton}
      </Paper>
    </Collapse>
  );
};

export default BasePanel;
