import Userfront from '@userfront/toolkit/react';
import { createBrowserRouter, redirect } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import IntentLayout from './components/IntentLayout/IntentLayout';
import { AccountProfileContainer } from './screens/AccountProfile/AccountProfileContainer';
import Accounts from './screens/Accounts';
import AdminDashboard from './screens/AdminDashboard';
import { Login, PasswordReset, SignUp } from './screens/Auth/Auth';
import ContactUs from './screens/ContactUs';
import Dashboard from './screens/Dashboard/Dashboard';
import ErrorScreen from './screens/ErrorScreen';
import IntentHelp from './screens/HelpIntent';
import IntentDashboard from './screens/IntentDashboard/IntentDashboard';
import IntentIntegrations from './screens/IntentIntegrations';
import DataExport from './screens/Onboarding/DataExport';
import IngestBrand from './screens/Onboarding/IngestBrand/IngestBrand';
import Ingesting from './screens/Onboarding/Ingesting/Ingesting';
import SDKSetup from './screens/Onboarding/SDKSetup';
import Website from './screens/Onboarding/Website';
import Welcome from './screens/Onboarding/Welcome/Welcome';
import Preview from './screens/Preview/Preview';
import PreviewByUrlToken from './screens/Preview/PreviewByUrlToken';
import Studio from './screens/Studio/Studio';
import SwitchApp from './screens/SwitchApp';

Userfront.init(import.meta.env?.VITE_USERFRONT_TENANT_ID ?? '9nymm5yn');

function loginAlreadyRedirect() {
  if (Userfront.tokens.accessToken) return redirect('/switch-app');
  return true;
}

function redirectIfNotSuperAdmin() {
  if (!Userfront.user.hasRole('superadmin')) {
    return redirect('/intent/dashboard');
  }
  return true;
}

const routes = createBrowserRouter([
  {
    path: '/',
    loader: loginAlreadyRedirect,
    element: <SignUp />,
  },
  {
    path: '/login',
    loader: loginAlreadyRedirect,
    element: <Login />,
  },
  {
    path: '/reset',
    loader: loginAlreadyRedirect,
    element: <PasswordReset />,
  },
  {
    path: '/dashboard',
    element: <PrivateRoute element={<Dashboard />} requireV2Subscription />,
  },
  {
    path: '/intent/dashboard',
    element: (
      <PrivateRoute
        requireIntentSubscription
        element={
          <IntentLayout>
            <IntentDashboard />
          </IntentLayout>
        }
      />
    ),
  },
  {
    path: '/:nanoId/edit/',
    element: <PrivateRoute element={<Studio />} requireV2Subscription />,
  },
  {
    path: '/:nanoId/preview/',
    element: <PrivateRoute element={<Preview />} requireV2Subscription />,
  },
  {
    path: '/preview/:nanoId',
    element: <PreviewByUrlToken />,
  },
  {
    path: '/switch-app',
    element: <PrivateRoute element={<SwitchApp />} />,
  },
  {
    path: '/onboarding',
    element: <PrivateRoute element={<Welcome />} requireV2Subscription />,
  },
  {
    path: '/pages/new',
    element: <PrivateRoute element={<Welcome />} requireV2Subscription />,
  },
  {
    path: '/onboarding-ingest-website',
    element: <PrivateRoute element={<IngestBrand />} requireV2Subscription />,
  },
  {
    path: '/onboarding-ingesting',
    element: <PrivateRoute element={<Ingesting />} requireV2Subscription />,
  },
  {
    path: '/onboarding-website',
    element: <PrivateRoute element={<Website />} />,
  },
  {
    path: '/onboarding-sdk-setup',
    element: <PrivateRoute element={<SDKSetup />} />,
  },
  {
    path: '/onboarding-integration',
    element: <PrivateRoute element={<DataExport />} />,
  },
  {
    path: '/accounts',
    element: <PrivateRoute element={<Accounts />} />,
  },
  {
    path: '/tell-us-about-yourself',
    element: <PrivateRoute element={<ContactUs />} />,
  },
  {
    path: '/profile',
    element: (
      <PrivateRoute
        element={
          <IntentLayout>
            <AccountProfileContainer />
          </IntentLayout>
        }
      />
    ),
  },
  {
    path: '/intent/integrations',
    element: (
      <PrivateRoute
        element={
          <IntentLayout>
            <IntentIntegrations />
          </IntentLayout>
        }
      />
    ),
  },
  {
    path: '/intent/connect-to-your-website',
    element: (
      <PrivateRoute
        requireIntentSubscription
        element={
          <IntentLayout>
            <IntentHelp />
          </IntentLayout>
        }
      />
    ),
  },
  {
    path: '/admin/dashboard',
    loader: redirectIfNotSuperAdmin,
    element: (
      <PrivateRoute
        element={
          <IntentLayout>
            <AdminDashboard />
          </IntentLayout>
        }
      />
    ),
  },
  {
    path: '*',
    element: <ErrorScreen statusNumber={404} />,
  },
  {
    path: '/403',
    element: <ErrorScreen statusNumber={403} />,
  },
]);

export default routes;
