import { ActionIcon, Group, Menu, rem, TextInput } from '@mantine/core';
import {
  IconBrowser,
  IconDashboard,
  IconDotsVertical,
  IconLayoutDashboard,
  IconLogout,
  IconPlugConnected,
  IconReceipt2,
  IconUser,
} from '@tabler/icons-react';
import Userfront from '@userfront/toolkit/react';
import debounce from 'lodash.debounce';
import { SyntheticEvent, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import TrackingStatus from './TrackingStatus';

import { TrackingStatusInfo } from '../../global.types';

import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { isSuperAdmin } from '~/services/UserServices';

type IntentMenuProps = {
  workspaceName: string;
  loading: boolean;
  onWorkspaceNameChange?: (newName: string) => void;
  trackingStatusInfo: TrackingStatusInfo;
  openPricing: () => void;
  onTrackingStatusClick: () => Promise<void>;
};

const IntentMenu = ({
  workspaceName,
  loading,
  onWorkspaceNameChange,
  trackingStatusInfo,
  onTrackingStatusClick,
  openPricing,
}: IntentMenuProps) => {
  const navigate = useNavigate();
  const [wsName, setWorkspaceName] = useState(workspaceName);
  const { userAndWorkspaceInfo } = useFeatureFlags();
  const { user } = userAndWorkspaceInfo;
  const debounced = debounce((name: string) => {
    onWorkspaceNameChange && onWorkspaceNameChange(name);
  }, 300);

  const changeHandler = useCallback(async (e: SyntheticEvent) => {
    const name = (e.currentTarget as HTMLInputElement).value;
    setWorkspaceName(name);
    debounced(name);
  }, []);

  const handleClickLogout = (e: SyntheticEvent) => {
    e.preventDefault();
    Userfront.logout();
  };

  const handleClickPlanAndPricing = (e: SyntheticEvent) => {
    e.preventDefault();
    if (user?.data?.stripeCustomerId) {
      navigate('/accounts');
    } else {
      openPricing();
    }
  };
  return (
    <Menu
      shadow="md"
      position="bottom-start"
      width={200}
      styles={{
        item: { padding: '10px' },
      }}
    >
      <Group
        justify="space-between"
        style={{
          width: '350px',
          backgroundColor: '#fff',
          padding: '8px',
          borderRadius: '10px',
          gap: '5px',
        }}
      >
        <Link to="/intent/dashboard">
          <img
            src="/intent_small.svg"
            style={{ width: '40px', height: '40px' }}
            alt="Upflowy Intent"
            className={loading ? 'spin' : ''}
          />
        </Link>
        <TextInput variant="unstyled" value={wsName} maw={140} size="sm" onChange={changeHandler} />
        <TrackingStatus
          status={trackingStatusInfo.status}
          lastEventCreatedAt={trackingStatusInfo.lastEventCreatedAt}
          onClick={onTrackingStatusClick}
        />
        <Menu.Target>
          <ActionIcon variant="transparent" aria-label="Menu">
            <IconDotsVertical style={{ color: 'var(--color-black)' }} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
      </Group>
      <Menu.Dropdown style={{ width: 'fit-content' }}>
        {isSuperAdmin() && (
          <Menu.Item
            leftSection={<IconDashboard style={{ width: rem(14), height: rem(14) }} />}
            onClick={() => navigate('/admin/dashboard')}
          >
            Admin Dashboard
          </Menu.Item>
        )}
        <Menu.Item
          leftSection={<IconLayoutDashboard style={{ width: rem(14), height: rem(14) }} />}
          onClick={() => navigate('/intent/dashboard')}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          leftSection={<IconBrowser style={{ width: rem(14), height: rem(14) }} />}
          onClick={() => navigate('/intent/connect-to-your-website')}
        >
          Connect to your website
        </Menu.Item>
        <Menu.Item
          leftSection={<IconPlugConnected style={{ width: rem(14), height: rem(14) }} />}
          onClick={() => navigate('/intent/integrations')}
        >
          Integrations
        </Menu.Item>
        <Menu.Divider />
        <Menu.Label>Account</Menu.Label>
        <Menu.Item
          leftSection={<IconUser style={{ width: rem(14), height: rem(14) }} />}
          onClick={() => navigate('/profile')}
        >
          Profile
        </Menu.Item>
        <Menu.Item
          leftSection={<IconReceipt2 style={{ width: rem(14), height: rem(14) }} />}
          onClick={(e) => handleClickPlanAndPricing(e)}
        >
          Plan & Pricing
        </Menu.Item>
        <Menu.Item
          leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
          onClick={handleClickLogout}
        >
          Log out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default IntentMenu;
