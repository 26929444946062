import { PageSetVariantStateObj } from '../PageSetProvider';
import updatePageContentWithVariant from '../updatePageContentWithVariant';

import msg from '~/helpers/viewerInteractions/msg';
import { BlockOutput } from '~/messages.types';
import {
  GetIdentifyVariantMarker,
  GetPageVariantData,
  updatePageSet,
} from '~/services/PageSetServices/PageSetServices';

type VariantAction =
  | {
      type: 'update-variant-marker';
      payload: {
        selector: string;
        outputData: GetIdentifyVariantMarker['data'][0];
        pageSetId: string;
        selectedVariant: GetPageVariantData;
        setSelectedVariant: React.Dispatch<React.SetStateAction<GetPageVariantData | undefined>>;
      };
    }
  | { type: 'to-analyze-variant-marker'; payload: { selector: string } }
  | {
      type: 'mark-blueprint-edited';
      payload: { selector: string; variantNodeId?: string; outputData: BlockOutput };
    }
  | { type: 'reset-variant-state' }
  | { type: 'override-variant-state'; payload: PageSetVariantStateObj };

const variantStateReducer = (
  state: PageSetVariantStateObj,
  action: VariantAction,
): PageSetVariantStateObj => {
  switch (action.type) {
    case 'mark-blueprint-edited': {
      const { selector, outputData, variantNodeId } = action.payload;
      const { data } = outputData;

      if (!data?.text) return state;

      return {
        ...state,
        editedVariant: state.editedVariant,
        editedVariantNodes: {
          ...state.editedVariantNodes,
          [selector]: {
            status: 'editing',
            selector,
            textValue: data?.text,
            ...(variantNodeId && { variantNodeId }),
          },
        },
      };
    }

    case 'to-analyze-variant-marker': {
      const { selector } = action.payload;

      return {
        ...state,
        editedVariantNodes: {
          ...state?.editedVariantNodes,
          [selector]: {
            ...(state?.editedVariantNodes && state.editedVariantNodes[selector]),
            status: 'analyzing',
          },
        },
      };
    }

    case 'update-variant-marker': {
      const { selector, outputData, pageSetId, selectedVariant, setSelectedVariant } =
        action.payload;

      const hasMarker = outputData.textValue.includes('</up-markup>');

      let selectedElement: PageSetVariantStateObj['editedVariantNodes'];
      if (hasMarker) {
        selectedElement = {
          [selector]: {
            ...(state?.editedVariantNodes && state.editedVariantNodes[selector]),
            status: 'unsynced',
            ...outputData,
          },
        };
      } else {
        selectedElement = {
          [selector]: undefined,
        };
      }

      const updatedState: PageSetVariantStateObj = {
        ...state,
        editedVariant: selectedVariant.nanoId,
        editedVariantNodes: {
          ...state?.editedVariantNodes,
          ...selectedElement,
        },
      };

      msg({
        type: 'handle-variant-markers',
        selector,
        textValue: outputData.textValue,
        blueprintText: outputData.blueprintText,
        hasMarker,
        variantNodeId: outputData.variantNodeId,
      });

      const currentVariantNodes = { ...selectedVariant.variantNodes };
      let pageSetVariantNodeIds = Object.keys(currentVariantNodes);

      const editedVariantNodeId =
        state.editedVariantNodes && state.editedVariantNodes[selector]?.variantNodeId;

      const shouldRemove = !hasMarker && editedVariantNodeId;
      const shouldAdd = hasMarker && !editedVariantNodeId;
      const shouldUpdate = hasMarker && editedVariantNodeId;

      if (shouldRemove) {
        delete currentVariantNodes[editedVariantNodeId];
        pageSetVariantNodeIds = pageSetVariantNodeIds.filter((id) => id !== editedVariantNodeId);
      }

      if (shouldAdd) {
        currentVariantNodes[outputData.variantNodeId] = {
          textValue: outputData.textValue,
          type: 'text',
        };
        pageSetVariantNodeIds.push(outputData.variantNodeId);
      }

      if (shouldUpdate) {
        currentVariantNodes[editedVariantNodeId] = {
          ...currentVariantNodes[editedVariantNodeId],
          textValue: outputData.textValue,
        };
      }

      setSelectedVariant({
        ...selectedVariant,
        variantNodes: currentVariantNodes,
      });

      updatePageSet({
        nanoId: pageSetId,
        state: updatedState,
        variantNodeIds: pageSetVariantNodeIds,
      });

      document.querySelectorAll('iframe[srcdoc]').forEach((viewer) => {
        updatePageContentWithVariant(
          viewer as HTMLIFrameElement,
          currentVariantNodes,
          updatedState,
        );
      });

      msg({ type: 'update-page-variant-state-success' });

      return updatedState;
    }

    case 'reset-variant-state':
      return {};

    case 'override-variant-state':
      return action.payload;

    default:
      return state;
  }
};

export default variantStateReducer;
