import { Button, Center, Image } from '@mantine/core';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { HideChat } from '../Studio/Studio.styles';

import ScreenContainer from '~/components/ScreenContainer/ScreenContainer.styles';
import { ERROR_STATUSES } from '~/constants';

import './ErrorScreen.css';

interface ErrorProps {
  statusNumber?: keyof typeof ERROR_STATUSES;
}

const ErrorScreen = ({ statusNumber = 404 }: ErrorProps) => {
  const navigate = useNavigate();
  const { heading, message, image } = ERROR_STATUSES[statusNumber];

  useEffect(() => {
    document.title = heading;
  }, []);
  return (
    <ScreenContainer style={{ textAlign: 'center' }}>
      <HideChat />
      <Center>
        <Image src={image} alt={heading} w={500} p={20} />
      </Center>
      <h1>{heading}</h1>
      <p>{message}</p>
      <Button onClick={() => navigate('/')}>
        <IconArrowNarrowLeft /> Back to home
      </Button>
    </ScreenContainer>
  );
};
export default ErrorScreen;
