import { useClickOutside } from '@mantine/hooks';
import IframeResizer from 'iframe-resizer-react';
import styled, { createGlobalStyle } from 'styled-components';

import msg from '~/helpers/viewerInteractions/msg';

// This is needed to disable browser native behaviour of two finger touch-swipe to change browser history.
// We need this two finger swipe to pan the viewer
const DisableBrowserNativeTouchEvents = createGlobalStyle`
  html, body {
    overscroll-behavior-x: none;
  }
`;

export const DevicesContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 8rem;
  transform: translateX(-40vw) translateY(-240px);
`;

export const ViewerContainer = styled.div`
  width: 100%;
  background-color: var(--m3-sys-light-on-primary);
  outline: 1px solid var(--m3-sys-light-outline-variant);
  box-sizing: border-box;
`;

export const Desktop = styled.div`
  width: 1920px;
`;
export const Tablet = styled.div`
  width: 820px;
`;
export const Mobile = styled.div`
  width: 390px;
`;

type ResizableViewerProps = IframeResizer.IframeProps &
  IframeResizer.ResizerOptions &
  IframeResizer.ResizerEvents & { holdMetaKey?: boolean; blockingEditor?: boolean };

export const ResizableViewer = styled(
  ({ holdMetaKey, blockingEditor: _, ...rest }: ResizableViewerProps) => {
    const ref = useClickOutside(() => {
      // Ensure resetting the selection bound only when the outside target is body,
      // so that it doesn't impact on the toolbars.
      // However, this should not apply if the user is release the mouse after panning
      if (document.activeElement?.tagName === 'BODY') {
        msg({ type: 'reset-bound' });
      }
    }, ['dblclick', 'touchend']);

    return (
      <div style={{ position: 'relative' }} ref={holdMetaKey ? null : ref}>
        <DisableBrowserNativeTouchEvents />
        <IframeResizer
          checkOrigin={false}
          heightCalculationMethod="taggedElement"
          style={{
            width: '1px',
            minWidth: '100%',
          }}
          {...rest}
        />
      </div>
    );
  },
)`
  border: 0;
  overflow: hidden;
  ${(props) => (props.holdMetaKey || props.blockingEditor) && 'pointer-events: none;'}
`;
