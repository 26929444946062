import { PageJsonSnippetObj } from '~/global.types';

interface AssetServiceImageResponse {
  fileName: string;
  filePaths: string[];
  metadata?: Record<string, string>;
}

function placeholderToImage(obj: PageJsonSnippetObj, uploaded: AssetServiceImageResponse) {
  const { fileName, filePaths, metadata } = uploaded;
  const updatedObject = JSON.parse(JSON.stringify(obj));

  for (const key in updatedObject) {
    if (key === '@data-image-src' && updatedObject[key] === fileName) {
      if (updatedObject.tagName === 'div') {
        updatedObject.tagName = 'img';
      }

      // Handle image link
      if (updatedObject['@data-image-link']) {
        updatedObject.contains.find((item: PageJsonSnippetObj) => item['@data-wrapped-element'])[
          '@src'
        ] = filePaths[0];
      } else {
        // Normal image
        updatedObject['@src'] = filePaths[0];
      }

      updatedObject['@data-image-src'] = undefined;
      delete updatedObject.textValue;

      if (metadata?.altText) {
        updatedObject['@alt'] = metadata.altText;
      }

      updatedObject['updatedTimestamp'] = new Date().valueOf();
    } else if (typeof updatedObject[key] === 'object') {
      updatedObject[key] = placeholderToImage(obj[key] as PageJsonSnippetObj, uploaded);
    }
  }

  return updatedObject;
}

export default placeholderToImage;
