import { Alert, Anchor, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconExclamationCircle } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import Pricing from '~/screens/Pricing';
import { getQuotaInfo } from '~/services/IntentAPI';

const StyledAnchor = styled(Anchor)`
  font-size: 14px;
  text-decoration: underline;
  color: inherit;
`;

type WorkspaceQuotaInfoProps = {
  workspaceId: string;
  propertyId: string;
};

const WorkspaceQuotaInfo = ({ workspaceId, propertyId }: WorkspaceQuotaInfoProps) => {
  const { userAndWorkspaceInfo } = useFeatureFlags();
  const { user } = userAndWorkspaceInfo;

  const [redirecting, setRedirecting] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [openPricing, setOpenPricing] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getQuotaInfo({ workspaceId, propertyId }).then((quotaInfoResponse) => {
      if (quotaInfoResponse.usedQuotaPercentage >= 75) {
        const msg = quotaInfoResponse.hasQuotaAvailable
          ? `You have used ${quotaInfoResponse.usedQuotaPercentage}% of your quota for the month`
          : `You have reached your lead limit for the month`;
        setMessage(msg);
      }
    });
  }, []);

  const handleSubscription = async () => {
    try {
      if (user?.data?.stripeCustomerId) {
        setRedirecting(true);
        navigate('/accounts');
      } else {
        setOpenPricing(true);
      }
    } catch (error) {
      setRedirecting(false);
      notifications.show({
        color: 'red',
        title: 'Unable to connect payment gateway!',
        message: 'Our team has been notified. Contact us if the problem persists.',
        autoClose: 3000,
      });
      window.location.href = '/intent/dashboard';
    }
  };

  if (!message) return <></>;

  return (
    <Alert
      variant="outline"
      color="#C92A2A"
      title={message}
      icon={redirecting ? <Loader size="xs" color="#C92A2A" /> : <IconExclamationCircle />}
      styles={{ root: { height: '80px' } }}
    >
      {!redirecting && (
        <>
          {openPricing && <Pricing withCloseButton onClose={() => setOpenPricing(false)} />}
          <StyledAnchor onClick={handleSubscription}>Upgrade now</StyledAnchor> to act on leads with
          high intent
        </>
      )}
      {redirecting && <>Loading Portal...</>}
    </Alert>
  );
};

export default WorkspaceQuotaInfo;
