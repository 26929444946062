import { Badge, BadgeProps, Select, SelectProps, Tabs } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconSection } from '@tabler/icons-react';
import { useEffect, useReducer, useState } from 'react';

import {
  backgroundReducer,
  backgroundReducerInit,
  BackgroundTypes,
  defaultSolid,
} from './backgroundReducer';

import BasePanel from '../BasePanel/BasePanel';
import { BrandToken, parseCSSVariables } from '../StylePanel/styleProcessing';

import ImageMasonry from '~/components/ImageMasonry/ImageMasonry';
import ImagePreview from '~/components/ImagePreview/ImagePreview';
import ImageUploader from '~/components/ImageUploader/ImageUploader';
import { SolidAndGradientColorEditor } from '~/components/SolidAndGradientColorEditor/SolidAndGradientColorEditor';
import { PageJsonSnippetObj, ToolbarPanelProps } from '~/global.types';
import {
  checkIsGradientAsBackgroundImage,
  checkIsSolidColorBrandPreset,
  checkIsUrlInBackgroundImage,
  getStyleWithString,
} from '~/helpers/cssVariableProcessing/cssVariableProcessing';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { MediaCollectionItem } from '~/providers/MediaCollectionProvider';
import { useStudioWorkspace } from '~/providers/WorkspaceProvider/WorkspaceProvider';
import { transferAsset, TransferAssetResponse, uploadFile } from '~/services/AssetServices';

function extractBrandTokens(head: PageJsonSnippetObj[]) {
  const cssVariablesFromHead = head.find(getStyleWithString(':root'));

  if (cssVariablesFromHead && cssVariablesFromHead.textValue) {
    const { textValue } = cssVariablesFromHead;
    const brandTokens: BrandToken[] = [];

    for (const brandToken of parseCSSVariables(textValue as string)) {
      const tokenName = Object.keys(brandToken)[0];
      const tokenValue = brandToken[tokenName];

      if (tokenName.includes('color-')) {
        brandTokens.push({ [tokenName]: tokenValue });
      }
    }

    return brandTokens;
  }

  return [];
}

const checkIsSvg = (cssUrlStr = '') => /^url\(["']?.+\.svg["']?\)$/i.test(cssUrlStr);

const CircleBadge = ({ active, ...rest }: BadgeProps & { active: boolean }) => (
  <Badge
    variant="default"
    w={20}
    h={22}
    radius="100%"
    {...rest}
    {...(active && { style: { borderColor: 'var(--mantine-color-upflowy-purple-filled)' } })}
  />
);

const StyledSelect = (props: SelectProps) => (
  <Select
    checkIconPosition="right"
    allowDeselect={false}
    comboboxProps={{ withinPortal: false }}
    withScrollArea={false}
    classNames={{
      groupLabel: 'show-group-line-only',
    }}
    {...props}
  />
);

const BackgroundPanel = ({
  opened,
  handleClickToToggleSubPanel,
  viewerDataFromPostMessage = {},
}: ToolbarPanelProps) => {
  const [{ workspaceId = '', siteProvisioningUrl }] = useStudioWorkspace();
  const [{ activeTab, ...state }, dispatch] = useReducer(backgroundReducer, backgroundReducerInit);

  const [brandColors, setBrandColors] = useState<BrandToken[]>([]);
  const [inReplaceImagePanel, setInReplaceImagePanel] = useDisclosure();
  const [isReplacingImage, setIsReplacingImage] = useDisclosure();

  const { solid, gradient, image } = state;
  const {
    backgroundSize: imgBgSize = 'cover',
    backgroundRepeat: imgBgRepeat = 'no-repeat',
    backgroundPosition: imgBgPos = 'top left',
  } = image.additional || {};

  const handleSwitchTab = (tab: string | null, overridingValue?: string) => {
    dispatch({ type: 'active', tab, overridingValue });
  };

  const handleChangeToUpdateValue =
    (key: 'backgroundImage' | 'backgroundColor', tab: BackgroundTypes) => (val: string) => {
      if (checkIsSolidColorBrandPreset(val)) {
        // Brand colors are just solid colors
        handleSwitchTab('solid', val);
      } else if (tab === 'gradient' && !checkIsGradientAsBackgroundImage(val)) {
        // Click on preset will also do a secondary onChange to switch picker color, ensure this is handled
        dispatch({ type: 'solid', data: { type: 'backgroundColor', value: val } });
      } else if (!checkIsSolidColorBrandPreset(val)) {
        dispatch({ type: tab, data: { type: key, value: val } });
      }
    };

  useEffect(() => {
    // Everytime the panel closes, this triggers autosaves
    if (!opened) {
      setInReplaceImagePanel.close();

      if (Object.keys(viewerDataFromPostMessage).length !== 0) msg({ type: 'editor-sync' });
    } else {
      dispatch({ type: 'reset' });
      dispatch({ type: 'set-viewer-data', viewerData: viewerDataFromPostMessage });

      const {
        backgroundImage = '',
        backgroundColor,
        backgroundSize = 'cover',
        backgroundRepeat = 'no-repeat',
      } = viewerDataFromPostMessage.editorState || {};

      if (checkIsUrlInBackgroundImage(backgroundImage)) {
        dispatch({
          type: 'image',
          isInit: true,
          data: {
            type: 'backgroundImage',
            value: backgroundImage,
            additional: {
              backgroundSize,
              backgroundRepeat,
            },
          },
        });
        dispatch({ type: 'active', isInit: true, tab: 'image' });
      } else if (checkIsGradientAsBackgroundImage(backgroundImage)) {
        dispatch({
          type: 'gradient',
          isInit: true,
          data: { type: 'backgroundImage', value: backgroundImage },
        });
        dispatch({ type: 'active', isInit: true, tab: 'gradient' });
      } else {
        dispatch({
          type: 'solid',
          isInit: true,
          data: { type: 'backgroundColor', value: backgroundColor ?? defaultSolid },
        });
        dispatch({ type: 'active', isInit: true, tab: 'solid' });
      }
    }
  }, [opened, viewerDataFromPostMessage]);

  useViewerMessage(({ data }) => {
    if (data.type === 'head-edited') {
      setBrandColors(extractBrandTokens(data.head));
    }

    if (
      data.type === 'first-fetch-page-data-completed' ||
      data.type === 'broadcast-history-change-page-data'
    ) {
      const { head } = JSON.parse(data.pageData);
      if (head) setBrandColors(extractBrandTokens(head));
    }
  }, []);

  const backgroundImageSync = ({ filePaths }: TransferAssetResponse) => {
    const constructedImagePath = `url(${siteProvisioningUrl + filePaths[0].replaceAll(' ', '+')})`;
    dispatch({
      type: 'image',
      data: {
        ...image,
        value: constructedImagePath,
        additional: {
          backgroundSize: imgBgSize,
          backgroundRepeat: imgBgRepeat,
        },
      },
    });

    setIsReplacingImage.close();
  };

  const backgroundImageErrorNotification = () => {
    notifications.show({
      id: 'background-image-upload-error',
      color: 'red',
      message: 'Error adding image as background. Please try again.',
      autoClose: 3000,
    });
    setIsReplacingImage.close();
  };

  const handleFileSelect = (imageFile: File | null) => {
    if (!imageFile) return;
    setIsReplacingImage.open();
    uploadFile({ workspaceId, file: [imageFile] })
      .then(backgroundImageSync)
      .catch(backgroundImageErrorNotification);
  };

  const handleSelectFromCollection = (item: MediaCollectionItem) => {
    transferAsset(workspaceId, item['@src'] ?? '')
      .then(backgroundImageSync)
      .catch(backgroundImageErrorNotification);

    setIsReplacingImage.open();
    setInReplaceImagePanel.close();
  };

  const replaceImage = (
    <>
      <ImageUploader />
      <ImageMasonry onItemClick={handleSelectFromCollection} />
    </>
  );

  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => {
        handleClickToToggleSubPanel('background-setting');
      }}
      label="Background setting"
      title="Background"
      icon={<IconSection />}
    >
      {inReplaceImagePanel ? (
        replaceImage
      ) : (
        <Tabs value={activeTab} onChange={handleSwitchTab} h={700}>
          <Tabs.List>
            <Tabs.Tab w={32} value="solid" aria-label="Edit solid color">
              <CircleBadge bg="#f2f2f2" active={activeTab === 'solid'} />
            </Tabs.Tab>
            <Tabs.Tab w={32} value="gradient" aria-label="Edit gradient">
              <CircleBadge
                bg="linear-gradient(180deg, #FFF 0%, #CED4DA 100%)"
                active={activeTab === 'gradient'}
              />
            </Tabs.Tab>
            <Tabs.Tab w={32} value="image" aria-label="Edit background image">
              <CircleBadge
                bgp="top center"
                bg="url(/assets/image-icon.svg)"
                active={activeTab === 'image'}
              />
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="solid" pt={16} aria-label="Solid color editor">
            <SolidAndGradientColorEditor
              value={solid.value || defaultSolid}
              onChange={handleChangeToUpdateValue('backgroundColor', 'solid')}
              brandColors={brandColors}
            />
          </Tabs.Panel>

          <Tabs.Panel value="gradient" pt={16} aria-label="Gradient editor">
            <SolidAndGradientColorEditor
              value={
                gradient.value ||
                `linear-gradient(180deg, ${solid.value || 'rgba(0,0,0,1)'} 0%, rgba(255,255,255,1) 100%)`
              }
              onChange={handleChangeToUpdateValue('backgroundImage', 'gradient')}
              brandColors={brandColors}
            />
          </Tabs.Panel>

          <Tabs.Panel value="image" pt={16} aria-label="Background image editor">
            <ImagePreview
              src={image.value?.replace(/url\(|\)/g, '')}
              isReplacingImage={isReplacingImage}
              onFileSelected={handleFileSelect}
              onLibrarySelected={setInReplaceImagePanel.open}
            />

            <StyledSelect
              mt={32}
              label="Type"
              value={
                ['cover', 'contain', '100% 100%'].includes(imgBgSize || '')
                  ? imgBgSize
                  : imgBgRepeat
              }
              data={[
                { label: 'Fill', value: 'cover' },
                { label: 'Fit', value: 'contain' },
                { label: 'Stretch', value: '100% 100%', disabled: checkIsSvg(image.value) },
                { label: 'Tile', value: 'repeat' },
              ]}
              onChange={(val) => {
                dispatch({
                  type: 'set-image-additional',
                  additional:
                    val === 'repeat'
                      ? { backgroundSize: 'unset', backgroundRepeat: 'repeat' }
                      : { backgroundSize: val ?? 'cover', backgroundRepeat: 'no-repeat' },
                });
              }}
            />

            <StyledSelect
              mt={16}
              label="Position"
              value={imgBgPos}
              data={[
                {
                  group: '1',
                  items: [
                    { label: 'Top Left', value: 'top left' },
                    { label: 'Top Center', value: 'top center' },
                    { label: 'Top Right', value: 'top right' },
                  ],
                },
                {
                  group: '2',
                  items: [
                    { label: 'Left', value: 'center left' },
                    { label: 'Center', value: 'center center' },
                    { label: 'Right', value: 'center right' },
                  ],
                },
                {
                  group: '3',
                  items: [
                    { label: 'Bottom Left', value: 'bottom left' },
                    { label: 'Bottom Center', value: 'bottom center' },
                    { label: 'Bottom Right', value: 'bottom right' },
                  ],
                },
              ]}
              onChange={(val) => {
                dispatch({
                  type: 'set-image-additional',
                  additional: { backgroundPosition: val ?? 'top left' },
                });
              }}
            />
          </Tabs.Panel>
        </Tabs>
      )}
    </BasePanel>
  );
};

export default BackgroundPanel;
