import { Switch, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { IconIcons } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import isURL from 'validator/lib/isURL';

import BasePanel from '../BasePanel/BasePanel';

import { PANEL_DEBOUNCE_UPDATE_INTERVAL, REGEX_PROTOCOL } from '~/constants';
import { ToolbarPanelProps } from '~/global.types';
import { isInputFromSidePanel } from '~/helpers/domChecks/domChecks';
import { findPageData } from '~/helpers/pageDataTraverse/pageDataTraverse';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { BlockOutput } from '~/messages.types';

const SelectedButtonPanel = ({
  opened,
  handleClickToToggleSubPanel,
  dataFromPostMessage = {},
}: ToolbarPanelProps) => {
  const [buttonSelector, setButtonSelector] = useState('');

  const form = useForm({
    initialValues: {
      textValue: 'button',
      anchorHref: '',
      anchorTarget: false,
    },
    validateInputOnChange: true,
    validate: {
      anchorHref: (value: string) =>
        // Is either blank or URL string - blank will transform image link back to decorative image
        value.length === 0 || (REGEX_PROTOCOL.test(value) && isURL(value))
          ? null
          : 'Enter your website URL in the correct format, like https://www.company.com',
    },
  });

  const handleEventToSubmit = () => {
    if (!opened || !form.isDirty()) return;

    const { values } = form;

    const outputData: BlockOutput = {
      id: buttonSelector,
      jsonOverride: {
        tagName: 'a',
        contains: undefined,
        textValue: values.textValue,
        '@href': values.anchorHref,
        '@target': values.anchorTarget ? '_blank' : undefined,
      },
    };

    msg({ type: 'editing-performed-from-panel', outputData });
  };

  const [debounced] = useDebouncedValue(form.values, PANEL_DEBOUNCE_UPDATE_INTERVAL);

  // All input has debounced trigger message to achieve page data transform and autosave
  useEffect(() => {
    if (form.isValid()) handleEventToSubmit();
  }, [debounced]);

  // useForm initialValues does not truly grab the props, as it is a Collapsible, it has already
  // been mounted with empty dataFromPostMessage props
  useEffect(() => {
    if (opened && !isInputFromSidePanel(document.activeElement, 'Button')) {
      const obj = {
        textValue: (dataFromPostMessage.textValue as string) || '',
        anchorHref: (dataFromPostMessage['@href'] as string) || '',
        anchorTarget: (dataFromPostMessage['@target'] as string) === '_blank',
      };
      form.setValues(obj);
      form.resetDirty(obj);

      setButtonSelector((dataFromPostMessage.selector as string) || '');
    }
  }, [opened, dataFromPostMessage]);

  useViewerMessage(
    async ({ data }) => {
      if (data.type === 'editing-performed' && opened) {
        msg({ type: 'trigger-broadcast-inline-edit-page-data' });
      }
      if (data.type === 'broadcast-inline-edit-page-data' && opened) {
        const buttonData = findPageData(JSON.parse(data.pageData), buttonSelector);
        const newTextValue = typeof buttonData?.textValue === 'string' ? buttonData.textValue : '';
        const cleanedTextValue = newTextValue?.replace(/&nbsp;/g, ' ');

        const obj = {
          ...form.values,
          textValue: cleanedTextValue || '',
        };
        if (newTextValue) {
          form.setValues(obj);
          form.resetDirty(obj);
        }
      }
    },
    [opened, buttonSelector],
  );

  return (
    <BasePanel
      opened={opened}
      onClickToClose={() => handleClickToToggleSubPanel('selected-button-panel')}
      label="Button"
      title="Button"
      icon={<IconIcons />}
    >
      <Title order={5} mt={10}>
        Button data
      </Title>
      <TextInput
        label="Button text"
        description={<span>Display text for the button.</span>}
        placeholder="Button text"
        size="sm"
        mt="md"
        {...form.getInputProps('textValue')}
      />
      <TextInput
        label="Button link"
        description={<span>URL for the button to go to</span>}
        placeholder="e.g. https://mywebsite/sign-up.com"
        size="sm"
        my="md"
        {...form.getInputProps('anchorHref')}
      />
      <Switch
        label="Open in new tab"
        {...form.getInputProps('anchorTarget', { type: 'checkbox' })}
        disabled={form.values.anchorHref === '' || !form.values.anchorHref}
      />
    </BasePanel>
  );
};

export default SelectedButtonPanel;
