import { ColorInput, NumberInput, ScrollArea, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconPalette } from '@tabler/icons-react';
import Color from 'color';
import { useEffect, useState } from 'react';

import {
  BrandToken,
  cssVariablesArrayToCSS,
  generateGoogleFontImports,
  parseCSSVariables,
} from './styleProcessing';

import BasePanel from '../BasePanel/BasePanel';

import { PANEL_DEBOUNCE_UPDATE_INTERVAL } from '~/constants';
import { PageJsonSnippetObj } from '~/global.types';
import { getStyleWithString } from '~/helpers/cssVariableProcessing/cssVariableProcessing';
import msg from '~/helpers/viewerInteractions/msg';
import useViewerMessage from '~/hooks/useViewerMessage/useViewerMessage';
import { useFeatureFlags } from '~/providers/FeatureFlagProvider';
import { Brand, getBrandAnalysis, saveBrandAnalysis } from '~/services/BrandServices';

interface StylePanelProps {
  opened: boolean;
  onClickToClose?: () => void;
}

const getFontFamilies = (brandTokens: BrandToken[]) =>
  brandTokens
    .filter((obj) => Object.keys(obj)[0].includes('font-family'))
    .map(Object.values)
    .flat();

const updateHtmlHeadArray = (htmlHeadArray: PageJsonSnippetObj[], brandTokens: BrandToken[]) => {
  // Update custom font object
  const fontStyleIndex = htmlHeadArray.findIndex(getStyleWithString('?family='));
  const fontFamilyValues = getFontFamilies(brandTokens);
  if (htmlHeadArray[fontStyleIndex]) {
    htmlHeadArray[fontStyleIndex].textValue = generateGoogleFontImports(fontFamilyValues);
  }

  // Update root styles
  const rootStyleIndex = htmlHeadArray.findIndex(getStyleWithString(':root'));
  if (htmlHeadArray[rootStyleIndex]) {
    htmlHeadArray[rootStyleIndex].textValue = cssVariablesArrayToCSS(brandTokens);
  }

  return htmlHeadArray;
};

const StylePanel = ({ opened, onClickToClose }: StylePanelProps) => {
  const [htmlHeadArray, setHTMLHeadArray] = useState<PageJsonSnippetObj[]>([]);
  const [brandTokens, setBrandTokens] = useState<BrandToken[]>([]);
  const [debouncedBrandTokens, cancel] = useDebouncedValue(
    brandTokens,
    PANEL_DEBOUNCE_UPDATE_INTERVAL,
  );
  const [brandAnalysis, setBrandAnalysis] = useState<Brand>();
  const { userAndWorkspaceInfo } = useFeatureFlags();
  const { v2ActiveWorkspace } = userAndWorkspaceInfo;

  useEffect(() => {
    const fetchBrandAnalysis = async (workspaceId: string) => {
      try {
        const { data: brand } = await getBrandAnalysis(workspaceId);
        setBrandAnalysis(brand);
      } catch (error) {
        console.error(error);
      }
    };
    if (v2ActiveWorkspace) {
      fetchBrandAnalysis(v2ActiveWorkspace.workspaceId);
    }
  }, [v2ActiveWorkspace]);

  const updateAnalysis = (nanoId: string, tokens: BrandToken[]): Promise<{ message: string }> => {
    const keyValues: Record<string, string | number> = {};
    tokens.map((obj) => {
      const updatedObj = { ...obj };
      Object.keys(updatedObj).forEach((key) => {
        const brandTokenIndex =
          brandAnalysis?.brandParameters.findIndex(
            (brandToken) => Object.keys(brandToken)[0] === `--${key}`,
          ) ?? -1;
        if (brandTokenIndex > -1) {
          keyValues[`brandParameters[${brandTokenIndex}].--${key}`] = updatedObj[key];
        }
      });

      return updatedObj;
    });
    return saveBrandAnalysis({
      nanoId,
      keyValues,
    });
  };

  useViewerMessage(({ data }) => {
    if (
      data.type === 'first-fetch-page-data-completed' ||
      data.type === 'broadcast-history-change-page-data'
    ) {
      const { head } = JSON.parse(data.pageData);
      if (head) {
        const cssVariablesFromHead = head.find(getStyleWithString(':root'));

        if (cssVariablesFromHead && cssVariablesFromHead.textValue) {
          const { textValue } = cssVariablesFromHead;
          setHTMLHeadArray(head);
          setBrandTokens(parseCSSVariables(textValue));

          if (data.type === 'broadcast-history-change-page-data') {
            setTimeout(cancel, PANEL_DEBOUNCE_UPDATE_INTERVAL / 2);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (htmlHeadArray.length > 0) {
      const updatedHTMLHeadArray = updateHtmlHeadArray(htmlHeadArray, brandTokens);
      setHTMLHeadArray(updatedHTMLHeadArray);
      msg({ type: 'head-edited', head: updatedHTMLHeadArray });
    }
    if (!brandAnalysis) return;
    updateAnalysis(brandAnalysis?.nanoId, debouncedBrandTokens);
  }, [debouncedBrandTokens]);

  const updateBrandToken = (tokenName: string, newTokenValue: string | number): void => {
    setBrandTokens((prevBrandTokens) =>
      prevBrandTokens.map((brandToken) =>
        tokenName in brandToken
          ? ({ ...brandToken, [tokenName]: newTokenValue } as BrandToken)
          : brandToken,
      ),
    );
  };

  const updateColorBrandTokenGroup = (tokenName: string, newTokenValue: string): void => {
    const hashedHex = newTokenValue.startsWith('#') ? newTokenValue : `#${newTokenValue}`;
    let validColor;

    try {
      validColor = Color(hashedHex);
    } catch {
      validColor = Color('#000000');
    }

    setBrandTokens((prevBrandTokens) =>
      prevBrandTokens.map((obj) => {
        const updatedObj = { ...obj };

        Object.keys(updatedObj).forEach((key) => {
          const prefix = tokenName.replace(/-\d+$/, ''); // Remove the suffix (e.g., "-100")
          if (key.startsWith(prefix)) {
            const opacity = parseInt(key.split('-').pop() || '100', 10);
            updatedObj[key] =
              key === tokenName
                ? hashedHex
                : validColor.mix(Color('white'), 1 - opacity / 100).hex();
          }
        });

        return updatedObj;
      }),
    );
  };

  return (
    <BasePanel
      opened={opened}
      onClickToClose={onClickToClose}
      label="Style options"
      title="Style"
      icon={<IconPalette />}
    >
      {/*
       * This style tag serves the brand CSS variables and CSS import of Google font to Studio overlay UI
       * The Studio viewer, which are iframes have its own style tag for such
       */}
      <style>
        {htmlHeadArray
          .map((item) => (item.tagName === 'style' ? item.textValue : ''))
          .reverse()
          .join('\n')}
      </style>
      <ScrollArea style={{ height: 'calc(100vh - 170px)' }} type="never">
        {brandTokens.length === 0 ? (
          <div>No brand information available</div>
        ) : (
          brandTokens.map((token, index) => {
            const tokenName = Object.keys(token)[0];
            const tokenValue = token[tokenName];
            const isNumber = typeof tokenValue === 'number';
            const isColorToken = tokenName.includes('color');

            if (isNumber) {
              return (
                <NumberInput
                  key={index}
                  label={tokenName.replace(/--/g, '').replace(/-/g, ' ')}
                  value={tokenValue as number}
                  onChange={(value: string | number) => {
                    updateBrandToken(tokenName, value);
                  }}
                  styles={{ label: { textTransform: 'capitalize' } }}
                  size="sm"
                  mt="md"
                />
              );
            }

            if (isColorToken) {
              return !(tokenName.endsWith('-100') || !/-\d+$/.test(tokenName)) ? null : (
                <ColorInput
                  key={index}
                  placeholder={`Enter a value for ${tokenName.replace('-100', '')}`}
                  label={tokenName.replace(/--/g, '').replace('-100', '').replace(/-/g, ' ')}
                  value={tokenValue as string}
                  onChange={(value) => updateColorBrandTokenGroup(tokenName, value)}
                  size="sm"
                  mt="md"
                  format="hex"
                  styles={{ label: { textTransform: 'capitalize' } }}
                />
              );
            }

            return (
              <TextInput
                key={index}
                label={tokenName.replace(/--/g, '').replace(/-/g, ' ')}
                placeholder={`Enter a value for ${tokenName}`}
                value={tokenValue as string}
                onChange={(event) => updateBrandToken(tokenName, event.currentTarget.value)}
                size="sm"
                mt="md"
                styles={{ label: { textTransform: 'capitalize' } }}
              />
            );
          })
        )}
      </ScrollArea>
    </BasePanel>
  );
};

export default StylePanel;
