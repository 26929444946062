import rgbHex from 'rgb-hex';

export const enforceHex = (color: string) => {
  let updatedColorString = color;
  if (color.startsWith('rgb')) updatedColorString = rgbHex(color);
  updatedColorString = '#' + updatedColorString.replaceAll('#', '');
  if (updatedColorString.length >= 7) updatedColorString = updatedColorString.substring(0, 7);
  return updatedColorString;
};

export const noTransparentColor = (newColor: string, prevColor: string) => {
  let assignColor = newColor;

  if (!assignColor) {
    assignColor = '#00000000';
  } else {
    const colorOnly = newColor.substring(0, 7);
    if (colorOnly !== prevColor.substring(0, 7)) {
      assignColor = `${colorOnly}ff`;
    }
  }

  return assignColor;
};
